
import {css, jsx} from '@emotion/react';

import styled from '@emotion/styled';

export const ExperienceDescription = styled.p`
      margin-top: 0;
      margin-bottom: 0;
      font-size: 0.9rem;
    `;
  
export const MissingDescription = styled(ExperienceDescription)`
      font-style: italic;
    `;
export const MissingCVTxt = styled.p`
          font-size: 1.5em;
        `;
  
export const AddCVDataTxt = styled.p`
          font-size: 14px;
        `;
      
export const EditIcon = styled.span`
        margin-right: 10px;
      `;
      
export const NoDataDesc = styled.p`
        font-style: italic;
        margin-top: 7px;
      `;

export const ExperienceDetails = styled.p`
margin-top: 0;
margin-bottom: 0;
color: #494949;

${props =>
  props.last
    ? css`
        margin-bottom: 10px;
      `
    : ''}
`;

export const LanguageName = styled.p`
display: inline-block;
font-weight: bold;
margin-bottom: 25px;
margin-top: 0;
`;

export const LanguageDesc = styled.p`
display: inline-block;
margin-bottom: 0;
margin-top: 0;
`;

export const ExperienceWrapper = styled.div`
display:flex;
margin-bottom: 10px;
min-height: 72px;
align-items: center;
`;

export const ExperienceHeader = styled.h3`
margin-top: 0;
margin-bottom: 0;
font-weight: 500;
font-size: 1em;
`;
export const LanguageCont = styled.div``;

export const LeftPart = styled.div``;
export const RightPart = styled.div`
  margin-left: auto;
`;

export const ExperienceKindTitle = styled.h2`
  color: #0b5cd7;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;