/* Returns a config-object with data and methods
for accessing that data.
*/
const _ = require('lodash');

const defaultEnabledModules = new Set(['my-education', 'profile']);

export const getConfigObject = ({config}) => {
  const obj = {
    data: config.config.data,
    error: config.config.error,
    isFetching: config.config.isFetching,

    /* Get a property using dot syntax, eg a.b.c.d
    If there is no data or the final property or any properties along the way dosent exist,
    undefined is returned

    */
    getProperty: key => {
      if (!config.config.data) {
        return undefined;
      }

      const {data} = config.config;

      return _.get(data, key);
    },
    isModuleEnabled: modulename => {
      const dataNotYetLoaded = !(config.config && config.config.data);

      if (dataNotYetLoaded) {
        return null;
      }

      const isEnabledByDefault = defaultEnabledModules.has(modulename);
      const configForModule
        = config.config
        && config.config.data
        && config.config.data.routes
        && config.config.data.routes[modulename];

      const isEnabledInConfig
        = configForModule
        && [undefined, false].includes(configForModule.disabled);
      const isDisabledInConfig
        = configForModule && configForModule.disabled === true;

      if (isEnabledInConfig) {
        return true;
      }
      if (isDisabledInConfig) {
        return false;
      }

      if (isEnabledByDefault) {
        return true;
      }

      return false;
    },
  };

  obj.isMapActivated
    = obj.isModuleEnabled('atlas')
    && (localStorage.getItem('track') || obj.getProperty('params.default-track'));

  return obj;
};

export const getConfig = ({config}) => config;
export const getHasLoadedConfig = ({config}) =>
  !!(!config.config.isFetching && !config.config.error && config.config.data);

export const getCourseCatalogFeatures = state => {
  const configObject = getConfigObject(state);

  return {
    searchbar: configObject.getProperty('routes.course-catalog.showSearchField'),
    titleOfCategory: !configObject.getProperty('routes.course-catalog.hideTitleOfCategory'),
    courseTypeFilter: configObject.getProperty('routes.course-catalog.showCourseTypeFilter'),
    sorting: configObject.getProperty('routes.course-catalog.showSorting'),
    classromCourseDigitalCourseSwitch: configObject.getProperty('routes.course-catalog.showClassromDigitalCourseSwitch'),
  };
};

export const getCurrTrack = state => {
  const configObject = getConfigObject(state);
  const track
    = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  return track;
};

export const getCurrLanguage = state => {
  const configObject = getConfigObject(state);

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return language;
};

export const getPropertiesForCurrLangAndTrackBadge = state => {
  const configObject = getConfigObject(state);

  if (!configObject.data) {
    return null;
  }

  const track
    = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  return configObject.getProperty(`routes.atlas.configByTrackAndLang.${track}.${language}.badge`);
};

export const getPropertiesForCurrLangAndTrack = state => {
  const configObject = getConfigObject(state);

  if (!configObject.data) {
    return null;
  }

  const track
    = localStorage.getItem('track')
    || configObject.getProperty('params.default-track');

  const language
    = localStorage.getItem('language')
    || configObject.getProperty('params.default-language')
    || 'no';

  try {
    const properties = {};
    // == atlas-config (config for the map)
    const atlasConfigByMapIdAndLang = configObject.getProperty('routes.atlas.configByTrackAndLang');
    const atlasConfigForCurrTrack
      = atlasConfigByMapIdAndLang && atlasConfigByMapIdAndLang[track] || {};

    const atlasConfigForCurrTrackAndLang
      = atlasConfigForCurrTrack && atlasConfigForCurrTrack[language] || {};

    if (atlasConfigByMapIdAndLang) {
      properties.atlas = {
        redirectAfterOutroFinished: (atlasConfigForCurrTrackAndLang.outro || {})
          .redirectAfterFinished,
      };
    }

    // == course catalog config
    const featuredTilesConfigByTrack = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack');
    const courseCatalogConfigForCurrTrack
      = (configObject.getProperty('routes.course-catalog.configByTrack') || [])[
        track
      ] || {};
    const featuredTilesConfigForCurrTrack
      = featuredTilesConfigByTrack
        && featuredTilesConfigByTrack[track]
        && (configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack') || [])[track].featuredTiles
      || [];

    if (configObject.getProperty('routes.course-catalog.configByTrack')) {
      properties.courseCatalog = {
        ...courseCatalogConfigForCurrTrack,
        featuredTiles: featuredTilesConfigForCurrTrack,
      };
    }

    // == menu config
    const menuConfig = configObject.getProperty('params.menu');

    const menuConfigByTrack = configObject.getProperty('params.menu.configByTrack');
    const menuConfigForMapId
      = menuConfigByTrack && menuConfigByTrack[track] || {};

    const menuConfigForMapIdAndLanguage
      = menuConfigForMapId
        && menuConfigForMapId.configByLang
        && menuConfigForMapId.configByLang[language]
      || {};

    properties.menu = {
      ...menuConfig,
      ...menuConfigForMapId,
      ...menuConfigForMapIdAndLanguage,
    };

    return properties;
  } catch (error) {
    alert(`Manglende eller feil konfigurering for løype med navn ${track}`);
    console.error(`Manglende konfigurering for løype med id ${track}`);
    console.error(error);

    return {};
  }
};
