import React from 'react';
import PropTypes from 'prop-types';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/no';
import '@ckeditor/ckeditor5-build-classic/build/translations/en-gb';
import {InputWrapper, StyledErrorMsg, StyledLabel} from '@components/form-input/form-input-styles';

import './form-ckeditor.scss';

const CKEditorField = ({label, input, meta}) => (
  <InputWrapper>
    {label
    && (
      <StyledLabel
        htmlFor={input.name}
      >
        {label}
      </StyledLabel>
    )}
    <CKEditor
      onBlur={() => input.onBlur()}
      className="form-ckeditor__editor"
      editor={ClassicEditor}
      data={input.value}
      config={{
        language: {
          no:'no',
          en:'en-gb',
        }[localStorage.getItem('language')] || 'en-gb',
      }}
      onChange={(_, editor) => {
        const message = editor.getData();

        input.onChange(message);
      }}
    />
    {meta.touched && meta.error 
    && (
      <StyledErrorMsg role="alert">
        {meta.error}
      </StyledErrorMsg>
    )}
  </InputWrapper>
);

CKEditorField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
};

export default CKEditorField;
