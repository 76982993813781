import styled from '@emotion/styled';
import {mq} from '@facepaint';

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #E1E4E8;
    border-radius: 5px;
`;

export const Header = styled.h3`
  background-color: #F6F8FA;
  border-bottom: 1px solid #E1E4E8;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1em;
`;

export const Wrapper = styled.div`
    ${mq.large} {
      margin-left: 40px;
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 1em;
    min-height: 10em;
    .events-list__card {
      border: 0;
    }
`;
