import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';
import {colors} from '@src/colors';

export const StyledModalTitle = styled.h2`
    display: flex;
    font-weight: 500;
    margin-bottom: 0.35em;
    margin: 0;
`;


export const StyledModalBody = styled.div`
`;

export const Wrapper = styled.div`

`;

export const LearnMoreWrapper = styled.div`
    display: flex;
    align-items: center;
    *{

        color: ${colors.blue};
    }

    &:hover, &:focus{
        text-decoration: underline;
    }
    &:hover{
        cursor: pointer;
    }
`;

export const LearnMoreLink = styled.a`
    font-weight: 500;
    margin-right: 0.75em;

`;

export const ActionButtonsWrapper = styled.div`
  flex-direction: inherit;
  margin-left: auto;
  width: auto;
  flex-direction: row;
  display: flex;
  * {
    margin-right: 0;
    margin-left: 1.5em;
  }
`;

export const CloseButton = styled.button`
    background: ${rgba('#000000', 0.4)};
    color: white;
    border-radius: 50%;
    color: white;
    padding: 0;
    width: 1.7em;
    height: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        cursor: pointer;
        background: ${rgba('#000000', 0.48)};
    }

    svg{
        width: 55%;
        height: 55%;
        color: ;
        font-size: 1.75em;
    }
`;

export const BottomButtonsWrapper  = styled.div`
    display: flex;
    align-items: center;

    margin-top: 1em;
    align-items: center;
`;

export const BodyWrapper = styled.div`
    margin-bottom: 0.5em;
`;
export const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 0.6em;
`;

export const ContentWrapper  = styled.div`
    background: white;
    border-radius: 8px;
    padding-top: 2.5em;
    padding-left: 1.8em;
    padding-right: 1.8em;
    display: flex;
    flex-direction: column;
    border: 1px solid #E0E0E0;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 55%;
    max-width: 700px;
    padding-bottom: 1.7em;

    ${({hasIcon}) => hasIcon && css`
        ${BodyWrapper}, ${BottomButtonsWrapper}{
            margin-left: 2.5rem;
        }
        `}

    ${({isMobile, hasIcon})=> isMobile && css`
        width: 68%;
        ${BottomButtonsWrapper}{
            flex-direction: column;
        }
        ${LearnMoreWrapper}{
            align-self: flex-start;
            margin-bottom: 0.35em;
        }

        ${hasIcon && css`
            ${BodyWrapper}, ${BottomButtonsWrapper} {
                margin-left: 1.1em;
            }

        `}

        ${ActionButtonsWrapper}{
            *{

                margin-left: 0.6em;
            }
        }
      `}
`;
