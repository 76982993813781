import {css, jsx} from '@emotion/react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import reports from '@routes/reports.manifest';
import * as T from '../../../../store/types/load.types';

import '@routes/employees/styles/employees-report.scss';
import {backendUrl} from '@config';

import {i18n} from '@src/i18n';

import {getSelectedOrganisation} from '@selectors/employees.selectors';
import ReportFull from '@routes/employees/components/employees-report/employees-report-full';
import ReportRole from '@routes/employees/components/employees-report/employees-report-role';
import ReportCompetence from '@routes/employees/components/employees-report/employees-report-competence';
import {ReportLI, ReportUL} from '@routes/employees/components/employees-report/employees-report-styles';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees as routes, myEducation as myEducationRoutes} from '@routes/routes.manifest';

const getReportTitle = reportId => !!reportId && reportId in reports && reports[reportId].name;
const getReportCti = reportId => !!reportId && reportId in reports && reports[reportId].cti;

class EmployeesReport extends Component {
  state = {
    doPrint: false,
    iframeIndex: 0,
    sortOrder: 'asc',
  };

  reportPrint = React.createRef();

  setReport(newReport) {
    const {onInit, orgId, history} = this.props;
    onInit(newReport);
    history.push(getRouteWithParams(routes.reportPreview, {
      reportId: newReport,
      orgId,
    }));

  }

  componentDidMount() {
    const {onInit, orgId, reportId} = this.props;
    this.toggleSortOrder = this.toggleSortOrder.bind(this);

    if (onInit) {
      onInit(reportId);
    }
  }

  print = () => {
    const {iframeIndex} = this.state;

    this.setState({
      doPrint: true,
      iframeIndex: iframeIndex + 1,
    });
  };

  toggleSortOrder() {
    const {sortOrder} = this.state;

    this.setState({sortOrder: sortOrder === 'asc' ? 'desc' : 'asc'});
  }

  render() {
    const {report, mainOrganisation, reportId, orgId} = this.props;
    const {doPrint, iframeIndex} = this.state;
    return (
      <div>
        <ReportUL>
          <ReportLI
            first
          >
            {i18n('reports.select-report')}:
          </ReportLI>
          <ReportLI
            active={reportId === 'totalt'}
            onClick={() => this.setReport('totalt')}
          >
            {i18n('reports.total')}
          </ReportLI>
          <ReportLI
            active={reportId === 'role'}
            onClick={() => this.setReport('role')}
          >

              {i18n('reports.by-role')}
          </ReportLI>
          <ReportLI
            active={reportId === 'competence'}
            onClick={() => this.setReport('competence')}
          >
            {i18n('reports.by-comptence')}
          </ReportLI>
        </ReportUL>
        {Boolean(report.status !== T.LoadStatuses.LOADED || report?.data?.reportId !== reportId || !mainOrganisation) && (
            <div
              style={{paddingTop: 50, width: "100%", clear: "both"}}
            >{i18n('reports.loading')}...</div>
          ) || report.data && (
            <>
              {reportId === 'role' && (
                <ReportRole
                  report={report}
                  mainOrganisation={mainOrganisation}
                />
              ) || reportId === 'competence' && (
                <ReportCompetence
                  report={report}
                  mainOrganisation={mainOrganisation}
                />
              ) || (
                <ReportFull
                  report={report}
                  mainOrganisation={mainOrganisation}
                />
              )}
            </>
          )}
        {doPrint && (
          <iframe
            key={iframeIndex}
            ref={this.reportPrint}
            src={`${backendUrl}/templates/widgets/pdf?organisation_id=${
              mainOrganisation.id
            }&competence_ids=${getReportCti(report.data.reportId)}&title=${window.encodeURIComponent(getReportTitle(report.data.reportId))}&print=1`}
            title="reportPrint"
            style={{
              'float': 'left',
              width: '1px',
              height: '1px',
              visibility: 'hidden',
              display: 'block',
              margin: '-1px',
            }}
          />
        )}
      </div>
    );
  }
}

EmployeesReport.propTypes = {
  report: PropTypes.shape({}).isRequired,
  mainOrganisation: PropTypes.shape({}).isRequired,
  onInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({mainOrganisation: getSelectedOrganisation(state)});


export default connect(mapStateToProps)(EmployeesReport);
