import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import './breadcrumbs.scss';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';

const UserBreadcrumb = ({match}) => <span>{match.params.userName}</span>;

UserBreadcrumb.propTypes = {match: PropTypes.shape({}).isRequired};

const Breadcrumbs = ({breadcrumbs, disableMyPage, ...props}) => (
  <div
    className="breadcrumbs"
    {...props}
  >
    {!disableMyPage
    && (
      <span key="id-0">
        <NavLink to="/">{i18n('menu.my-page')}</NavLink>
        <FontAwesomeIcon icon={faArrowRight} />
      </span>
    )}
    {breadcrumbs.map(({breadcrumb, key, match: {url}}, index) => (
      <span key={key + '-' + index}>
        <NavLink to={url}>{breadcrumb}</NavLink>
        {index < breadcrumbs.length - 1
          && (
            <FontAwesomeIcon
              style={{color:'#656565'}}
              icon={faArrowRight}
            />
          )}
      </span>
    ))}
  </div>
);

Breadcrumbs.propTypes = {breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired};

export default Breadcrumbs;
