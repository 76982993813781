import React from 'react';
import PropTypes from 'prop-types';

import {PersonShape} from '@types/person';

import '@routes/employees/styles/employees-list.scss';
import * as T from '@types/load.types';

import EmployeeCard, {EmployeeCardLoading} from '@routes/employees/components/employee-card/employee-card';
import {i18n} from '@src/i18n/index';

const EmployeesList = ({
  employees,
  hasMore,
  onEmployeeSelect,
  onEmployeeMore,
  isFetching,
}) => (
  <div className="employees-list">
    <table className="employees-list__table">
      <tr>
        <th />
        <th />
        <th>{i18n('person.status')}</th>
        <th>{i18n('person.position')}</th>
        <th>{i18n('person.email')}</th>
        <th>{i18n('person.phone')}</th>
        <th>{i18n('person.mobile')}</th>
      </tr>
      {employees.map(employee => (
        <EmployeeCard
          absent={employee.is_absent}
          listView
          isFetching
          employee={employee}
          onAvatarClick={() => onEmployeeSelect(employee)}
        />
      ))}
    </table>
    {hasMore && (
      <>
        {isFetching !== T.LoadStatuses.LOADED && (
          <div className="info__chip">
            <div className="chip show-more chip--loading">
              <i className="fas fa-spin fa-spinner" />
            </div>
          </div>
        ) || (
          <div className="info__chip">
            <button
              type="button"
              className="chip show-more"
              onClick={onEmployeeMore}
            >
              ...
            </button>
          </div>
        )}
      </>
    )}
  </div>
);

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(PersonShape()).isRequired,
  onEmployeeSelect: PropTypes.func.isRequired,
  onEmployeeMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default EmployeesList;

export const EmployeesListLoading = () => (
  <div className="employees-list">
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
  </div>
);
