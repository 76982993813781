import {i18n, i18nMoment} from '@src/i18n';
import {CheckCircle, MinusCircle, WarningCircle} from 'phosphor-react';
import React, {useState} from 'react';
import {css, jsx} from '@emotion/react';

import {Header, ParticipantsLabel, Wrapper, PartipicantsWrapper, grayDotStyle, approveDotStyle, Action, Partipicant, ActionName, signOffDotStyle, ActionsWrapper, signedUpDotStyle, Name, onWaitlistDotStyle} from './course-event-partipicant-list-styles';
import Modal from '@components/modal/modal';
import {courseSignOff} from '@actions/courses.actions';
import {useDispatch} from 'react-redux';
import {darken, lighten, rgba} from 'polished';
import {Dropdown} from '@components/dropdown/dropdown';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {getCoursePath, getEmployeePreviewPath} from '@utils/routes.utils';
import Moment from 'react-moment';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';

export const EventPartipicantsList = (
  {
    me,
    myEmployeesEvents,
    myEmployeesEventsWaitlist = []
  }
) => {
  const [employeeForConfirmSignOff, setEmployeeForConfirmSignOff] = useState(null);
  const [employeeForConfirmEnrollment, setEmployeeForConfirmEnrollment] = useState(null);

  const [view, setView] = useState('needs-approval');

  const allEvents = [...myEmployeesEvents.map(e => ({
    ...e,
    waitlist: false,
  })), ...myEmployeesEventsWaitlist.map(e => ({
    ...e,
    waitlist: true,
  }))].sort((a, b) => a.person.fullname.localeCompare(b.person.fullname));

  const dispatch = useDispatch();


  const partipicantsForCurrView = {
    'signed-up': myEmployeesEvents,
    'needs-approval': myEmployeesEvents,
    // 'on-waitlist': myEmployeesEvents.filter(e => !e.confirmed),

    // DEBUG!
    'on-waitlist': myEmployeesEvents,
  }[view];

  const handleSignOffEvent = employee => {
    dispatch(courseSignOff({
      courseEventId: myEmployeesEvents[0].id,
      employees: [employee],
      onSuccess: () => {
        setEmployeeForConfirmSignOff(null);
        setEmployeeForConfirmEnrollment(null);
      },
    }));
  };

  const waitList = myEmployeesEvents.filter((x) => x.waitlist);
  const confirmedList = myEmployeesEvents.filter((x) => x.confirmed);

  const uniqueEvents = [...new Map(myEmployeesEvents.map((x) => [x['id'], x])).values()];
  return (
    <>
      {uniqueEvents.map((event, idx, arr) => (
        <div
          className={classNames([
            'events-list__card',
          ])}
        >
          <div
            className={`events-list__card__contents ${
              event.waitlist ? 'waiting' : ''
            }`}
          >
            <div className="events-list__date">
              <Moment
                className="events-list__date__day"
                interval={0}
                format="DD"
              >
                {event.startdate}
              </Moment>

              <div className="events-list__date__month">
                {i18nMoment(event.startdate).format('MMM')}
              </div>
            </div>

            <div className="events-list__text__wrapper">
              {event.title
              && <div className="events-list__text__title">{event.title}</div>
              || (
                <div className="events-list__text__title">
                  {event.competence_title}
                </div>
              )}


              <div className="events-list__text__time_and_location">
                <div className="events-list__text__description">
                  {moment(event.startdate).format('HH:mm')}
                  -
                  {moment(event.enddate).format('HH:mm')}
                  {event.location.title && <span>,&nbsp;</span>}
                </div>

                <div className="events-list__text__description">
                  {event.locationTitle}
                </div>

                {event.location && event.location.title && (
                  <div className="events-list__text__location">
                    {event.location.title}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Wrapper>
            {employeeForConfirmSignOff
            && (
              <Modal
                variant="warning"
                onClose={() => setEmployeeForConfirmSignOff(null)}
                onCancel={() => setEmployeeForConfirmSignOff(null)}
                onConfirm={() => handleSignOffEvent(employeeForConfirmSignOff)}
                title={() => (
                  <Modal.Title>
                    {i18n('course.confirm-sign-off')}
                  </Modal.Title>
                )}
                submitButtonText={i18n('course.sign-off')}
              >
                {i18n('course.sign-off-person-x-question', {functionArgs: {x:employeeForConfirmSignOff.fullname}})}
              </Modal>
            )}
            {employeeForConfirmEnrollment
            && (
              <Modal
                title={() => (
                  <Modal.Title>
                    {i18n('course.confirm-enrollment')}
                  </Modal.Title>
                )}
                onClose={() => setEmployeeForConfirmEnrollment(null)}
                onCancel={() => handleSignOffEvent(employeeForConfirmEnrollment)}
                cancelButtonText={i18n('course.sign-off')}
                submitButtonText={i18n('course.sign-up')}
                cancelButtonStyle={css`color: white; background: #F03E3E;
              &:hover{
                background: ${darken(0.06, rgba('#F03E3E', 1))}
              }
              `}
                cancelButtonStyledAsSubmitButton
              />
            )}
            {[confirmedList, waitList].map((renderList,i) => (
              <>
                {renderList.length !== 0 && (
                  <>
                    <ParticipantsLabel>
                      {i18n('course.persons-confirmed')}
                    </ParticipantsLabel>
                    <PartipicantsWrapper>
                      {renderList.map(p => (
                        <Partipicant>
                          {[
                             <CheckCircle
                              css={signedUpDotStyle}
                              weight="fill"
                            />,
                            <WarningCircle
                              css={onWaitlistDotStyle}
                              weight="fill"
                            />,
                            <WarningCircle
                              css={onWaitlistDotStyle}
                              weight="fill"
                            />,
                          ][i]}
                          <Name>
                            {p.person.person_id === me && (
                              <>
                                {p.person.fullname} <em>({i18n('globals.you')})</em>
                              </>
                            ) || (
                              <>
                                {p.person.fullname}
                              </>
                            )}
                          </Name>

                          <ActionsWrapper>
                            {i >= 1 && (
                              <Action
                                role="button"
                                tabIndex="0"
                                onClick={() => setEmployeeForConfirmEnrollment(p.person)}
                              >
                                <CheckCircle
                                  css={grayDotStyle}
                                  weight="fill"
                                  aria-hidden="true"
                                />

                                <ActionName>
                                  {i18n('course.confirm-enrollment')}
                                </ActionName>
                              </Action>
                            )}

                            {i === 0 && (
                              <Action
                                role="button"
                                tabIndex="0"
                                onClick={() => setEmployeeForConfirmSignOff(p.person)}
                              >
                                <MinusCircle
                                  css={signOffDotStyle}
                                  weight="fill"
                                  aria-hidden="true"
                                />

                                <ActionName>
                                  {i18n('course.sign-off')}
                                </ActionName>
                              </Action>
                            )}

                            {['needs-approval'].includes(view)
                            && false && (
                              <Action>
                                <MinusCircle
                                  css={approveDotStyle}
                                  weight="fill"
                                  aria-hidden="true"
                                />

                                <ActionName>
                                  {i18n('globals.approve')}
                                </ActionName>
                              </Action>
                            )}
                          </ActionsWrapper>
                        </Partipicant>
                      ))}
                    </PartipicantsWrapper>
                  </>
                  )}
              </>
            ))}

          </Wrapper>
        </div>
      ))}


    </>
  );
};
