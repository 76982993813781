import {
  employeesFetchSelectedPerson,
  employeesEditSelectedPerson,
  employeesUpdateSelectedPersonEmployment,
  employeesFetchSelectedPersonReport,
  employeesEditViewInit,
  employeesResetPassword,
  employeesEditPassword,
  employeesSaveVerification,
  employeesSaveRequirements,
} from '@actions/employees.actions';
import Modal from '@components/modal/modal';
import {
  getIsFetchingFunctions,
  getSelectedPerson,
  getSelectedPersonReport,
} from '@selectors/employees.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router';
import EmployeeMessage from '../components/employee-message';
import {employees as routes} from '@routes/routes.manifest';
import EmployeeEdit from '@routes/employees/components/employee-edit/employee-edit';

import PersonEmploymentForm from '@components/person-employment-form/person-employment-form';

import EmployeeCard, {EmployeeCardLoadingNew} from '@routes/employees/components/employee-card/employee-card';

import EmployeeOverview from '@routes/employees/components/employee-overview';
import {i18n} from '@src/i18n/index';
import {messagesSendMessage} from '@actions/messages.actions';
import EmployeeReport from '@routes/employees/components/employee-report';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import {getOrganisationId, getRoles} from '@selectors/profile.selectors';
import {getOrganisations} from '@selectors/roles.selectors';
import {getRouteWithParams} from '@utils/routes.utils';
import {AnimatePresence, motion} from 'framer-motion';
import {css} from '@emotion/react';

const EmployeePreviewMainContent = ({children, ...props}) => <div {...props}>{children}</div>;

const EmployeeContainer = ({onInit, userName, url, ...props}) => {
  const person = useSelector(getSelectedPerson);
  const orgId = useSelector(getOrganisationId);
  const report = useSelector(getSelectedPersonReport);
  const roles = useSelector(getRoles);
  const organisations = useSelector(getOrganisations);
  const isFetchingFunctions = useSelector(getIsFetchingFunctions);
  const isMobile = useSelector(getIsMobile);

  const [modalsOpen, setModalsOpen] = useState({});
  const [dataLoaded, setDataLoaded] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(employeesFetchSelectedPerson({userName}));
  }, [dispatch, userName]);

  const closeAllModals = () => {
    setModalsOpen({});
  };

  const handleSendMessage = message => {
    dispatch(messagesSendMessage({
      ...message,
      callback: closeAllModals,
    }));
  };

  const handleEmployeeReportViewInit = useName => {
    dispatch(employeesFetchSelectedPersonReport({userName}));
  };
  const handleEmployeeEditViewInit = userName => {
    dispatch(employeesEditViewInit({userName}));
  };

  const handleCancelMessage = () =>
    setModalsOpen(old => ({
      ...old,
      sendMessage: false,
    }));

  const handleMessageEmployeeClick = () =>
    setModalsOpen(old => ({
      ...old,
      sendMessage: true,
    }));

  const handleEditEmploymentClick = () =>
    setModalsOpen(old => ({
      ...old,
      employment: true,
    }));

  const handleEditEmployeeClick = () => {
    history.push(getRouteWithParams(routes.employeeEdit, {
      userName,
      orgId,
    }));
  };

  const [exitAnimating, setExitAnimating] = useState(false);

  useEffect(() => {
    setExitAnimating(false);
  }, [userName, orgId]);

  const handleGotoEmployeesList = () => {
    setExitAnimating(true);
    setTimeout(() => {
      history.push(getRouteWithParams(routes.employees, {orgId}));
    }, 1200);
  };
  const redirectToPreviewEmployee = () => {
    history.push(getRouteWithParams(routes.employeePreview, {userName}));
  };
  const handleEditEmployeeFormSubmit = employee => {
    dispatch(employeesEditSelectedPerson({
      person: employee,
      refetchAfterEdit: true,
      callback: () => {
        redirectToPreviewEmployee();
      },
    }));
  };
  const handleResetPassword = identifier => {
    dispatch(employeesResetPassword({identifier}));
  };

  const handleEditPassword = data => {
    dispatch(employeesEditPassword({
      data,
      callback: redirectToPreviewEmployee,
    }));
  };

  const handleEmploymentFormSubmit = data => {
    setModalsOpen(old => ({
      ...old,
      employment: false,
    }));
    dispatch(employeesUpdateSelectedPersonEmployment({
      personId: person.data.id,
      data,
      callback: ({quitted}) => quitted && redirectToPreviewEmployee(),
    }));
  };

  const handleEmployedFormClose = () => {
    closeAllModals();
    setDataLoaded(old => ({
      ...old,
      employment: false,
    }));
  };

  return (
    <AnimatePresence>
      {!exitAnimating && (
        <motion.div
          key={userName}
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10000;
            background: #fff;
            margin: 0;
            overflow: auto;
          `}
          initial={{top: '100%'}}
          animate={{top: '0%'}}
          exit={{top: '100%'}}
          transition={{
            ease: 'easeOut',
            duration: 0.6,
          }}
          {...props}
        >
          {person.data && (
            <EmployeeCard
              isExpanded
              employee={person.data}
              onProfileClick={handleCancelMessage}
              onEditClick={handleEditEmployeeClick}
              onMessageClick={handleMessageEmployeeClick}
              onEditEmploymentClick={handleEditEmploymentClick}
              isMobile={isMobile}
              onCloseButtonClick={handleGotoEmployeesList}
            />
          )
        || person.isFetching && <EmployeeCardLoadingNew />}

          <EmployeePreviewMainContent className="employees-page__employee-preview-page-wrapper">
            <Route
              path={url}
              exact
            >
              <EmployeeOverview
                orgId={orgId}
                onClickOpenCompetenceOverview={() => history.push()}
                saveVerification={() => dispatch(employeesSaveVerification())}
                saveRequirements={() => dispatch(employeesSaveRequirements())}
              />
            </Route>

            <Route
              path={routes.employeeReport}
              render={({
                match: {
                  url,
                  params: {userName},
                },
              }) => (
                <>
                  <Breadcrumbs
                    style={{
                      marginTop: 0,
                      marginBottom: '0.5em',
                    }}
                    breadcrumbs={[
                      {
                        breadcrumb: i18n('person.profile'),
                        match: {
                          url: getRouteWithParams(routes.employeePreview, {
                            orgId,
                            userName,
                          }),
                        },
                      },
                      {
                        breadcrumb: i18n('person.competence-report'),
                        match: {
                          url: getRouteWithParams(routes.employeeReport, {
                            orgId,
                            userName,
                          }),
                        },
                      },
                    ]}
                    disableMyPage
                  />
                  <EmployeeReport
                    report={report}
                    onInit={() => handleEmployeeReportViewInit(userName)}
                  />
                </>
              )}
            />

            <Route
              path={routes.employeeEdit}
              render={({
                match: {
                  url,
                  params: {userName},
                },
              }) => (
                <>
                  <Breadcrumbs
                    style={{
                      marginTop: 0,
                      marginBottom: '0.5em',
                    }}
                    breadcrumbs={[
                      {
                        breadcrumb: i18n('person.profile'),
                        match: {
                          url: getRouteWithParams(routes.employeePreview, {
                            orgId,
                            userName,
                          }),
                        },
                      },
                      {
                        breadcrumb: i18n('person.change-personalia'),
                        match: {
                          url: getRouteWithParams(routes.employeeEdit, {
                            orgId,
                            userName,
                          }),
                        },
                      },
                    ]}
                    disableMyPage
                  />
                  <EmployeeEdit
                    person={person}
                    roles={roles}
                    organisations={organisations}
                    isFetching={isFetchingFunctions}
                    onInit={() => handleEmployeeEditViewInit(userName)}
                    onEmployeeFormSubmit={handleEditEmployeeFormSubmit}
                    onResetPassword={handleResetPassword}
                    onEditPassword={handleEditPassword}
                  />
                </>
              )}
            />
          </EmployeePreviewMainContent>

          {modalsOpen.sendMessage && (
            <Modal
              onClose={() =>
                setModalsOpen(old => ({
                  ...old,
                  sendMessage: false,
                }))}
              disableActionButtons
              title={() => (
                <Modal.Title>
                  {i18n('person.send-message-to-x', {functionArgs: {x: person.data.fullname}})}
                </Modal.Title>
              )}
            >
              <EmployeeMessage
                username={person.data.user_name}
                onSendMessage={handleSendMessage}
                onCancel={() =>
                  setModalsOpen(old => ({
                    ...old,
                    sendMessage: false,
                  }))}
              />
            </Modal>
          )}

          {modalsOpen.employment
        && (

          <Modal
            onClose={() => setModalsOpen(old => ({
              ...old,
              employment: false,
            }))}
            isFetching={!dataLoaded.employment}
            disableActionButtons
            title={() => <Modal.Title>{i18n('person.change-employment')}</Modal.Title>}
          >
            <PersonEmploymentForm
              roles={roles}
              userName={person.data.user_name}
              personId={person.data.id}
              onSubmit={handleEmploymentFormSubmit}
              onCancel={handleEmployedFormClose}
              onGotData={() => setDataLoaded(old => ({
                ...old,
                employment: true,
              }))}
            />
          </Modal>
        )}
        </motion.div>
      ) || null}
    </AnimatePresence>
  );
};

export default EmployeeContainer;
