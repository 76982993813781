import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import moment from 'moment';

import {RoleShape} from '@types/role';

import './person-employment-form.scss';
import axios from 'axios';
import {getOrganisationId} from '@selectors/profile.selectors';
import {backendUrl} from '@config';
import FormDatePicker from '@components/form-datepicker';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n, i18nLocaleDateStrToStdDateStr, i18nMoment} from '@src/i18n';

const validateDate = dateObj => {
  if(!dateObj) {
    return;
  }
  try {
    if (moment(dateObj).isValid()) {
      return undefined;
    }
  } catch {
    //
  }

  return 'Ugyldig dato';
};

const maskDate = value => {
  return value && moment(value, 'DD.MM.YYYY').toDate();
};

class PersonEmploymentForm extends Component {
  state = {
    personData: {
      isFetching: true,
      data: null,
    },
  };

  componentDidMount() {
    const {initialize, personId, orgId, onGotData} = this.props;

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/persons/employment_json/${personId}/${orgId}`,
        withCredentials: true,
      })
      .then(({data}) => {
        this.setState({
          personData: {
            isFetching: false,
            data,
          },
        });
        initialize(data);

        if (onGotData !== null) {
          onGotData();
        }
      });
  }

  render() {
    const {handleSubmit, onCancel} = this.props;
    const {personData} = this.state;

    return (
      <div>
        {!personData.isFetching && (
          <div>
            <form
              onSubmit={handleSubmit}
              className="person-employment-form"
            >
              <div className="person-employment-form__section-wrapper">
                <Field
                  component={FormDatePicker}
                  name="enddate"
                  label="Sluttdato"
                  type="text"
                  normalize={i18nLocaleDateStrToStdDateStr}
                />
                
                <Field
                  component={FormDatePicker}
                  className="employee-add__input"
                  name="absent_from"
                  label="Fraværende f.o.m."
                  type="text"
                  normalize={i18nLocaleDateStrToStdDateStr}
                />
                <Field
                  component={FormDatePicker}
                  className="employee-add__input"
                  name="absent_to"
                  label="Fraværende t.o.m."
                  type="text"
                  normalize={i18nLocaleDateStrToStdDateStr}
                /> 
              </div>

              <div className="role-form__submit-button-wrapper">

                <ActionButton
                  variant="text"
                  theme="black"
                  onClick={onCancel}
                  type="button"
                >
                  {i18n('globals.cancel')}
                </ActionButton>

                <ActionButton
                  variant="round"
                  theme="black"
                  type="submit"
                >
                  {i18n('globals.save')}
                </ActionButton>

              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

PersonEmploymentForm.propTypes = {
  personData: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  initialize: PropTypes.func.isRequired,

  personId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onGotData: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

PersonEmploymentForm.defaultProps = {onGotData: null};

const mapStateToProps = state => ({orgId: getOrganisationId(state)});

export default connect(mapStateToProps)(reduxForm({
  form: 'personemployment', // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(PersonEmploymentForm));
