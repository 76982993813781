import {css, jsx} from '@emotion/react';

export const highlightOnKeyboardFocusMixin = css`
  &.focus-visible {
    outline-color: rgb(77, 144, 254); // #4D90FE
outline-offset: 2px;
outline-style: auto;
outline-width: 5px;
  }
`;

export const highlightOnKeyboardFocusMixinExtra = css`
  &.focus-visible {
    outline: 2px solid black;
  }
`;

export const screenreaderOnlyMinxin = css`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;
