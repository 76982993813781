import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { highlightOnKeyboardFocusMixin } from "@styles/mixins";

export const Sender = styled.div`
  font-size: 0.85rem;
  font-weight: 400;
  color: black;
  margin-bottom: 0.35em;
`;

export const Subject = styled.div`
  font-weight: 500;
  font-size: 1.1rem;
  color: black;
`;

export const Header = styled.h2`
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

export const Time = styled.div`
  margin-left: auto;
  color: #9E9E9E;
  align-self: flex-start;
  white-space: nowrap;
`;

export const Content = styled.div`
  color: #616161;
  margin-top: 0;
  font-weight: 400;
  font-size: 0.96rem;
  ${({ isExpanded }) => (isExpanded ? css`` : css``)}
  p:first-child {
    margin-top: 0;
  }
`;

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    cursor: pointer;
  }
  &.focus-visible{
    ${highlightOnKeyboardFocusMixin};
  }
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid #C2C2C2;
  display: flex;
  margin-bottom: 1em;
`;