import React, {useState} from 'react';

import {
  ProgressWrapper,
  ProgressLine,
  ProgressText,
  ProgressBG,
} from '@routes/employees/components/employees-report/employees-report-styles';

const Progress = ({data}) => {
  return (
    <ProgressWrapper>
      <ProgressBG>
        <ProgressLine value={data[0]/data[1]*100} />
      </ProgressBG>
      <div style={{float: 'left', fontWeight: 600, paddingLeft: 4}}>
        {Math.round(data[0]/data[1]*100, 0)}%
      </div>
    </ProgressWrapper>
  );
};


export default Progress;
