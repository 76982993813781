import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Base, Input} from './styles';

export const FormInput = ({
  className,
  input,
  meta,
  placeholder,
  icon,
  sizeVariant,
  ...props
}) => (
  <Base sizeVariant={sizeVariant}>
    {icon && (
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
    )}
    <Input
      type="text"
      placeholder={placeholder}
      value={input.value || ''}
      autoComplete="off"
      {...props}
      {...input}
    />

    {meta.touched && meta.error && <div>{meta.error}</div>}
  </Base>
);

FormInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormInput.defaultProps = {className: ''};
