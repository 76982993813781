import {useState} from 'react';
import {Dashboard, DashboardRenderer, sampleData, Context} from '@snapper/dashboard';
import {AnimatePresence, motion} from 'framer-motion';
import {Block, Button, Label, Overlay, useRequiredContext} from '@snapper/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {backendUrl} from '@config';
import {IconButton} from '@components/buttons/iconButton';
import {i18n} from '@src/i18n';
import {css} from '@emotion/react';

const ReportDashboard = () => {
  const {data: {inputs}, renderItem, fetchReport} = useRequiredContext(Context);
  
  const [inputsOpen, setInputsOpen] = useState(false);

  return (
    <>
      <Block
        grow
        column
        padding="40px"
        paddingTop="2em"
        paddingBottom="80px"
        justify="top"
      >
        <Block
          grow
          column
          width="100%"
          maxWidth="1200px"
          marginTop="0.5em"
        >
          <Block
            row
            justify="right"
          >
            <Button
              buttonStyle="primary"
              onClick={() => {
                setInputsOpen(true);
              }}
            >
              Rediger
            </Button>
          </Block>
          <DashboardRenderer />
        </Block>
      </Block>
      <AnimatePresence>
        {inputsOpen
          && (
            <Overlay css={css`inset: 0;`}>
              <Block
                as={motion.div}
                initial={{top: '100%'}}
                animate={{top: '0%'}}
                exit={{top: '100%'}}
                transition={{
                  ease: 'easeOut',
                  duration: 0.6,
                }}
                background="#495866"
                width="100%"
                height="100%"
                justify="center"
                column
                grow
              >
                <Block
                  grow
                  column
                  margin="0 auto"
                  width="100%"
                  maxWidth="760px"
                >
                  <Block grow />
                  <Block
                    background="#fff"
                    padding="60px 40px"
                    column
                    shrink
                  >
                    {inputs?.map(renderItem)}
                    <Block
                      marginTop="30px"
                      justify="center"
                      row
                      marginBetween="10px"
                    >
                      <Button
                        onClick={() => {
                          setInputsOpen(false);
                        }}
                      >
                        <Label
                          size="1.1em"
                          nowrap
                          fontVariant="small-caps"
                        >
                          Lukk
                        </Label>
                      </Button>
                      <Button
                        buttonStyle="primary"
                        onClick={() => {
                          fetchReport();
                          setInputsOpen(false);
                        }}
                      >
                        <Label
                          size="1.1em"
                          nowrap
                          fontVariant="small-caps"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                          {' '}
                          Lag rapport
                        </Label>
                      </Button>
                    </Block>
                  </Block>
                  <Block
                    grow
                    background="#fff"
                  />
                </Block>
                <IconButton
                  className="close-btn"
                  theme="gray"
                  variant="round"
                  ariaLabel={i18n('globals.close')}
                  title={i18n('globals.close')}
                  onClick={() => {
                    setInputsOpen(false);
                  }}
                  icon={faTimes}
                  style={{
                    position: 'absolute',
                    right: '1.75em',
                    top: '1.75em',
                  }}
                />
              </Block>
            </Overlay>
          )}
      </AnimatePresence>
    </>
  );
};

export const ReportNew = () => (
  <Dashboard
    apiUrl={`${backendUrl}/api.v2`}
    data={sampleData}
  >
    <ReportDashboard />
  </Dashboard>
);