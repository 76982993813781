import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import './action-bar.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {employees as routes} from '@routes/routes.manifest';
import {backendUrl} from '@config';
import {ActionButton} from '@components/buttons/actionButton';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import {Dropdown} from '@components/dropdown/dropdown';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      type: props.type,
    };
  }

  render() {
    const {
      history: {push},
      orgId,
      searchEmployee,
    } = this.props;

    const {open, type} = this.state;

    return (
      <div className="action-bar">
        {type === 'employee-bar' && (
          <Dropdown
            showSelectedAsTitle={false}
            actionButton={{
              text: i18n('employees.actions'),
              souldOpenDropdown: true,
            }}
            onChange={val => {
              if(val === 'add-employee') {
                push(routes.addEmployee, {orgId});
              } else if(val === 'move-employee') {
                searchEmployee();
              } else if(val === 'export-excel') {
                window.location = `${backendUrl}/organisations/personstoexcel/${orgId}`;
              }
            }}
            items={[{
              label: i18n('employees.add-employee'),
              value:'add-employee',
            }, {
              label:  i18n('employees.move-employee'),
              value:'move-employee',
            }, {
                label:  i18n('employees.export-to-excel'),
                value:'export-excel',
              }
            ]}
          />
        )}
      </div>
    );
  }
}

ActionBar.propTypes = {
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,

  searchEmployee: PropTypes.func,
  type: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
};

ActionBar.defaultProps = {searchEmployee: null};

export default withRouter(ActionBar);
