import { i18n } from "@src/i18n";

export const employees = {
  dashboard: '/dashboard/:orgId',
  campExtra: '/dashboard/camp-extra',
  employees:        '/employees/:orgId',
  addEmployee:      '/employees/:orgId/user/add',
  employeePreview:  '/employees/:orgId/user/:userName',
  employeeReport:   '/employees/:orgId/user/:userName/fullreport',
  employeeEdit:     '/employees/:orgId/user/:userName/edit',
  employeeMessage:  '/employees/:orgId/user/:userName/message',
  reportRole:       '/employees/:orgId/report/role',
  reportCompetence: '/employees/:orgId/report/competence',
  reportPreview:    '/employees/:orgId/report/:reportId',
  reportNew: '/dashboard/competence-report',
  report: '/dashboard/report-full-competence/:orgId/:reportId',
  reportSearch: '/dashboard/competence-report/search',
  reportBrandschool: '/employees/competence-report/totalt',
};

export const courseCatalog = {
  main: {
    index: 0,
    path: '/course-catalog',
    active: '/course-catalog',
    breadcrumb: 'Kurskatalog',
  },
  category: {
    index: 1,
    path: '/course-catalog/category/:groupId',
    active: '/course-catalog/',
    breadcrumb: 'Kurskatalog',
  },
  coursePreview: {
    index: 2,
    path: '/course-catalog/:cid',
    active: '/course-catalog/',
    breadcrumb: 'Kurs',
  },
};

export const myEducation = {
  main: {
    index: 0,
    path: '/my-education',
    active: '/my-education',
    breadcrumb: 'Full rulle',
  },
  profileView: {
    index: 1,
    path: '/my-education/profile/:personId',
    active: '/my-education/profile/:personId',
    breadcrumb: 'Rediger profil',
  },
  courseView: {
    index: 3,
    path: '/my-education/course/:courseId',
    active: '/my-education/course',
    breadcrumb: 'Kurs',
  },
  cvView: {
    index: 4,
    path: '/my-education/cv/:personId',
    active: '/my-education/cv',
    breadcrumb: 'Kompetanserapport',
  },
  cvEdit:{
    index: 8,
    path: '/my-education/profile/:personId/edit-cv/:cvId',
    breadcrumb: 'CV',
  },
  cvAdd:{
    index: 9,
    path: '/my-education/profile/:personId/add-cv',
    breadcrumb: 'CV',
  },
  messagesView: {
    index: 7,
    path: '/my-education/messages/:personId',
    active: '/my-education/messages',
    breadcrumb: i18n('message.my-messages')
  },
  roleView: {
    index: 2,
    path: '/my-education/role/:roleId',
    active: '/my-education/role',
    breadcrumb: 'Rolle',
  },
  roleCourseView: {
    index: 5,
    path: '/my-education/role/:roleId/:courseId',
    active: '/my-education/role/:roleId/',
    breadcrumb: 'Kurs',
  },
};

export const atlas = {
  main: {
    index: 0,
    path: '/atlas/:id?',
    breadcrumb: 'Kart',
  },
};

export const content = {
  main: {
    index: 0,
    path: '/content/:contentId',
    active: '/content/:contentId',
    breadcrumb: '',
  },
};

export const nanoLearning = {
  main: {
    index: 0,
    path: '/nano-learning',
    active: '/nano-learning/',
    breadcrumb: 'Nano Learning',
  },
  player: {
    index: 0,
    path: '/nano-learning/:courseId',
    active: '/nano-learning/:courseId',
    breadcrumb: 'Nano Learning',
  },
};

export const logout = '/logout';
