import React from 'react';
import PropTypes from 'prop-types';
import {Circle} from 'rc-progress';
import classNames from 'classnames';
import {connect} from 'react-redux';
import Progress from '@components/progress/progress';

import '@routes/employees/styles/statistic-card.scss';
import {getIsMobile} from '@selectors/global.selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';

const StatisticCard = connect(state => ({isMobile: getIsMobile(state)}))(({
  clickable,
  name,
  key,
  firstItem,
  lastItem,
  icon,
  progress: {has, should, level},
  isMobile,
  ...props
}) => (
  <div
    className={classNames('statistic-card', {
      'statistic-card-mobile': isMobile,
      'statistic-card-first': firstItem,
      'statistic-card-last': lastItem,
      'statistic-card-clickable': clickable,
    })}
    {...props}
  >
    {isMobile && (
      <Progress
        progress={should && has / should * 100}
        tasksDone={has}
        tasks={should}
        taskName={name}
        fullWidth
      />
    ) || (
      <>
        <div className="statistic-card__inner">
          <div className="statistic-card__title">{name || <>&nbsp;</>}</div>
          {icon && (
            <div className="statistic-card__icon-wrapper">
              <FontAwesomeIcon icon={icon} style={{fontSize: 64}} />
            </div>
          ) || (
            <div className="statistic-card__circle-wrapper">
              <div className="statistic-card__circle-label">{level}%</div>
              <Circle
                percent={level}
                strokeWidth="9"
                trailWidth="9"
                trailColor="#F6F8FB"
                strokeColor="#1166FB"
              />
            </div>
          )}
        </div>
        {clickable && (
          <div className="statistic-card__link">
            {i18n('employees.report-view')}
            <FontAwesomeIcon
              icon={'arrow-circle-right'}
            />
          </div>
        )}
      </>
    )}
  </div>
));

StatisticCard.defaultProps = {
  firstItem: false,
  lastItem: false,
}

StatisticCard.propTypes = {
  name: PropTypes.string.isRequired,
  lastItem: PropTypes.bool,
  firstItem: PropTypes.bool,
  progress: PropTypes.shape({}).isRequired,
  clickable: PropTypes.bool.isRequired,
};

export default StatisticCard;

export const StatisticCardLoading = connect(state => ({isMobile: getIsMobile(state)}))(({isMobile, ...props}) => (
  <div
    className={classNames('statistic-card statistic-card__loading', {'statistic-card-mobile': isMobile})}
    {...props}
  >
    {isMobile && (
      <Progress
        progress={5}
        tasks
        loading
        fullWidth
      />
    ) || (
      <div className="statistic-card__inner">
        <div className="statistic-card__title">&nbsp;</div>
        <div className="statistic-card__circle-wrapper">
          <div className="statistic-card__circle-label">-/-</div>
          <Circle
            percent={0}
            strokeWidth="9"
            trailWidth="9"
            trailColor="#F6F8FB"
            strokeColor="#1166FB"
          />
        </div>
      </div>
    )}
  </div>
));
