import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as T from '@types/load.types';

import PropTypes from 'prop-types';
import {Section, Header, Content} from './employee-expired-styles';

import {i18n} from '@src/i18n';
import ExpiringList from '@components/expiring-competences';


const EmployeesExpired = ({
  isMobile,
  expired,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Section>
      <Header
        onClick={() => setExpanded(!expanded)}
      >
        {i18n('employees.expire-header')}
      </Header>
      <Content>
        {expired?.status !== T.LoadStatuses.LOADED && (
          <div>
            {i18n('globals.loading')}
          </div>
        ) || expired?.data.length !== 0 && (
          <div>
            {!expanded && (
            <div
              onClick={() => setExpanded(!expanded)}
            >
              {i18n('employees.expire-will',{functionArgs: {x:expired.data.length}})}
            </div>
              )}
            {expanded && (
              <ExpiringList
                expiring={expired.data}
              />
            )}
          </div>
        )}
      </Content>
    </Section>
  );
};

export default EmployeesExpired;
