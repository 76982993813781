import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';
import countdown from 'countdown';

import {Header, Subject, Sender, Time, Content, TopRow, Wrapper} from './message-styles';
import {i18n, i18nFormatTimeAgo} from '@src/i18n';
import PersonAvatar from '@components/person-avatar/person-avatar';
import {css} from '@emotion/react';
import {screenreaderOnlyMinxin} from '@styles/mixins';
import {ScreenreaderOnlyButton} from '@components/buttons/screenreaderOnlyButton';

const createMessageContent = (content, showAll) => {
  // we want to 'show' all content at all times, but only make the intro
  // content visible for the user, if we in collapsed mode
  // by 'showing' all content at all times, the width of the container will not change when we
  // go into expanded mode and we avoid a wierd moving-effect

  const introPart = content.slice(0, 120);

  return (
    showAll
      && <div dangerouslySetInnerHTML={{__html: content}} />
      || (
        <>
          <div dangerouslySetInnerHTML={{__html: introPart + '...'}} />
          <div
            css={css`
          height: 0px; 
          color: transparent;
          overflow: hidden;`}
            dangerouslySetInnerHTML={{__html: content}}
          />
        </>
      )
  );
};

const Message = ({message}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function formatStringToDate(timestamp) {
    // Gets timestamp as String and makes a Date out of it
    // const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    // const date = new Date(timestamp.replace(pattern, '$3-$2-$1'));

    const day = moment(timestamp).format('DD.MM.YYYY');
    const time = moment(timestamp).format('HH:mm');

    return moment(timestamp).isSame(moment(), 'day')
      ? `i dag kl. ${time}`
      : `${day} kl. ${time}`;
  }

  const getTimeFormatted = timeString => {
    const d = new Date(timeString);
    const now = new Date();
    const diff = countdown(d, now);

    if(diff.years || diff.months || diff.days > 10) {
      return moment(d).format('DD.MM.YY');
    }

    if(diff.days) {
      return i18nFormatTimeAgo('day', diff.days);
    }

    if(diff.hours) {
      return i18nFormatTimeAgo('hour', diff.hours);
    }

    if(diff.minutes > 0) {
      return i18nFormatTimeAgo('minute', diff.minutes);
    } else if(diff.minutes === 0) {
      return i18nFormatTimeAgo('lessThanMinute', diff.minutes);
    }
  };

  return (
    <Wrapper>

      <PersonAvatar
        person={{fullname: message.sender_name}}
        style={{
          minWidth:'50px',
          width:'50px',
          height:'50px',
          marginRight:'2em',
          marginTop:'1em'
        }}
      />
      <TopRow
        tabIndex={0}
        onClick={() => {
          if(!window.getSelection().toString()) {
            setIsExpanded(!isExpanded);
          }
        }}
        onKeyUp={evt => evt.key === 'Enter' && setIsExpanded(!isExpanded)}
      >
        <ScreenreaderOnlyButton
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
        >
          {i18n('globals.open-close-toggle')}
        </ScreenreaderOnlyButton>
        <Header>
          <Sender>
            {message.sender_name}
          </Sender>
          <Subject>

            {message.subject}
          </Subject>
          <Content
            isExpanded={isExpanded}
          >
            {createMessageContent(message.text, isExpanded)}
          </Content>

        </Header>
        <Time>
          {getTimeFormatted(message.ts)}
        </Time>
      </TopRow>
    </Wrapper>
  );
};

Message.propTypes = {message: PropTypes.shape({}).isRequired};
export default Message;
