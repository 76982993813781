import {css, jsx} from '@emotion/react';
import {NoDataDesc, ExperienceDescription, ExperienceWrapper, LeftPart, ExperienceKindTitle, RightPart, EditIcon, AddCVDataTxt, MissingCVTxt, MissingDescription} from './cv-view-styles';

import {ContentWrapper, MainHeader} from '../cv-styles';
import {Experience} from '../edit/edit-experience-type/edit-experience-type';
import {TopBar} from '../cv-components';
import {i18n} from '@src/i18n';

export const CVView = ({
  experiencesDividedByType,
  description,
  title,
  profile,
  isFetching,
  fullwidth,
  action,
  onEnterEditMode,
  onRemoveCv,
}) => {
  const noCVData = !isFetching && Object.values(experiencesDividedByType).length === 0 && !description  && !title && action ===  'edit';
  
  if (noCVData) {
    return (
      <div>
        <TopBar
          manageMode="view" 
          onClickEditSave={onEnterEditMode}
        />
        <ContentWrapper>
          <MissingCVTxt>{i18n('cv.missing-data')}</MissingCVTxt>
          <AddCVDataTxt>
            <p>
              {i18n('cv.add-cv-instruction')}
            </p>
          </AddCVDataTxt>
        </ContentWrapper>
      </div>
    );
  }
  
  return (
    <>
      <TopBar
        manageMode="view" 
        title={title}
        onClickEditSave={onEnterEditMode} 
        onClickRemove={onRemoveCv}
      />

      <ContentWrapper>
        <MainHeader>
          {isFetching
            ?  i18n('globals.loading', {ellipsis: true})
            : profile.data.fullname}
        </MainHeader>

        
        {!isFetching 
        && (
          <>
            <CvExperienceKindSection
              css={css`margin-bottom: 2.4rem;`}
            >
              {description && <ExperienceDescription>{description}</ExperienceDescription>
        ||           (
          <MissingDescription>
            {i18n('cv.no-desc-added')}
          </MissingDescription>
        )}
            </CvExperienceKindSection>
            <CvExperienceKindSection
              kind="work"
              items={experiencesDividedByType.work}
            />
            <CvExperienceKindSection
              kind="education"
              items={experiencesDividedByType.education}
            />
            <CvExperienceKindSection
              kind="project"
              items={experiencesDividedByType.project}
            />

            <CvExperienceKindSection
              kind="elected"
              items={experiencesDividedByType.elected}
            />

            <CvExperienceKindSection
              kind="language"
              items={experiencesDividedByType.language}
            /> 
          </>
        )}
      </ContentWrapper>
    </>
  );
};

const CvExperienceKindSection = ({
  items,
  titleStyle,
  centerTitle,
  kind,
  withEditAndDeleteButtons,
  onEditClick,
  children,
  ...props
}) =>  {
  const hasItems =  Array.isArray(items) && items.length > 0;
  const title = i18n(`cv.experience-kind-headers.${kind}`);
  const missingItemsTxt = i18n(`cv.no-items-of-kind.${kind}`);

  return (

    <div {...props}>
      {title && hasItems && (
        <ExperienceKindTitle>
          {title}
        </ExperienceKindTitle>
      )}

      {Array.isArray(items) && items.length === 0
        && <NoDataDesc>{missingItemsTxt}</NoDataDesc> 
        ||  Array.isArray(items) && items.map(item => (
          <ExperienceWrapper key={`${item.UID}`}>
            <LeftPart css={css`width: 100%;`}>
              <Experience 
                experience={item}
                experienceKind={kind}
              />
            </LeftPart>
            <RightPart />

          </ExperienceWrapper>
        ))}
      {children}
    </div>
  );
};