import {backendUrl} from '@config';
import {
  Cell,
  Dot,
  DotMatrixTable,
  Header,
  TableReport,
  RotatingHeader,
  RotatingHeaderContent,
} from '@routes/employees/components/employees-report/employees-report-styles';
import {css} from '@emotion/react';
import PersonAvatar from '@components/person-avatar/person-avatar';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees} from '@routes/routes.manifest';
import React, {useState} from 'react';
import {ArrowDown} from 'phosphor-react';
import styled from '@emotion/styled';

const ReportFull = ({mainOrganisation, report}) => {
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <>
      {new Date() === false && (
      <div className="employees-report__buttons">
        <a
          href={`${backendUrl}/custom/custompdf/pdf.mak?organisation_id=${
          mainOrganisation.id
        }&competence_ids=${
          report.data.reportCids
        }&title=${window.encodeURIComponent(report.data.reportTitle)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="far fa-file-pdf" />
          {' '}
          PDF
        </a>
      </div>
      )}
      <TableReport>
        <DotMatrixTable>
          <tr>
            <Header
              css={css`
                vertical-align: bottom;
                padding-bottom: 2.5em;
                min-width: 17em;
            `}
            >
              <div css={css`display: flex;`}>
                Navn
                <SortArrow
                  css={css`
                                margin-left: auto;
                                margin-right: 1em;
                            `}
                  sortOrder={sortOrder}
                  onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                  newDesc={sortOrder === 'desc' ? 'A til Å' : 'Å til A'}
                />
              </div>
            </Header>
            {report.data.courses.map(course => (
              <RotatingHeader nowrap>
                <RotatingHeaderContent>
                  {course.name}
                </RotatingHeaderContent>
              </RotatingHeader>
            ))}
          </tr>
          {report.data.persons.sort((a, b) => sortOrder === 'asc' ? a.fullname.localeCompare(b.fullname) : b.fullname.localeCompare(a.fullname)).map(person => (
            <tr key={person.id}>
              <Cell nowrap>
                <div css={css`display: flex;`}>
                  <PersonAvatar
                    person={person}
                    fontSize="0.8em"
                    css={css`
                                  width: 1.85em;
                                  height: 1.85em;
                                  margin-right: 0.5em;
                              `}
                  />
                  <Link
                    to={getRouteWithParams(employees.employeePreview, {
                      userName: person.user_name,
                      orgId: null,
                    })}
                    style={{color: 'black'}}
                  >
                    {person.fullname}
                  </Link>
                </div>
              </Cell>
              {person.courses.map(personCourse => (
                <Cell>
                  <Dot
                    key={personCourse.id}
                    fill={personCourse.class && personCourse.class.includes(' mandatory')
                      ?                             personCourse.class.includes(' ok') && 'red' || 'green'
                      : 'gray'}
                  >
                    {personCourse.passed ? personCourse.score : ''}
                  </Dot>
                </Cell>
              ))}
            </tr>
          ))}
        </DotMatrixTable>
      </TableReport>
    </>
  );
};

const SortArrow = ({onClick, sortKey, newDesc, sortOrder, ...props}) => (
  <SortArrowWrapper
    {...props}
    rotation={sortOrder === 'asc' ? '0deg' : '180deg'}
  >
    <ArrowDown
      role="button"
      tabIndex="0"
      aria-label={`Endre sortering av ${sortKey} til ${newDesc}`}
      onClick={onClick}
    />
  </SortArrowWrapper>
);

const SortArrowWrapper = styled.div`
  transform: rotate(${props => props.rotation});
  transform-origin: center center;
  &:hover{
    cursor: pointer;
  }
`;

export default ReportFull;
