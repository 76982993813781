/* eslint-disable no-debugger */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';

import {Wrapper} from '@routes/content/components/contentViewer/styles';
import AspectRatio from '@components/common/aspect-ratio';
import ContentLoader from 'react-content-loader';

/* Displays a skeleton for an article */
const ArticleLoader = ({
  isMobile,
  wrapperstyle,
  wrapperstyleMobile,
  imagePartStyle,
  imagePartStyleMobile,
  textPartStyle,
  textPartStyleMobile,
}) => (
  <Wrapper
    aria-hidden
    css={css`
      padding: 2em;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;

      margin-top: ${isMobile ? '-7.5em' : '0em'};
      ${wrapperstyle};
      ${isMobile ? wrapperstyleMobile : ''}
    `}
  >
    <div>
      <AspectRatio
        ratio={1.7}
        css={css`
          margin-top: 0em;
          height: 550px;
          margin-bottom: ${isMobile ? '0.5em' : 'unset'};
          ${imagePartStyle}
          ${isMobile ? imagePartStyleMobile : ''}
        `}
      >
        <div
          className="course-card__cover--image"
          style={{backgroundColor: '#f3f3f3'}}
        />
      </AspectRatio>
    </div>
    <ContentLoader
      css={css`
      ${textPartStyle};
      ${isMobile ? textPartStyle : ''};
      `}
      height={1500}
      width={1200}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect
        x="15"
        y={isMobile ? '0' : '10'}
        rx="4"
        ry="4"
        width="1250"
        height="25"
      />

      <rect
        x="100"
        y="180"
        rx="4"
        ry="4"
        width="1250"
        height="30"
      />
      <rect
        x="100"
        y="250"
        rx="4"
        ry="4"
        width="1050"
        height="30"
      />
      <rect
        x="100"
        y="320"
        rx="4"
        ry="4"
        width="1150"
        height="30"
      />

      <rect
        x="100"
        y="450"
        rx="4"
        ry="4"
        width="1300"
        height="30"
      />
      <rect
        x="100"
        y="520"
        rx="4"
        ry="4"
        width="1250"
        height="30"
      />
      <rect
        x="100"
        y="590"
        rx="4"
        ry="4"
        width="1150"
        height="30"
      />
      <rect
        x="100"
        y="660"
        rx="4"
        ry="4"
        width="1200"
        height="30"
      />
      <rect
        x="100"
        y="730"
        rx="4"
        ry="4"
        width="1200"
        height="30"
      />

      {isMobile
        && (
          <rect
            x="100"
            y="800"
            rx="4"
            ry="4"
            width="1300"
            height="30"
          />
        )}
    </ContentLoader>
  </Wrapper>
);

ArticleLoader.propTypes = {
  isMobile: PropTypes.bool,
  wrapperstyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperstyleMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
ArticleLoader.defaultProps = {
  isMobile: false,
  wrapperstyle: '',
  wrapperstyleMobile: '',
};

export default ArticleLoader;

export const MapLoader = ({
  isMobile,
  wrapperstyle,
  wrapperstyleMobile,
  imagePartStyle,
  imagePartStyleMobile,
}) => (
  <div>
    <div css={css`
      display: flex;
      max-width: 1024px;
      margin: 0 auto;
      flex-direction: row;
      margin-top: 80px;
    `}
    >
      <div
        css={css`
          width: 50%;
          height: 500px;
          margin-right: 50px;
          background-color: #f3f3f3;
      `}
      >
        <Wrapper
          aria-hidden
          css={css`

      padding: 2em;
      max-width: 850px;
      margin-left: auto;
      height: 400;
      margin-right: auto;

    `}
        />
      </div>

      <div
        css={css`
          width: 50%;
          display: flex;
          margin-top: -100px;
          height: 600px;
          justify-content: center;
          flex-display: row;
          align-items: center;
      `}
      >
        <ContentLoader
          height={'100%'}
          width={600}
          speed={2}
          style={{height: 'auto'}}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="15"
            y={isMobile ? '0' : '10'}
            rx="4"
            ry="4"
            width="70%"
            height="25"
          />
          <rect
            x="15"
            y={isMobile ? '0' : '46'}
            rx="4"
            ry="4"
            width="90%"
            height="18"
          />
        </ContentLoader>
      </div>
    </div>

  </div>
);


export const FullscreenArticleLoader = () => (
  <div
    css={css`
        position: fixed;
        background: white;
        top: 0;
        left: 0;
        background-color: #000334;
        margin: auto;
        width: 100vw;
        height: 100wh;
        z-index: 3000;
      `}
  >
    <ArticleLoader
      wrapperstyle={css`
          height: 100vh;
          position: relative;
          top: -4.5em;
        `}
    />
  </div>
);
