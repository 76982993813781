import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getProfile} from '@selectors/profile.selectors';

import {routerMyEducationMessagesMainViewDidMount} from '@actions/router.actions';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {i18n} from '@src/i18n';

import {ProfileBaseInformation} from '@routes/my-education';
import '../styles/my-education-container.scss';
import {getRouteWithParams} from '@utils/routes.utils';
import PersonInfo from '@routes/my-education/components/person-info';

import MessagesList from '../components/messages-list';
import * as T from '../../../store/types/load.types';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';


class MyEducationMessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.redirectToProfileView = this.redirectToProfileView.bind(this);
  }

  componentDidMount() {
    const {routerMyEducationMessagesMainViewDidMount} = this.props;

    routerMyEducationMessagesMainViewDidMount('messages');
  }

  redirectToProfileView() {
    const {history} = this.props;

    history.push(myEducationRoutes.profileView.path);
  }

  render() {
    const {profile, messages, breadcrumbs = [], match} = this.props;

    return (
      <div className="my-education">
        <div className="my-education__section">
          <Breadcrumbs 
            breadcrumbs={[
              {
                breadcrumb: myEducationRoutes.messagesView.breadcrumb,
                match,
              },
            ]}
          />
          <h2 className="messages-header">{i18n('message.my-messages')}</h2>
          <div className="my-education__section messages">
            {messages.status === T.LoadStatuses.LOADED && (
              <div className="messages-list-wrapper">
                {messages && messages.data.length !== 0 
                  && <MessagesList messages={messages.data} />
                 || (
                   <div>
                     {i18n('message.no-messages')}
                   </div>
                 )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MyEducationMessagesContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  messages: PropTypes.shape({}).isRequired,
  routerMyEducationMessagesMainViewDidMount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  messages: state.messages.all,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {routerMyEducationMessagesMainViewDidMount},
    dispatch,
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MyEducationMessagesContainer);
