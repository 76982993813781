import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Route, Redirect, Switch} from 'react-router-dom';
import Dropzone from 'react-dropzone';

import {PersonShape} from '@types/person';
import {RoleShape, OrganisationShape} from '@types/role';
import {fetchedDataWrapperShape} from '@types/fetched-data';
import {getIsMobile} from '@selectors/global.selectors';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

import {routerMyEducationProfileViewDidMount} from '@actions/router.actions';
import {
  profileChangeProfilePicture,
  profileEditPerson,
  profileEditPassword,
} from '@actions/profile.actions';

import {getProfile} from '@selectors/profile.selectors';
import {getRoles, getOrganisations} from '@selectors/roles.selectors';

import PersonForm from '@components/person-form/person-form';

import PersonAvatar from '@components/person-avatar/person-avatar';

import '../styles/profile-container.scss';
import {i18n} from '@src/i18n';

class ProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.handleProfileImageChange = this.handleProfileImageChange.bind(this);
    this.handlePersonFormSubmit = this.handlePersonFormSubmit.bind(this);
    this.handleEditPassword = this.handleEditPassword.bind(this);
  }

  state = {
    avatarPreview: undefined,
    loading: false,
  };

  componentDidMount() {
    const {routerMyEducationProfileViewDidMount} = this.props;

    routerMyEducationProfileViewDidMount();
  }

  handleEditPassword(data) {
    const {profileEditPassword} = this.props;

    profileEditPassword({data});
  }

  handleProfileImageChange([file]) {
    const {profile, profileChangeProfilePicture} = this.props;
    const {person_id} = profile.data;

    profileChangeProfilePicture({
      person_id,
      file,
    });
    this.setState({avatarPreview: file.preview});
  }

  handlePersonFormSubmit(person) {
    const {
      history: {push},
      profileEditPerson,
    } = this.props;

    this.setState({loading: true});

    profileEditPerson({person});
    push('/my-education');
    this.forceUpdate();
  }

  render() {
    const containerBaseUrl = myEducationRoutes.profileView.path;
    const {profile, roles, organisations, isMobile} = this.props;
    const {avatarPreview, loading} = this.state;

    return (
      <Switch>
        <Route
          path={`${containerBaseUrl}/edit`}
          render={() => (
            <div className="profile-page">
              <div className="profile-page__section">
                {(profile.isFetching || loading) && (
                  <>
                    <i className="fa fa-spin fa-spinner" />
                    {' '}
                    Laster...
                  </>
                )
                  || (profile.error && 'Failed to load data...'
                    || profile.data && (
                      <div className="profile-page__card">
                        <div className="profile-page__profile-picture-wrapper">
                          <Dropzone onDrop={this.handleProfileImageChange}>
                            {({getRootProps, getInputProps}) => (
                              <div
                                className="form-input"
                                {...getRootProps()}
                              >
                                <label
                                  htmlFor="profile_picture"
                                  className="form-input__label"
                                >
                                  {i18n('person.profile-picture')}
                                  <input
                                    id="profile_picture"
                                    {...getInputProps()}
                                  />
                                </label>
                                <PersonAvatar
                                  person={profile.data}
                                  imageUrl={avatarPreview}
                                  editable
                                  to={false}
                                  className="profile-page__profile-picture"
                                />
                                <p className="profile-page__add-profile-picture-label">
                                  {avatarPreview
                                  || profile.data.profile_image
                                    && profile.data.profile_image.url
                                    ?   i18n('person.click-to-edit-profile-picture') 
                                    : i18n('person.click-to-upload-profile-picture')}
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div className="profile-page__person-form-wrapper">
                          <PersonForm
                            initialValues={{
                              user_name: profile.data.user_name,
                              firstname: profile.data.firstname,
                              lastname: profile.data.lastname,
                              email: profile.data.email,
                              mobile: profile.data.mobile,
                              roles:
                                Array.isArray(roles.data)
                                && Array.isArray(profile.data.roles)
                                  ? roles.data.filter(role =>
                                    profile.data.roles
                                        && profile.data.roles.some(personRole =>
                                          personRole.id === role.id))
                                  : [],
                              organisations:
                                Array.isArray(organisations.data)
                                && Array.isArray(profile.data.organisations)
                                  ? organisations.data.filter(organisation =>
                                    profile.data.organisations.some(personOrganisation =>
                                      personOrganisation.id
                                          === organisation.id))
                                  : [],
                            }}
                            destroyOnUnmount={false}
                            editMode={false}
                            isMobile={isMobile}
                            roles={roles}
                            onEditPassword={this.handleEditPassword}
                            organisations={organisations}
                            onSubmit={this.handlePersonFormSubmit}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )}
        />
        <Redirect to={`${containerBaseUrl}/edit`} />
      </Switch>
    );
  }
}

ProfileContainer.propTypes = {
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,
  profile: fetchedDataWrapperShape(PersonShape()).isRequired,
  roles: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  organisations: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(OrganisationShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  onEditPassword: PropTypes.func.isRequired,
  routerMyEducationProfileViewDidMount: PropTypes.func.isRequired,
  profileChangeProfilePicture: PropTypes.func.isRequired,
  profileEditPerson: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  roles: getRoles(state),
  isMobile: getIsMobile(state),
  organisations: getOrganisations(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationProfileViewDidMount,
      profileChangeProfilePicture,
      profileEditPassword,
      profileEditPerson,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileContainer);
