import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {forwardRef} from 'react';
import {ActionButtonStyled, Separator} from './buttonStyles';
import {css} from '@emotion/react';

import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {ArrowRight} from 'phosphor-react';

/** *
 * props:
 * variant: controlls the shape and look-and-feel of the button
 * theme: adjusts the colors for a given variant
 */

export const ActionButton = forwardRef(({
  children, withRightChrevron, withRightArrow, small,
  withFontawesomeIcon, withPhosphorIcon, isFetching, theme, variant, fontSize, ...props
}, ref) => {
  const PhosphorIconComponent = withPhosphorIcon && withPhosphorIcon.icon;

  const themeToUse = theme || {link:'blue'}[variant] || 'black';
  const variantToUse = variant || 'round';

  return (

    <ActionButtonStyled
      ref={ref}
      theme={themeToUse}
      variant={variantToUse}
      isFetching={isFetching}
      withRightArrow
      fontSize={fontSize}
      small={small}
      {...props}
    >

      {withPhosphorIcon && withPhosphorIcon.position === 'left'
        &&           (
          <PhosphorIconComponent
            css={{
              marginRight:'0.8em',
              fontSize:'0.8em',
            }}

            {...withPhosphorIcon}
          />
        )}
      {withFontawesomeIcon && withFontawesomeIcon.position === 'left'
        && (
          <FontAwesomeIcon
            icon={withFontawesomeIcon.icon}
            css={{
              marginRight:'0.8em',
              fontSize:'0.8em',
            }}
          />
        )}
      {!isFetching && children}
      {withFontawesomeIcon && withFontawesomeIcon.position === 'right'
        && (
          <FontAwesomeIcon
            icon={withFontawesomeIcon.icon}
            css={{
              marginLeft:'0.8em',
              fontSize:'0.8em',
            }}
          />
        )}

      {withPhosphorIcon && withPhosphorIcon.position === 'right'
        &&    <PhosphorIconComponent  />}
      {isFetching && (
        <span
          aria-hidden="true"
          style={{color: 'transparent'}}
        >
          Test1234567
        </span>
      )}
      {withRightChrevron && !isFetching && (
        <FontAwesomeIcon
          css={{
            marginLeft: '0.75em',
            fontSize: '0.8em',
            alignSelf:'center',
          }}

          icon={faChevronRight}
        />
      )}

      {withRightArrow && !isFetching
      &&       (
        <div
          css={css`
          position: absolute;
          display: flex;
          right: 0.9em;
          align-items: center;;
      `}>

          <Separator theme={themeToUse} />
          <ArrowRight size="0.95em"/>
        </div>
      )}
    </ActionButtonStyled>
  );
});
