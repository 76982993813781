import themedLogo from '@components/themed-logo/themed-logo';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';
const _ = require('lodash');

export const Wrapper = styled.div`
  display: flex;
  background: #495866;
  color: #ffffff;
  padding: 4.5em 0em;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
`;

export const LeftPart = styled.div``;
export const RightPart = styled.div`
  margin-left: auto;
`;
export const Heading = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.4em;
  margin-top: 0;
`;
export const SubHeading = styled.p`
  color: white;
  margin: 0 !important;
  font-weight: 500;
`;


const colors = {
  gray: {
    bg: '#ffffff',
    bgOpacity: 0.2,
    fg: '#ffffff',
  },
  darkGray:{
    bg:'#464646',
    bgOpacity: 0.2,
  },
  black: {
    bg: '#000000',
    fg: '#ffffff',
  },
  blueOnWhite: {
    bg: '#ffffff',
    fg: '#0b5dd7',
  },
  white:{
    bg: '#ffffff',
    fg: '#0a0a0a',
  },
  red:{
    bg: '#F03E3E',
    fg: '#ffffff',
  },
  blue:{
    bg: '#0B5CD7',
    fg: '#ffffff',
  },

};

const getColor = path => rgba(_.get(colors, path), _.get(colors, path + 'Opacity') || 1);

const styleMatrix = {
  secondary: {
    black: {
      normal: css`
        background: white;
        color: black;
    `,
      hover: css`color: ${darken(0.2, getColor('black.bg'))};`,
    },
  },
  primary: {
    black: {
      normal:
        css`
            color: ${colors.black.fg};
            background: ${colors.black.bg};`,
      hover: css`background: ${lighten(0.2, getColor('black.bg'))};`,
    },
    gray: {
      normal: css`
            color: ${colors.gray.fg};
            background: ${getColor('gray.bg')};
      `,
      hover: css`
        background: ${darken(0.2, getColor('gray.bg'))};
    `,
    },
  },
  submit:{
    red:{
      normal: css`
        color: ${colors.red.fg};
        background: ${colors.red.bg};
      `,
      hover: css`
        background: ${darken(0.06, getColor('red.bg'))};
      `,
    },
    blue:{
      normal: css`
        color: ${colors.blue.fg};
        background: ${getColor('blue.bg')};
      `,
      hover: css`
        background: ${darken(0.05, rgba(colors.blue.bg, 1))};
      `,
    },
    white:{
      normal: css`
        color: ${getColor('white.fg')};
        background: ${getColor('white.bg')};
        border: 1px solid #E0E0E0;
      `,
      hover: css`
        background: ${darken(0.03, getColor('white.bg'))};
      `,
    },
  },
  round: {
    black: {
      normal:
        css`
            color: ${colors.black.fg};
            background: ${colors.black.bg};
        `,
      hover: css`background: ${lighten(0.2, getColor('black.bg'))};`,
    },
    gray: {
      normal: css`
            color: ${colors.gray.fg};
            background: ${getColor('gray.bg')};
      `,
      hover: css`
    background: ${darken(0.2, getColor('gray.bg'))};
  `,
    },

    darkGray: {
      normal: css`
            color: ${colors.gray.fg};
            background: ${getColor('darkGray.bg')};
      `,
      hover: css`
    background: ${darken(0.2, getColor('darkGray.bg'))};
  `,
    },
  },
  text:{
    black:{
      normal: css``,
      hover: css``,
    },
  },
  link: {
    blue: {
      normal: css`color: ${colors.blueOnWhite.fg}; background: ${colors.blueOnWhite.bg};`,
      hover: css`color: ${darken(0.2, getColor('blueOnWhite.fg'))};`,
    },
  },
};

export const ActionButtonStyled = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em;
  position: relative;

  font-size: ${({fontSize})=> fontSize ? fontSize : '0.9em'};

  &:hover {
    cursor: pointer;
    ${({theme, variant}) => styleMatrix[variant][theme].hover}
  }

  &:focus {
    outline: none;
  }

  &.focus-visible {
    box-shadow: 0 0 0 3px #537fc3;
  }

  margin-bottom: 0;
  margin-left: 0;

  ${({variant, fontSize, small}) =>
  ({
    round: css`

        font-size: ${fontSize ? fontSize : '1em'};
        padding: 0.7em 2.2em;
        border-radius: 999px;
      `,
    primary: css`
        padding: 1.1em 0.9em;
        border-radius: 4px;
      `,
    secondary: css`
        font-weight: 500;
      `,
    link: css`
        font-size: 1em;
      `,
    submit: css`
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      font-size: ${fontSize ? fontSize : '1em'};
      padding: 1em 1em;
      margin-bottom: 0;
      margin-right: 0;
      margin-left: 0.6em;

      ${small && css`
          font-size: 0.85rem;
          padding: 0.75rem 1.1rem;
      `}
    `,
    text: css`
     font-size: 1em;
    `,
  }[variant])};

  ${({theme, variant}) => styleMatrix[variant][theme].normal}
`;

const iconButtonColors = {
  grayTransparent: {
    bg: 'rgba(255, 255, 255, 0.2)',
    fg: 'rgb(255,255,255)',
  },
  gray:{
    bg: 'gray',
    fg:'white',
  },
};

export const IconButtonStyled = styled(ActionButtonStyled)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1em;
  font-size: 1.1em;
  margin: 0;

`;

export const PersonInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5em;
`;
export const Personalia = styled.div`
  margin-left: 1.5em;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.9em;
`;


export const Separator = styled.div`
width: 1px;
height: 1.3em;
margin-left: 0.4em;
    margin-right: 0.4em;
  ${({theme}) => ({
  black: css`
      background: rgba(255, 255, 255, 0.2);
    `,
}[theme])}
`;
