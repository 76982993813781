import '../styles/messages-list.scss';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Message from './message/message';
import axios from 'axios';

import {backendUrl} from '@config';
import Calendar from '@components/calendar/calendar';

const MessagesList = ({messages}) => {
  const [messageData, setMessageData] = useState(null);

  const loadMessageData = () => {
    const messages_mock  = JSON.parse(`[
        {
          "files": null,
          "is_sms": 0,
          "id": null,
          "message_id": 1234,
          "person": {
            "email": "test@example.com",
            "fullname": "Test mr Test",
            "id": 139780
          },
          "recipient_address": "test@example.com",
          "recipient_name": "Test Test",
          "reply_to": null,
          "sender_name": "Anonymous Anonymous",
          "subject": "Glemt passord",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse molestie suscipit eros ac vehicula. Curabitur non urna vestibulum, rhoncus sem nec, pretium ante. Phasellus imperdiet lorem in bibendum rhoncus. Maecenas eu mollis magna. Nulla pharetra sem eget urna molestie posuere. Nam ut mi ligula. Morbi eros libero, ornare eget mollis vehicula, tincidunt eget tellus. Vestibulum rutrum dui eget ligula iaculis ultricies. Maecenas et elit feugiat, accumsan libero sed, maximus libero. Mauris sodales arcu risus, id imperdiet arcu rutrum sit amet.</p>",
          "ts": "2021-06-27 09:10:03"
        },
        {
          "files": null,
          "is_sms": 0,
          "id": null,
          "message_id": 1234,
          "person": {
            "email": "person@example.com",
            "fullname": "Person Example",
            "id": 139780
          },
          "recipient_address": "test@example.com",
          "recipient_name": "Person Example",
          "reply_to": null,
          "sender_name": "Anonymous Anonymous",
          "subject": "Mail2",
          "text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse molestie suscipit eros ac vehicula. Curabitur non urna vestibulum, rhoncus sem nec, pretium ante. Phasellus imperdiet lorem in bibendum rhoncus. Maecenas eu mollis magna. Nulla pharetra sem eget urna molestie posuere. Nam ut mi ligula. Morbi eros libero, ornare eget mollis vehicula, tincidunt eget tellus. Vestibulum rutrum dui eget ligula iaculis ultricies. Maecenas et elit feugiat, accumsan libero sed, maximus libero. Mauris sodales arcu risus, id imperdiet arcu rutrum sit amet.</p>",
          "ts": "2021-06-09 10:35:03"
        }
      ]`);

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/messages`,
        withCredentials: true,
      })
      .then(({data: {messages: message}}, // etc
      ) => setMessageData(message));
  };

  useEffect(() => {
    loadMessageData();
  }, []);


  return (
    <>

    {/* 

    <Calendar /> */}
      <div className="messages-list">
        {messages.length === 0
          && <div className="nomsg">Du har ikke motatt noen meldinger.</div>
         || (
           <div className="messages-wrapper">
             {messageData
              && messageData.map(message => (
                <Message
                  key={message.message_id}
                  message={message}
                />
              ))}
           </div>
         )}
      </div>
    </>
  );
};

MessagesList.propTypes = {messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired};

export default MessagesList;
