import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import completed from '../../../assets/svg/completed.svg';

import {getRouteWithParams} from '@utils/routes.utils';
import CourseButtons from '@components/course/course-buttons';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

const createSubtitleContent = content => ({__html: content});

class CompetenceCourse extends Component {
  constructor(props) {
    super(props);
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const {size, course, roleId, defaultImg, isPassed} = this.props;

    return (
      <>
        {size === 'big' && (
          <>
            {isPassed && (
              <>
                <Link
                  to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                    courseId: course.id,
                    roleId,
                  })}
                  className="cell small-3 medium-3"
                >
                  <div
                    className="img"
                    style={{
                      minHeight: 120,
                      backgroundImage: `url(${course.cover && course.cover.url
                      || defaultImg})`,
                    }}
                  />
                </Link>
                <div className="content cell small-8 medium-8">
                  <Link
                    to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                      courseId: course.id,
                      roleId,
                    })}
                    className="__title"
                  >
                    <h2>{course.competence_title}</h2>
                  </Link>
                  <div
                    style={{
                      color: 'gray',
                      width: '80%',
                      'float': 'left',
                    }}
                  >
                    {course.competence_type.competence_type
                    && <div>{course.competence_type.title}</div>}
                    <div
                      className="desc"
                      style={{
                        color: 'rgba(0,0,0,0.5)',
                        paddingTop: 10,
                      }}
                      dangerouslySetInnerHTML={createSubtitleContent(course.short_description && course.short_description.replace(/(<([^>]+)>)/gi, '').slice(0, 150) + '...')}
                    />
                    <CourseButtons course={course} />
                  </div>
                  <div
                    style={{
                      color: 'gray',
                      width: '10%',
                      'float': 'right',
                    }}
                  >
                    <img
                      alt="completed"
                      src={completed}
                    />
                  </div>
                </div>
              </>
            ) || (
              <>
                <Link
                  to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                    courseId: course.id,
                    roleId,
                  })}
                  className="cell small-4 medium-4"
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${course.cover && course.cover.url
                    || defaultImg})`,
                    }}
                  />
                </Link>
                <div className="content cell small-7 medium-7">
                  <Link
                    to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                      courseId: course.id,
                      roleId,
                    })}
                    className="__title"
                  >
                    <h2>{course.competence_title}</h2>
                  </Link>
                  {course.competence_type.competence_type
                  && <div>{course.competence_type.title}</div>}
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
                  />
                  {course.files && !!course.files.length && (
                    <div className="course-preview__files">
                      {course.files.map(({title, url}) =>
                        title !== 'cover' && (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn course-preview__file-button"
                          >
                            <i className="fas fa-file-alt" />
                            {' '}
                            {title}
                          </a>
                        ))}
                    </div>
                  )}

                  <CourseButtons course={course} />
                </div>
              </>
            )}
          </>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  <div className="roles-list__competence-title">
                    <div
                      className={classNames([
                        'roles-list__competence-status',
                        {
                          'roles-list__competence-status--not-started':
                            course.passed === 0,
                        },
                        {
                          'roles-list__competence-status--in-progress':
                            course.passed > 0 && course.passed < 100,
                        },
                        {
                          'roles-list__competence-status--completed':
                            course.passed === 100,
                        },
                      ])}
                    />
                    <div>
                      <div className="small-header">
                        {course.competence_type.title}
                        {roleId === -2 && course.passed !== 100 && (
                          <div className="removeThis">
                            <button
                              type="button"
                              onClick={() =>
                                this.removePersonalCompetence(course.competence_id)}
                            >
                              <i className="far fa-trash-alt" />
                            </button>
                          </div>
                        )}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon="calendar-alt" />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon="user-check" />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

CompetenceCourse.propTypes = {
  course: PropTypes.shape({}).isRequired,
  size: PropTypes.string,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
};

CompetenceCourse.defaultProps = {
  size: 'normal',
  removeRequirement: undefined,
  roleId: -2,
};

export default CompetenceCourse;
