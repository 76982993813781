import {screenreaderOnlyMinxin} from '@styles/mixins';

export const ScreenreaderOnlyButton = ({children, ...props}) => (
  <button
    css={screenreaderOnlyMinxin}	
    tabIndex="-1"
    {...props}
  >
    {children}
  </button>
);