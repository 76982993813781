import React, {Component, useEffect} from 'react';
import {profileFetchCvs} from '@actions/profile.actions';
import {getCVs, getProfile} from '@selectors/profile.selectors';

import {useDispatch, useSelector} from 'react-redux';

import {Wrapper, Table, Header, Title} from './cvs-list-styles';
import {i18n, i18nFrmatDate} from '@src/i18n';
import {css, jsx} from '@emotion/react';
import {ActionButton} from '@components/buttons/actionButton';
import {useHistory} from 'react-router';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';

export const CvsList = ({...props}) => {
  const dispatch = useDispatch();

  const cvs = useSelector(getCVs);
  const profile = useSelector(getProfile);
  const history = useHistory();

  useEffect(() => {
    if(!cvs.data && !cvs.isFetching && !cvs.error) {
      dispatch(profileFetchCvs());
    }
  }, [cvs, dispatch]);

  const openCvView = cvId => {
    history.push(getRouteWithParams(myEducationRoutes.cvEdit.path, {
      personId: profile.data.id,
      cvId,
    }));
  };

  const openAddNewCvView = () => {
    history.push(getRouteWithParams(myEducationRoutes.cvAdd.path, {personId: profile.data.id}));
  };

  return (
    <Wrapper {...props}>
      {cvs.data && !cvs.isFetching && (
        <>
          <Header>
            <Title>{i18n('cv.my-cvs')}</Title>
            <ActionButton
              variant="submit"
              theme="blue"
              small
              css={css`
                height: fit-content;
                margin-left: auto;
              `}
              onClick={openAddNewCvView}
            >
              {i18n('globals.create-new')}
            </ActionButton>
          </Header>

          {Object.values(cvs.data).length > 0 
          && (
            <Table>
              <thead>
                <tr>
                  <th className="name">{i18n('globals.name')}</th>
                  <th className="last-updated">{i18n('globals.last-updated')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(cvs.data).map((cv, idx) => (
                  <tr onClick={() => openCvView(cv.id)}>
                    <td className="name">
                      {cv.title || (
                        <i>
                          CV
                          {idx + 1}
                        </i>
                      )}
                    </td>
                    <td className="last-updated">
                      {i18nFrmatDate(cv.last_updated)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}

      {cvs.error &&
        <p css={css`font-size: 1.2rem;`}>{i18n('cv.cvs-fetch-failure', {ellipsis: true})}</p>
      }

      {cvs.data && Object.values(cvs.data).length === 0 && !cvs.isFetching && (
        <p
          css={css`
            font-size: 0.85rem;
          `}
        >
          Ingen CV-er lagt til enda. Trykk på «Opprett ny» for å opprette en.
        </p>
      )}
    </Wrapper>
  );
};