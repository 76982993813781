
import React from 'react';
import {lastDateNextYear} from '@utils/misc.utils';

import {Field} from 'redux-form';
import FormDatePicker from '@components/form-datepicker';
import FormInput from '@components/form-input';

import {maskDate, validateDate} from '../util';
import {i18n, i18nLocaleDateStrToStdDateStr} from '@src/i18n';
import moment from 'moment';

const validateRequired = input => {
  if (!input || !input.trim()) {
    return i18n('globals.required-field');
  }

  return undefined;
};

const validateIsFourdigitYear = input => {
  if(!input) {
    return undefined;
  }

  const parsedInt = Number.parseInt(input);

  if (Number.isNaN(parsedInt) || (parsedInt < 1900 || parsedInt > 2200)) {
    return i18n('globals.invalid-year-field');
  }

  return undefined;
};

const validateAlsoHasStartyear = (input, allInputs) => {
  if(!input) {
    return undefined;
  }

  if(input && !allInputs.startyear) {
    return i18n('cv.no-endyear-without-startyear');
  }
};

const validateAlsoHasStartdate = (input, allInputs) => {
  if(!input) {
    return undefined;
  }

  if(input && !allInputs.startdate) {
    return i18n('cv.no-endyear-without-startdate');
  }
};

const validateStartdateIsBeforeEnddate = (enddateInput, allInputs) => {
  if(!enddateInput) {
    return undefined;
  }

  if(moment(enddateInput).isBefore(moment(allInputs.startdate))) {
    return i18n('cv.enddate-is-before-startdate');
  }

  return undefined;
};

const validateStartyearIsBeforeEndyear = (endyearInput, allInputs) => {
  if(!endyearInput) {
    return undefined;
  }

  if(endyearInput < allInputs.startyear) {
    return i18n('cv.endyear-is-before-startyear');
  }

  return undefined;
};

export const ProjectFormElements = () => (
  <>
    <Field
      component={FormInput}
      autoFocus
      label="Navn"
      name="designation"
      validate={[validateRequired]}
    />

    <Field
      component={FormInput}
      label="Beskrivelse"
      name="name"
      validate={[validateRequired]}
    />

    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="startdate"
      label="Fra"
      type="text"
      
    />

    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="enddate"
      label="Til (blank hvis du ikke er ferdig)"
      type="text"
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
    />

  </>
); 

export const LanguageFormElements = () => (
  <>
    <Field
      component={FormInput}
      label="Språk"
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label="Ferdighet i språket"
      name="designation"
      validate={[validateRequired]}
    />
  </>
); 


export const WorkExperiencesFormElements = () => (
  <>
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.work-position')}
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.work-company')}
      name="name"
      validate={[validateRequired]}
    />


    <Field
      component={FormDatePicker}
      label={i18n('cv.form-elements.work-fromdate')}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="startdate"
      type="text"
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
      name="enddate"
      type="text"
      label={i18n('cv.form-elements.work-todate')}
    />
  </>
);

export const HonoraryOrVolunteerElements = () => (
  <>
    <Field
      component={FormInput}
      label="Stilling / rolle"
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label="Selskap / Bedrift"
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      label="Fra"
      name="startdate"
      type="text"
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      label="Til (tom hvis du ikke er ferdig / har sluttet)"
      name="enddate"
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
      type="text"
    />
  </>
);

export const EducationFormElements = () => (
  <>
    <Field
      component={FormInput}
      label="Sted"
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label="Navn på utdanning"
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      type="number"
      label="Start (år)"
      step="1"
      name="startyear"
      validate={[validateIsFourdigitYear]}
    />
    <Field
      component={FormInput}
      type="number"
      label="Slutt (år)"
      step="1"
      name="endyear"
      validate={[validateIsFourdigitYear, validateAlsoHasStartyear, validateStartyearIsBeforeEndyear]}
    />
  </>
);