/* eslint-disable camelcase */
/* eslint-disable no-console */
import {
  call,
  all,
  put,
  take,
  select,
  takeLatest,
  takeEvery,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import * as T from '../../store/types/load.types';

import {backendUrl} from '@config';

import {stringifyUrlParams} from '@utils/requests.utils';
import qs from 'qs';

import {
  getSelectedPersonUsername,
  getSelectedPerson, getTree,
} from '@selectors/employees.selectors';
import {getProfile, getOrganisationId} from '@selectors/profile.selectors';

import {
  routerEmployeesListDidMount,
  routerEmployeesMainViewDidMount,
} from '@actions/router.actions';
import {authUnauthorized} from '@actions/auth.actions';
import {
  EMPLOYEES_GET,
  employeesGetRequest,
  employeesGetSuccess,
  employeesGetFailure,
  employeesSearchPhrazeChange,
  employeesSearchRequest,
  employeesSearchSuccess,
  employeesSearchFailure,
  EMPLOYEES_FETCH_FUNCTIONS,
  employeesFetchFunctionsRequest,
  employeesFetchFunctionsSuccess,
  employeesFetchFunctionsFailure,
  EMPLOYEES_FETCH_SELECTED_PERSON,
  employeesFetchSelectedPerson,
  employeesFetchSelectedPersonRequest,
  employeesFetchSelectedPersonSuccess,
  employeesFetchSelectedPersonFailure,
  EMPLOYEES_ADD_PERSON,
  employeesAddPersonPostFailure,
  employeesAddPersonPostRequest,
  employeesAddPersonPostSuccess,
  EMPLOYEES_EDIT_SELECTED_PERSON,
  employeesEditSelectedPersonPutFailure,
  employeesEditSelectedPersonPutRequest,
  employeesEditSelectedPersonPutSuccess,
  EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES,
  EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS,
  employeesFetchSelectedPersonChecklistsRequest,
  employeesFetchSelectedPersonChecklistsSuccess,
  employeesFetchSelectedPersonChecklistsFailure,
  employeesUpdateSelectedPersonRolesPostRequest,
  employeesUpdateSelectedPersonRolesPostSuccess,
  employeesUpdateSelectedPersonRolesPostFailure,
  employeesFetchSelectedPersonCompetencesRequest,
  employeesFetchSelectedPersonCompetencesSuccess,
  employeesFetchSelectedPersonCompetencesFailure,
  employeesUpdateSelectedPersonEmploymentRequest,
  employeesUpdateSelectedPersonEmploymentSuccess,
  employeesUpdateSelectedPersonEmploymentFailure,
  employeesFetchSelectedPersonReportRequest,
  employeesFetchSelectedPersonReportSuccess,
  employeesFetchSelectedPersonReportFailure,
  EMPLOYEES_REMOVE_REQUIREMENTS,
  employeesRemoveRequirementsRequest,
  employeesRemoveRequirementsSuccess,
  employeesRemoveRequirementsFailure,
  EMPLOYEES_EDIT_VIEW_INIT,
  EMPLOYEES_REPORT_VIEW_INIT,
  EMPLOYEES_FETCH_STATISTICS,
  employeesFetchStatisticsGetRequest,
  employeesFetchStatisticsGetSuccess,
  employeesFetchStatisticsGetFailure,
  employeesFetchChecklistsGetRequest,
  employeesFetchChecklistsGetSuccess,
  employeesFetchChecklistsGetFailure,
  EMPLOYEES_FETCH_ACTIVITIES,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES,
  employeesFetchSelectedPersonActivitiesRequest,
  employeesFetchSelectedPersonActivitiesSuccess,
  employeesFetchSelectedPersonActivitiesFailure,
  employeesFetchSelectedPersonExpiringRequest,
  employeesFetchSelectedPersonExpiringSuccess,
  employeesFetchSelectedPersonExpiringFailure,
  employeesFetchActivitiesGetRequest,
  employeesReportFailure,
  employeesReportSuccess,
  employeesReportRequest,
  employeesFetchActivitiesGetSuccess,
  employeesFetchActivitiesGetFailure,
  EMPLOYEES_RESET_PASSWORD,
  employeesResetPasswordRequest,
  employeesResetPasswordSuccess,
  employeesResetPasswordFailure,
  EMPLOYEES_EDIT_PASSWORD,
  employeesEditPasswordRequest,
  employeesEditPasswordSuccess,
  employeesEditPasswordFailure,
  employeesFetchExtraDataRequest,
  employeesFetchExtraDataSuccess,
  employeesFetchExtraDataFailure,
  EMPLOYEES_FETCH_EXTRADATA,
  EMPLOYEES_SAVE_VERIFICATION,
  employeesSaveVerificationRequest,
  employeesSaveVerificationSuccess,
  employeesSaveVerificationFailure,
  employeesSaveRequirementsRequest,
  employeesSaveRequirementsSuccess,
  employeesSaveRequirementsFailure,
  EMPLOYEES_SAVE_REQUIREMENTS,
  employeesSaveRoleRequest,
  employeesSaveRoleSuccess,
  employeesSaveRoleFailure,
  EMPLOYEES_SAVE_ROLE,
  employeesFetchEventsRequest,
  employeesFetchEventsSuccess,
  employeesFetchEventsFailure,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES,
  EMPLOYEES_FETCH_EVENTS,
  employeeMoveRequest,
  employeeMoveSuccess,
  employeeMoveFailure,
  employeesGet,
  EMPLOYEES_FETCH_EXPIRING_COMPETENCES,
  employeesFetchSelectedPersonExpiring,
  employeesFetchExpiringCompetencesSuccess,
  employeesFetchExpiringCompetencesFailure,
  EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING,
  employeesFetchSelectedPersonEvents,
  EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS,
  employeesFetchSelectedPersonEventsFailure,
  employeesFetchSelectedPersonEventsRequest,
  employeesFetchSelectedPersonEventsSuccess,
  EMPLOYEE_MOVE,
  EMPLOYEES_FETCH_CHECKLISTS,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT,
  EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT,
  employeesFetchOrganisationRequest,
  employeesFetchOrganisationSuccess,
  employeesFetchOrganisationFailure, employeesFetchTreeProgressFailure,
  employeesFetchEventsWaitlistRequest,
  employeesFetchEventsWaitlistFailure,
  employeesFetchEventsWaitlistSuccess,
  EMPLOYEES_FETCH_EVENTS_WAITLIST

} from '@actions/employees.actions';

import * as EA from '@actions/employees.actions';

import {
  profileFetchPersonRequest,
  PROFILE_FETCH_PERSON_SUCCESS,
  profileFetchPerson,
} from '@actions/profile.actions';

import {notificationsAdd} from '@actions/notifications.actions';
import reports from '@routes/reports.manifest';

import retry from '@utils/sagas.utils';
import {i18n} from '@src/i18n';



function* fetchEmployees(action) {
  try {
    const profile = yield select(getProfile);
    let limit = 10;

    if (action.payload && action.payload.all) {
      limit = 100;
    }

    yield put(employeesGetRequest());
    const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);
    let hasChildren = false;

    if (organisationId) {
      const organisationResponse = yield retry(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/api/organisations/${organisationId}`,
          params: {
            fields:
              'has_children',
            limit,
          },
          withCredentials: true,
        }));

      hasChildren = organisationResponse.data.organisations[0].has_children;
    }

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    const employeesResponse = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/persons`,
        params: {
          fields:
            'user_name,progress,email,phone,mobile,fullname,firstname,lastname,positions(role_id,title,organisation_id),profile_image,data(avatar),tasks,is_absent',
          limit,
          absent: '1',
        },
        withCredentials: true,
      }));

    let employees = employeesResponse.data.persons;
    let hasMore = false;

    if (employees.length >= limit) {
      hasMore = true;
      employees = employees.slice(0, limit - 1);
    }

    yield put(employeesGetSuccess({
      employees,
      hasChildren,
      hasMore,
    }));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }

    yield put(employeesGetFailure({error}));
  }
}

function* searchEmployees(action) {
  const {searchPhraze: query} = action.payload;
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }

  if (query.length > 3) {
    yield put(employeesSearchRequest());

    try {
      const {persons} = yield call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/search?items=persons&mode=partial&organisation_ids=${organisationId}`,
            params: {
              query,
              fields:
                'user_name,email,phone,mobile,fullname,firstname,lastname,profile_image',
            },
            withCredentials: true,
          })
          .then(({data}) => data));

      yield put(employeesSearchSuccess({employees: persons}));
    } catch (error) {
      console.error(error);
      if (error.status === 401) {
        yield put(authUnauthorized({error}));
      }
      yield put(employeesSearchFailure({error}));
    }
  }
}

function* fetchSelectedPersonReport(payload) {
  const {userName} = payload.payload;

  yield put(employeesFetchSelectedPersonReportRequest());

  try {
    const {data: {persons}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/persons/${userName}`,
        params: {fields: 'id'},
        withCredentials: true,
      }));
    const personId = persons[0].id;
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/reports/personfullcompetencereportjson/${personId}`,
        params: {
          fields:
            'id,passed,date,certificate_url,competence_title,competence(competence_type,files)',
        },
        withCredentials: true,
      }));

    yield put(employeesFetchSelectedPersonReportSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchSelectedPersonReportFailure());
  }
}

function* fetchSelectedPersonActivites(payload) {
  yield put(employeesFetchSelectedPersonActivitiesRequest());
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: payload.payload.userName,
          fields:
            'id,passed,date,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'missing',
        },
        withCredentials: true,
      }));
    const selectedPerson = yield select(getSelectedPerson);

    for (const competence of competences) {
      competence.children = [];
    }
    selectedPerson.data.summary.activities.children = competences;
    selectedPerson.data.summary.activities.isFetching = false;
    const new_data = selectedPerson.data;

    yield put(employeesFetchSelectedPersonActivitiesSuccess({data: new_data}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchSelectedPersonActivitiesFailure());
  }
}

function* fetchSelectedPersonExpiring(payload) {
  yield put(employeesFetchSelectedPersonExpiringRequest());
  const userName = payload ? payload.payload.user_name : null;

  try {
    const {data: {personcompetences: data}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expired',
        },
        withCredentials: true,
      }));

    yield put(employeesFetchSelectedPersonExpiringSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchSelectedPersonExpiringFailure());
  }
}

/*
function* fetchPersonRoles({ userName }) {
  try {
    const {
      data: { roles },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles/`,
        params: {
          user_name: userName,
          summary: 1,
          role_meta_types: 'position,role',
          fields: 'title,id',
        },
        withCredentials: true,
      })
    );

    return yield all(
      roles.map(role => call(fetchPersonRoleCompetences, { role, userName }))
    );
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    return new Error(error);
  }
}
*/

function* fetchSelectedReport(action) {
  const {reportId, orgId} = action.payload;
  try {
    yield put(employeesReportRequest({reportId}));
    if (reportId === 'role') {
      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/organisations/statistics_json/${orgId}/person-role`,
          withCredentials: true,
        }));
        yield put(employeesReportSuccess({
          data: {
            ...data.data,
            reportId,
          },
        }));
    }else if (reportId === 'competence') {
      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/organisations/statistics_json/${orgId}/competence`,
          withCredentials: true,
        }));
        yield put(employeesReportSuccess({
          data: {
            ...data.data,
            reportId,
          },
        }));
    }else {
      let cti = [];
      let title = '';

      if (reportId in reports) {
        // eslint-disable-next-line prefer-destructuring
        cti = reports[reportId].cti;
        title = reports[reportId].name;
      } else {
        const req_data = yield call(() =>
          axios.request({
            method: 'GET',
            url: `${backendUrl}/api/organisationalfunctions`,
            params: {
              organisation_id: orgId,
              fields: 'title,required_competences(competence_id)',
            },
            withCredentials: true,
          }));
        const id_data = req_data.data.roles.find(r => r.id === Number.parseInt(reportId, 0));

        // eslint-disable-next-line prefer-destructuring
        title = id_data.title;

        for (let i = 0; i < id_data.required_competences.length; i += 1) {
          cti.push(id_data.required_competences[i].id);
        }
        cti = cti.join(',');
      }

      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/reports/coursematrixjson`,
          params: {
            organisation_id: orgId,
            competence_ids: cti,
          },
          withCredentials: true,
        }));

      data.data.reportTitle = title;
      data.data.reportCids = cti;
      yield put(employeesReportSuccess({
        data: {
          ...data.data,
          reportId,
        },
      }));
    }
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized());
    }

    yield put(employeesReportFailure({error}));
  }
}

function* fetchExtraData() {
  try {
    yield put(employeesFetchExtraDataRequest());
    const data = yield all({
      downloads: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_downloads`,
            withCredentials: true,
          })
          .then(response => response.data)),
      metrics: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_metrics`,
            withCredentials: true,
          })
          .then(response => response.data)),
      orgs: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_orgs`,
            withCredentials: true,
          })
          .then(response => response.data)),
      employees: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_employees`,
            withCredentials: true,
          })
          .then(response => response.data)),
    });

    yield put(employeesFetchExtraDataSuccess({data}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(employeesFetchExtraDataFailure({error}));
  }
}
function* fetchSelectedPersonCompetences(action) {
  try {
    const {personId, roleId, type, orgId} = action.payload;

    yield put(employeesFetchSelectedPersonCompetencesRequest({personId}));

    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/rolecompetence_json/${personId}/${roleId}/${type}/${orgId}/0`,
        params: {
          ajax: 1,
          role_meta_types: 'position,role',
        },
        withCredentials: true,
      }));
    const selectedPerson = yield select(getSelectedPerson);

    let section = null;

    if (type === 'mandatory') {
      section = selectedPerson.data.summary.requirement.filter(c => c.id === roleId);
    } else if (type === 'other') {
      // eslint-disable-next-line prefer-destructuring
      section = selectedPerson.data.summary.other;
    } else {
      section = selectedPerson.data.summary.optional.filter(c => c.id === roleId);
    }

    /* JUST SET UT DATA STRUCTURE */
    for (const datum of data) {
      datum.children = [];
      datum.isFetching = false;
    }

    if (section && section.length) {
      section[0].children = data;
    }

    selectedPerson.data.summary.isFetching = false;
    const new_data = selectedPerson.data;

    yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));

    /*
    yield put(
      employeesFetchSelectedPersonSuccess({
        person: {
          ...person,
          competencelevel,
          events,
          summary: personSummary,
          activities,
        },
      })
    );
    */
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(employeesFetchSelectedPersonCompetencesFailure({error}));
  }
}

/*
 * FETCH USERS CHECKLISTS AND RETURN DATA.
 * OTHER THINGS CONTROLL WHERE TO STORE IT.
 * */

function* fetchSelectedPersonChecklistsAPI(payload) {
  const {userName, organisationId} = payload;

  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/pending_checklists`,
        params: {
          user_name: userName,
          limit: 20,
        },
        withCredentials: true,
      }));

    return data.persons;
  } catch (error) {
    console.error(error);
  }

  return null;
}

function* fetchSelectedPersonChecklists(action) {
  let organisationId = yield select(getOrganisationId);

  try {
    while (organisationId === null) {
      yield take();
      organisationId = yield select(getOrganisationId);
    }
    yield put(employeesFetchSelectedPersonChecklistsRequest());
    const checklists = yield call(() =>
      fetchSelectedPersonChecklistsAPI({
        userName: action.payload.userName,
        organisationId,
      }));

    yield put(employeesFetchSelectedPersonChecklistsSuccess({data: checklists}));
  } catch (error) {
    console.error(error);

    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }

    yield put(employeesFetchSelectedPersonChecklistsFailure({error}));
  }
}

function* fetchSelectedPerson(action) {
  const {userName} = action.payload;

  yield put(employeesFetchSelectedPersonRequest({userName}));
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }

  try {
    const {
      person,
      personSummary,
      competencelevel,
      events,
      checklists,
      activities,
    } = yield all({
      person: call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/persons/${userName}?fields=fullname,is_absent,organisations(organisation_id,title,brand_id),email2,user_name,firstname,lastname,email,mobile,profile_image,positions(title,role_id,organisation_id)`,
            withCredentials: true,
          })
          .then(response => {
            const person = response.data.persons[0];

            if (person && person.is_absent) {
              person.absent_data = call(() =>
                axios
                  .request({
                    method: 'GET',
                    url: `${backendUrl}/persons/employment_json/${person.id}/${organisationId}`,
                    withCredentials: true,
                  })
                  .then(({data}) => data));
            }

            return person;
          })),
      competencelevel: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/competencelevel`,
            params: {user_name: userName},
            withCredentials: true,
          })
          .then(response => response.data.competencelevel[0].level)),
      events: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/personevents`,
            params: {
              user_name: userName,
              fields: 'title,location,competence_id,startdate,enddate,person',
            },
            withCredentials: true,
          })
          .then(response => response.data.personevents)),
      personSummary: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/roles`,
            params: {
              user_name: userName,
              oid: organisationId,
              summary: 1,
            },
            withCredentials: true,
          })
          .then(response => response.data)),
      activities: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/organisations/${organisationId}/activities`,
            params: {
              user_name: userName,
              limit: 20,
              fields:
                'person(profile_image,fullname,firstname,lastname,user_name),competence_title,competence_id,passed,date,subject,ts',
            },
            withCredentials: true,
          })
          .then(({data}) => {
            const {activities} = data;

            activities.sort(({date: date1, ts: ts1}, {date: date2, ts: ts2}) =>
              date1 && (date1 > date2 && -1 || date1 < date2 && 1)
                || ts1 && (ts1 > ts2 && -1 || ts1 < ts2 && 1)
                || 0);

            return activities;
          })),
      checklists: fetchSelectedPersonChecklistsAPI({
        userName: action.payload.userName,
        organisationId,
      }),
    });

    personSummary.isFetching = false;
    personSummary.activities = {};
    personSummary.activities.isFetching = false;
    personSummary.activities.children = [];
    for (let i = 0; i < personSummary.requirement.length; i += 1) {
      personSummary.requirement[i].children = [];
      personSummary.requirement[i].isFething = false;
    }
    for (let i = 0; i < personSummary.other.length; i += 1) {
      personSummary.other[i].children = [];
      personSummary.other[i].isFething = false;
    }
    for (let i = 0; i < personSummary.optional.length; i += 1) {
      personSummary.optional[i].children = [];
      personSummary.optional[i].isFething = false;
    }

    yield put(employeesFetchSelectedPersonSuccess({
      person: {
        ...person,
        competencelevel,
        events,
        checklists,
        summary: personSummary,
        activities,
      },
    }));
    yield put(employeesFetchSelectedPersonExpiring({user_name: userName}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    if (error.response.status === 403 || error.response.status === 404) {
      yield put(notificationsAdd({
        notification: {
          text: 'Du har ikke tilgang til denne profilen',
          color: 'red',
          redirectTo: `/employees/${organisationId}`,
        },
      }));
    }

    yield put(employeesFetchSelectedPersonFailure({error}));
  }
}

function* addPerson(action) {
  const {employee, callback} = action.payload;
  const organisationId = yield select(getOrganisationId);
  const {
    send_login,
    user_name,
    firstname,
    lastname,
    email,
    role_id,
    role_ids,
    mobile,
    employee_code,
  } = employee;
  let new_role_ids = '';

  if (role_ids) {
    new_role_ids = role_ids.map(r => r.id);
    new_role_ids = new_role_ids.join(',');
  }

  const do_send_login = send_login ? '1' : '';
  const newPerson = {
    user_name:
      user_name
      || `${firstname}.${lastname}`.replace(/[^.@_a-z-]/gi, '').toLowerCase(),
    firstname,
    lastname,
    ajax: 1,
    send_login: do_send_login,
    role_id,
    employee_code,
    mobile,
    email,
    'role_ids[]': new_role_ids,
    oid: organisationId,
  };

  try {
    yield put(employeesAddPersonPostRequest());

    const data = yield call(() =>
      axios
        .request({
          method: 'POST',
          data: qs.stringify(newPerson),
          url: `${backendUrl}/persons/save`,
          withCredentials: true,
        })
        .then(response => response.data));

    if (data.statuscode === 0) {
      yield call(() => callback({employee: newPerson}));
      yield put(employeesAddPersonPostSuccess({data}));
    } else {
      yield put(notificationsAdd({
        notification: {
          text: data.status,
          color: 'red',
        },
      }));
      yield put(employeesAddPersonPostFailure());
    }
  } catch (error) {
    console.error(error);
    yield put(notificationsAdd({
      notification: {
        text: 'Kunne ikke opprette ny bruker, prøv igjen.',
        color: 'red',
      },
    }));
    yield put(employeesAddPersonPostFailure({error}));
  }
}

function* editSelectedPerson(action) {
  const {person: editedPerson, callback, refetchAfterEdit} = action.payload;

  try {
    yield put(employeesEditSelectedPersonPutRequest());

    const userName = yield select(getSelectedPersonUsername);

    const {user_name, ...personData} = editedPerson;

    const params = encodeURI(stringifyUrlParams(personData, 'person_data', true));
    const {person} = yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      }));


    yield call(() => callback({employee: editedPerson}));

    yield put(employeesEditSelectedPersonPutSuccess({person}));
    yield put(notificationsAdd({
      notification: {
        text: 'Bruker oppdatert',
        color: 'green',
      },
    }));

    const {data: profile} = yield select(getProfile);

    if (user_name === profile.user_name) {
      yield put(profileFetchPerson());
    }

    if(refetchAfterEdit) {
      yield put(employeesFetchSelectedPerson({userName: user_name}));
    }
  } catch (error) {
    console.error(error);
    yield put(employeesEditSelectedPersonPutFailure({message: 'failed to save employee'}));
  }
}

function* updateSelectedPersonEmployment(action) {
  try {
    const {personId, callback} = action.payload;
    const orgId = yield select(getOrganisationId);
    const {payload} = action;
    const params = {
      ...payload.data,
      ajax: 1,
    };

    yield put(employeesUpdateSelectedPersonEmploymentRequest());

    const {data: return_data} = yield call(() =>
      axios.request({
        method: 'POST',
        params,
        url: `${backendUrl}/persons/saveajax/${personId}/${orgId}/0`,
        withCredentials: true,
      }));

    if (return_data.statuscode === 0) {
      yield put(employeesUpdateSelectedPersonEmploymentSuccess({personId}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.change-employment-success'),
          color: 'green',
        },
      }));
      yield call(() => callback(return_data));
    } else {
      yield put(employeesUpdateSelectedPersonEmploymentFailure({}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.change-employment-failure'),
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(employeesUpdateSelectedPersonEmploymentFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.change-employment-failure'),
        color: 'red',
      },
    }));
  }
}

function* moveEmployee(action) {
  try {
    const {personId, move_type, position} = action.payload;
    const orgId = yield select(getOrganisationId);

    yield put(employeeMoveRequest());
    let operation = 'add';

    if (move_type === '2') {
      operation = 'move';
    }
    const params = {
      person_id: personId,
      organisation_id: orgId,
      position_id: position,
      json: 1,
      operation,
    };

    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params,
        url: `${backendUrl}/persons/move`,
        withCredentials: true,
      }));

    yield put(employeeMoveSuccess({personId}));
    yield put(notificationsAdd({
      notification: {
        text: data.message,
        color: 'green',
      },
    }));

    yield put(employeesGet());
  } catch (error) {
    console.error(error);
    yield put(employeeMoveFailure({error}));
  }
}

function* updateSelectedPersonRoles(action) {
  try {
    const userName = yield select(getSelectedPersonUsername);
    const {roles} = action.payload;
    const params = encodeURI(stringifyUrlParams({role_ids: roles.map(role => role.id)}));

    yield put(employeesUpdateSelectedPersonRolesPostRequest());
    yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}/roles?${params}`,
        withCredentials: true,
      }));
    yield put(employeesUpdateSelectedPersonRolesPostSuccess({userName}));
  } catch (error) {
    console.error(error);
    yield put(employeesUpdateSelectedPersonRolesPostFailure({error}));
  }
}

function* fetchChecklists(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchChecklistsGetRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data: {persons}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/pending_checklists`,
        withCredentials: true,
      }));

    yield put(employeesFetchChecklistsGetSuccess({persons}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchChecklistsGetFailure({message: 'falied to save employee'}));
  }
}

function searchItems(node, find) {
  if (node.organisation_id === find) {
    return node;
  } else if (node.children != null) {
    let result = null;

    for (let i = 0; result == null && i < node.children.length; i++) {
      result = searchItems(node.children[i], find);
    }

    return result;
  }

  return null;
}

function* fetchOrganisationProgress(action) {
  console.log(action);
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  yield put(EA.employeesFetchTreeProgressRequest({
    orgId: organisationId,
    fetchMore: action.payload.fetchMore,
  }));
  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/getorgstats_json?oid=${organisationId}`,
        withCredentials: true,
      }));

    yield put(EA.employeesFetchTreeProgressSuccess({
      orgId: organisationId,
      data,
    }));
  } catch (error) {
    console.log(error);
    yield put(EA.employeesFetchTreeProgressFailure({message: 'falied to save employee'}));
  }
}
function* fetchOrganisationTree(action) {
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  yield put(EA.employeesFetchTreeRequest({
    orgId: organisationId,
    fetchMore: action.payload.fetchMore,
  }));
  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/organisationmaplevel_json/${organisationId}`,
        withCredentials: true,
      }));

    data.children.forEach(child => {
      child.children = [];
      child.progress_status = T.LoadStatuses.NOT_LOADED;
      child.status = T.LoadStatuses.NOT_LOADED;
    });

    yield action.payload.fetchMore
      ? put(EA.employeesFetchTreeSuccess({
        data,
        fetchMore: true,
        orgId: organisationId,
      }))
      : put(EA.employeesFetchTreeSuccess({data}));
    yield put(EA.employeesFetchTreeProgress({orgId: organisationId}));
    yield all(data.children.map(item => put(EA.employeesFetchTreeProgress({orgId: item.organisation_id}))));
  } catch (error) {
    console.log(error);
    yield put(EA.employeesFetchTreeFailure({message: 'falied to save employee'}));
  }
}

function* fetchOrganisation(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchOrganisationRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/organisationmaplevel_json/${organisationId}?simple=1`,
        withCredentials: true,
      }));

    yield put(employeesFetchOrganisationSuccess({data}));
  } catch (error) {
    console.log(error);
    yield put(employeesFetchOrganisationFailure({message: 'falied to save employee'}));
  }
}

function* fetchStatistics(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchStatisticsGetRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data: {statistics}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/statistics`,
        withCredentials: true,
      }));

    yield put(employeesFetchStatisticsGetSuccess({statistics}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchStatisticsGetFailure({message: 'falied to save employee'}));
  }
}

function* fetchExpiringCompetences(action) {
  try {
    const profile = yield select(getProfile);

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    yield put(employeesFetchActivitiesGetRequest());
    const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          limit: 20,
          organisation_ids: organisationId,
          state: 'expired',
          fields: 'valid_until,person,competence_title,competence_id',
        },
        withCredentials: true,
      }));

    yield put(employeesFetchExpiringCompetencesSuccess({expiring: data.personcompetences}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchExpiringCompetencesFailure({message: 'failed to fetch activities'}));
  }
}

function* fetchActivities(action) {
  try {
    const profile = yield select(getProfile);

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    yield put(employeesFetchActivitiesGetRequest());
    const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);
    const {data: {activities}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/activities`,
        params: {
          limit: 20,
          fields:
            'person(profile_image,fullname,firstname,lastname,user_name),competence_title,competence_id,passed,date,subject,ts',
        },
        withCredentials: true,
      }));

    activities.sort(({date: date1, ts: ts1}, {date: date2, ts: ts2}) =>
      date1 && (date1 > date2 && -1 || date1 < date2 && 1)
        || ts1 && (ts1 > ts2 && -1 || ts1 < ts2 && 1)
        || 0);
    yield put(employeesFetchActivitiesGetSuccess({activities}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchActivitiesGetFailure({message: 'failed to fetch activities'}));
  }
}

function* fetchFunctions() {
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }
  yield put(employeesFetchFunctionsRequest());

  try {
    const {data: {roles: functions}} = yield call(axios.request, {
      method: 'GET',
      url: `${backendUrl}/api/roles`,
      params: {
        limit: 101,
        fields: 'title,id,rolemetatype',
        organisation_ids: organisationId,
        role_meta_types: 'role,position',
      },
      withCredentials: true,
    });

    yield put(employeesFetchFunctionsSuccess({functions}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchFunctionsFailure({error}));
  }
}

function* fetchSelectedPersonEvents(payload) {
  yield put(employeesFetchSelectedPersonEventsRequest());
  try {
    const userName = yield select(getSelectedPersonUsername);
    const {data: {personevents: events}} = yield call(axios.request, {
      method: 'GET',
      url: `${backendUrl}/api/personevents`,
      params: {
        user_name: userName,
        include_person: 1,
        fields:
          'title,location,competence_id,startdate,enddate,person(profile_image,fullname,firstname,lastname,user_name)',
      },
      withCredentials: true,
    });

    yield put(employeesFetchSelectedPersonEventsSuccess({data: events}));
  } catch (error) {
    yield put(employeesFetchSelectedPersonEventsFailure({error}));
  }
}

function* fetchEventsApi({organisationId, waitlist}) {
  const {data: {personevents: events}} = yield call(axios.request, {
    method: 'GET',
    url: `${backendUrl}/api/personevents`,
    params: {
      organisation_id: organisationId,
      include_person: 1,
      all: 1,
      fields:
          'title,location,competence_id,startdate,enddate,person,waitlist,confirmed(profile_image,fullname,firstname,lastname,user_name)',
    },
    withCredentials: true,
  });

  return events;
}

function* fetchEvents(action) {

  yield put(employeesFetchEventsRequest());

  try {
    let organisationId = yield select(getOrganisationId);

    if (!organisationId) {
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
      organisationId = yield select(getOrganisationId);
    }

    const events = yield call(fetchEventsApi, {
      organisationId,
    });

    yield put(employeesFetchEventsSuccess({events}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchEventsFailure({error}));
  }
}

function* fetchEventsWaitlist(action) {

  yield put(employeesFetchEventsWaitlistRequest());

  try {
    let organisationId = yield select(getOrganisationId);

    if (!organisationId) {
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
      organisationId = yield select(getOrganisationId);
    }

    const events = yield call(fetchEventsApi, {
      waitlist: true,
      organisationId,
    });

    yield put(employeesFetchEventsWaitlistSuccess({eventsWaitlist: events}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchEventsWaitlistFailure({error}));
  }
}

function* resetPassword({payload: {identifier}}) {
  yield put(employeesResetPasswordRequest);
  try {
    yield put(employeesResetPasswordSuccess());
    // TODO: some sort of feedback with result for the user
    yield call(axios.request, {
      method: 'POST',
      url: `${backendUrl}/api/forgot_password`,
      params: {identification: identifier},
      withCredentials: true,
    });
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.send-new-password-to-user-succes'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.send-new-password-to-user-failure'),
        color: 'red',
      },
    }));
    yield put(employeesResetPasswordFailure({error}));
  }
}

function* editPassword({
  payload: {
    data: {user_name: newUserName, newPassword},
    callback,
  },
}) {
  yield put(employeesEditPasswordRequest());
  try {
    const params = {};

    const {data} = yield select(getSelectedPerson);

    let userNameChanged = false;

    if (data.user_name !== newUserName) {
      /*
       * new user name add it.
       * */
      userNameChanged = true;
      params.user_name = newUserName;
    }
    if (newPassword) {
      params.password = newPassword;
    }

    const query = encodeURI(stringifyUrlParams({...params}, 'person_data'));

    // TODO: some sort of feedback with result for the user
    yield call(axios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${data.user_name}?${query}`,
      withCredentials: true,
    });
    yield put(employeesEditPasswordSuccess());
    yield put(notificationsAdd({
      notification: {
        // Password always changes
        text: userNameChanged
          ? 'Passord og brukernavn er endret'
          : 'Passord er endret',
        color: 'green',
      },
    }));
    yield call(() => callback({employee: {user_name: newUserName}}));
  } catch (error) {
    console.error(error);
    yield put(employeesEditPasswordFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: 'Feil i oppdatering av brukernavn/passord',
        color: 'red',
      },
    }));
  }
}

function* saveVerification({payload: {data}}) {
  if (!data || !data.verifycourse_ids) return;

  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);
    const params = {
      ...data,
      person_id: id,
      json: 1,
      verifycourse_ids: data.verifycourse_ids,
    };
    const url = `${backendUrl}/persons/saveverify/${id}/${orgId}`;

    yield put(employeesSaveVerificationRequest());
    const {data: return_data} = yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield return_data.status
      ? all([
        put(employeesSaveVerificationSuccess()),
        put(employeesFetchSelectedPerson({userName})),
        put(notificationsAdd({
          notification: {
            text: return_data.message,
            color: 'green',
          },
        })),
      ])
      : all([
        put(employeesSaveVerificationFailure({})),
        put(notificationsAdd({
          notification: {
            text: return_data.message,
            color: 'red',
          },
        })),
      ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(employeesSaveVerificationFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* removeRequirements({payload: {data}}) {
  const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
  const orgId = yield select(getOrganisationId);
  const params = {
    ajax: 1,
    'course_ids_no_check[]': data,
  };

  const url = `${backendUrl}/persons/savecompetencerequirements/${id}/${orgId}`;

  try {
    yield put(employeesRemoveRequirementsRequest());

    const {data: return_data} = yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield put(employeesRemoveRequirementsSuccess());
    yield return_data.statuscode === -2
      ? all([
        put(employeesSaveRequirementsSuccess()),
        put(employeesFetchSelectedPerson({userName})),
        put(notificationsAdd({
          notification: {
            text: 'Krav er fjernet',
            color: 'green',
          },
        })),
      ])
      : all([
        put(employeesRemoveRequirementsFailure()),
        put(notificationsAdd({
          notification: {
            text: 'Error',
            color: 'red',
          },
        })),
      ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(employeesRemoveRequirementsFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* saveRequirements({payload: {data}}) {
  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);

    const course_ids = [];

    Object.keys(data).forEach(item => {
      for (let i = 0; i < data[item].length; i += 1) {
        if (data[item][i].id) {
          course_ids.push(data[item][i].id.toString());
        }
      }
    });

    const params = {
      ajax: 1,
      'course_ids[]': course_ids.join(','),
    };

    const url = `${backendUrl}/persons/savecompetencerequirements/${id}/${orgId}`;

    yield put(employeesSaveRequirementsRequest());
    yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield all([
      put(employeesSaveRequirementsSuccess()),
      put(employeesFetchSelectedPerson({userName})),
      put(notificationsAdd({
        notification: {
          text: 'Bruker oppdatert',
          color: 'green',
        },
      })),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(employeesSaveRequirementsFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* saveRole({payload: {data}}) {
  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);

    const {position, roles} = data;
    const params = {
      ...data,
      person_id: id,
      ajax: 1,
      position_id: position,
    };

    if (roles) {
      params.roles_ids = roles.map(({id}) => id);
    }

    const url = `${backendUrl}/persons/saveajax/${id}/${orgId}`;

    yield put(employeesSaveRoleRequest());
    yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield all([
      put(employeesSaveRoleSuccess()),
      put(employeesFetchSelectedPerson({userName})),
      put(notificationsAdd({
        notification: {
          text: 'Bruker oppdatert',
          color: 'green',
        },
      })),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(employeesSaveRoleFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}
export default [
  takeLatest(routerEmployeesListDidMount().type, fetchOrganisation),
  takeLatest(routerEmployeesListDidMount().type, fetchStatistics),
  takeLatest(routerEmployeesListDidMount().type, fetchChecklists),
  takeLatest(routerEmployeesListDidMount().type, fetchExpiringCompetences),
  takeLatest(routerEmployeesListDidMount().type, fetchEvents),
  takeLatest(routerEmployeesListDidMount().type, fetchEmployees),

  takeLatest(EA.employeesFetchTree().type, fetchOrganisationTree),
  takeEvery(EA.employeesFetchTreeProgress().type, fetchOrganisationProgress),

  takeLatest(EMPLOYEES_FETCH_CHECKLISTS, fetchChecklists),

  takeLatest(EMPLOYEES_FETCH_EVENTS, fetchEvents),
  takeLatest(EMPLOYEES_GET, fetchEmployees),
  throttle(700, employeesSearchPhrazeChange().type, searchEmployees),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON, fetchSelectedPerson),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS, fetchSelectedPersonEvents),
  takeLatest(EMPLOYEES_EDIT_VIEW_INIT, fetchSelectedPerson),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON_REPORT, fetchSelectedPersonReport),

  takeEvery(EMPLOYEES_REPORT_VIEW_INIT, fetchSelectedReport),
  takeLatest(EMPLOYEES_ADD_PERSON, addPerson),
  takeLatest(EMPLOYEES_EDIT_SELECTED_PERSON, editSelectedPerson),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS,
    fetchSelectedPersonChecklists,
  ),
  takeLatest(EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES, updateSelectedPersonRoles),
  takeLatest(
    EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT,
    updateSelectedPersonEmployment,
  ),
  takeLatest(
    EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS,
    fetchSelectedPerson,
  ),

  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES,
    fetchSelectedPersonCompetences,
  ),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES,
    fetchSelectedPersonActivites,
  ),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING,
    fetchSelectedPersonExpiring,
  ),
  takeLatest(EMPLOYEE_MOVE, moveEmployee),
  takeLatest(EMPLOYEES_FETCH_STATISTICS, fetchStatistics),
  takeLatest(EMPLOYEES_FETCH_ACTIVITIES, fetchActivities),
  takeLatest(EMPLOYEES_FETCH_EXPIRING_COMPETENCES, fetchExpiringCompetences),
  takeLatest(EMPLOYEES_FETCH_FUNCTIONS, fetchFunctions),
  takeLatest(EMPLOYEES_RESET_PASSWORD, resetPassword),
  takeLatest(EMPLOYEES_EDIT_PASSWORD, editPassword),
  takeLatest(EMPLOYEES_FETCH_EXTRADATA, fetchExtraData),
  takeLatest(EMPLOYEES_FETCH_EXTRADATA, fetchEmployees),
  takeLatest(EMPLOYEES_SAVE_VERIFICATION, saveVerification),
  takeLatest(EMPLOYEES_SAVE_ROLE, saveRole),
  takeLatest(EMPLOYEES_REMOVE_REQUIREMENTS, removeRequirements),
  takeLatest(EMPLOYEES_SAVE_REQUIREMENTS, saveRequirements),
  takeLatest(EMPLOYEES_FETCH_EVENTS_WAITLIST, fetchEventsWaitlist),
];
