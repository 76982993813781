/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import sizeMe from 'react-sizeme';
import {jsx, css} from '@emotion/react';
import {base, wrapper} from '@routes/atlas/components/Map/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {lerp} from '../../../../common/utils/animation.utils';
import {easingFunctions} from '../../../../common/utils/easing-functions';
import Path from '../Path/Path';
import {lineStyle} from '../Path/styles';
import {Item} from '../Hexagon/Hexagon';
import {selectMapCourse} from '@actions/map.actions';
import {useDispatch} from 'react-redux';

const maxScrollGradient = 40;
const scrollIndicatorThreshold = 4;

const animateSpeed = 0.15;
let previousMapData = null;

export const Map = sizeMe({
  monitorWidth: true,
  monitorHeight: true,
})(({
  size: {width, height},
  className,
  track,
  data,
  activeCourseID,
  completedCourses,
  badge,
  allCompleted,
  hide,
  backgroundImage,
  disableInteraction,
  finishedWithFirstMapRuntrough,
  clickPathMode,
  backgrondPosition,
  isMobile,
}) => {
  const dispatch = useDispatch();

  const coursesLength = Object.keys(data).length;

  const [lastCompletedIndex] = useState(() => {
    if (!previousMapData || previousMapData.trackId !== track.id) {
      previousMapData = {
        trackId: track.id,
        data,
      };
      if (completedCourses === 1) {
        return 0;
      }

      return -1;
    }
    const index = [...data].reverse().findIndex(({status}, index) => {
      const reverseIndex = coursesLength - index - 1;

      return (
        status === 'DONE'
        && (!previousMapData[reverseIndex]
          || previousMapData[reverseIndex].status !== 'DONE')
      );
    });

    previousMapData = {
      trackId: track.id,
      data,
    };

    return coursesLength - index - 1;
  }, [track, completedCourses, data]);

  const initialCompletedCount = useMemo(() => {
    if (lastCompletedIndex !== -1) {
      return lastCompletedIndex;
    }

    return completedCourses;
  }, [completedCourses, lastCompletedIndex]);

  const [completedCount, setCompletedCount] = useState(initialCompletedCount);
  const [completedCoursesTarget, setCompletedCoursesTarget] = useState(initialCompletedCount);
  const [animationStarted, setAnimationStarted] = useState(lastCompletedIndex === -1);

  const animationReachedTarget = completedCount >= completedCoursesTarget;

  const clickMode = isMobile ? 'OPEN_COURSEPLAYER' : 'SELECT_ACTIVE_COURSE';

  useEffect(() => {}, [track, data]);

  useEffect(() => {
    if (animationStarted) {
      setCompletedCoursesTarget(completedCourses);

      return undefined;
    }
    let running = true;
    const timeout = setTimeout(() => {
      if (!running) {
        return;
      }
      setCompletedCoursesTarget(completedCourses);
      setAnimationStarted(true);
    }, 1500);

    return () => {
      running = false;
      clearTimeout(timeout);
    };
  }, [animationStarted, completedCourses, coursesLength]);

  const animationProgress = useRef({
    progress:
      Math.min(completedCoursesTarget, coursesLength - 1)
        / (coursesLength - 1) || 0,
  });
  const animatedLineRef = useRef(null);

  useEffect(() => {
    const line = animatedLineRef.current;

    if (!line) {
      return undefined;
    }

    const targetProgress
      = Math.min(completedCoursesTarget, coursesLength - 1)
        / (coursesLength - 1) || 0;
    const currentProgress = animationProgress.current.progress;

    const duration = Math.abs((targetProgress - currentProgress) * 1000 / animateSpeed);

    let playing = true;
    const startTime = Date.now();
    const frame = () => {
      if (!playing) {
        return;
      }

      const now = Date.now();
      const seek = easingFunctions.easeOutQuad(Math.min(1, (now - startTime) / duration));

      const progress = lerp(currentProgress, targetProgress, seek);

      animationProgress.current.progress = progress;

      const {style} = line;

      style.height = `${progress * 100}%`;

      const completedCount = Math.floor(progress * coursesLength);

      setCompletedCount(completedCount);

      if (seek < 1) {
        requestAnimationFrame(frame);
      }
    };

    requestAnimationFrame(frame);

    return () => {
      playing = false;
    };
  }, [completedCount, completedCoursesTarget, coursesLength]);

  const svgPath = track && track.tracks[0].svgpath;
  const fixedDotLayout = track && track.tracks[0].layout !== 'free';

  const ref = useRef(null);
  const element = ref.current;

  const [scrollIndicator, setScrollIndicator] = useState(false);

  const onScroll = useCallback(() => {
    if (!element) {
      return;
    }

    const scrollRemaining = Math.min(
      Math.max(
        element.scrollHeight - element.clientHeight - element.scrollTop,
        0,
      ),
      maxScrollGradient,
    );

    setScrollIndicator(element.scrollTop
        < element.scrollHeight - element.clientHeight - scrollIndicatorThreshold);

    const mask = `linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) ${scrollRemaining}px)`;

    const {style} = element;

    style['-webkit-mask-image'] = mask;
    style['mask-image'] = mask;
  }, [element]);

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  const mapImgWidth = track && track.size && track.size.width || 447;
  const mapImgHeight = track && track.size && track.size.height || 746;

  const mapScale = fixedDotLayout ? width / mapImgWidth : Math.min(width / mapImgWidth, height / mapImgHeight);
  const marginTop = Math.max(0, (height - mapImgHeight * mapScale) / 2);
  const marginLeft = (width - mapImgWidth * mapScale) / 2;

  return (
    <div
      className={className}
      css={css`
        position: relative;
        height: 100%;
        visibility: ${hide ? 'hidden' : 'visible'};
        overflow-x: hidden;
        overflow-y: auto;
      `}
    >
      <div
        className="map-container"
        css={css(
          css`
          position: absolute;
          border-radius: 5px;
          width: ${mapImgWidth * mapScale}px;
          height: ${mapImgHeight * mapScale}px;
          top: ${marginTop}px;
          left: ${marginLeft}px;
          bottom: auto;
          right: auto;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-image: url(${backgroundImage});
          background-position: 0 0;
        `,
          fixedDotLayout && css`
          position: relative;
          left: auto;
          top: auto;
          width: 100%;
          height: auto;
          background-size: cover;
          background-position: ${backgrondPosition || '50% 80%'};
        `,
        )}
      >

        {badge && (
          <div
            role="button"
            tabIndex="0"
            css={css(css`
              right: 20px;
              position: absolute;
              bottom: 20px;
              border-radius: 50px;
              width: 50px;
              height: 50px;
              opacity: ${activeCourseID === 'badge' ? '1' : '0.4'};
              background-color: ${!allCompleted ? 'black' : 'transparent'};
              z-index: 1000;
              cursor: pointer;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url(${allCompleted ? badge.icon : ''});
          `)}
            onClick={() => {
              if(allCompleted) {
                dispatch(selectMapCourse({id: 'badge'}));
              }
            }}
          />
        )}
        {svgPath && (
          <svg
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            `}
            viewBox={`0 0 ${mapImgWidth} ${mapImgHeight}`}
            preserveAspectRatio="none"
          >
            <path
              d={svgPath}
              stroke="#cf152d"
              strokeWidth="15"
              fill="transparent"
            />
          </svg>
        )}
        {!fixedDotLayout
          ? data.map((course, index) =>
            course.position
              ? (
                <Item
                  key={course.id}
                  absolute
                  x={course.position.x * mapScale}
                  y={course.position.y * mapScale}
                  index={index}
                  course={course}
                  activeCourseID={activeCourseID}
                  clickMode={clickMode}
                  completedCourses={completedCount}
                  animateCompleted={
                    lastCompletedIndex === index && !finishedWithFirstMapRuntrough
                  }
                  disableInteraction={disableInteraction}
                />
              )
              : null)
          : (
            <>
              {scrollIndicator && (
                <div
                  css={css`
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  transform: translateX(-50%);
                  color: #fff;
                  opacity: 0.8;
                `}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              )}
              <div
                css={css`
                height: 100%;
                justify-content: center;
                align-items: center;
                overflow-x: hidden;
                overflow-y: auto;
              `}
                ref={ref}
                onScroll={onScroll}
              >
                <div
                  css={css(
                    wrapper,
                    isMobile
                    && css`
                      margin-bottom: 2em;
                    `,
                  )}
                >
                  <div css={base}>
                    {!svgPath && (
                      <div
                        css={css(lineStyle, {
                          marginTop: '2.5em',
                          height: `${(coursesLength - 1) * 5}em`,
                        })}
                      >
                        <div
                          ref={animatedLineRef}
                          css={css({
                            backgroundColor: '#21CE6C',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                          })}
                        />
                      </div>
                    )}
                    {data.map((course, index) => (
                      <Path
                        clickMode={clickMode}
                        disableInteraction={disableInteraction}
                        key={course.id}
                        course={course}
                        index={index}
                        activeCourseID={
                        animationStarted && animationReachedTarget
                        || course.status === 'OPEN'
                          ? activeCourseID
                          : null
                      }
                        coursesLength={coursesLength}
                        completedCourses={completedCount}
                        animateCompleted={
                        lastCompletedIndex === index && !finishedWithFirstMapRuntrough
                      }
                        hidePath={!!svgPath}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
});
