import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees as routes} from '@routes/routes.manifest';
import reports from '@routes/reports.manifest';
import StatisticCard, {StatisticCardLoading} from '@routes/employees/components/statistic-card';
import {i18n} from '@src/i18n';
import {faDotCircle, faEllipsisH, faRoute, faUserTag} from '@fortawesome/pro-light-svg-icons';
import * as T from '@types/load.types';
import {HasMoreCard} from '@routes/employees/components/statistics/statistics-style';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const StatisticCards = ({
  statistics,
  orgId,
  isMobile,
}) => {
  if (statistics.status !== T.LoadStatuses.LOADED) {
    return (
      <div className="employees-page__statistics">
        <StatisticCardLoading />
        <StatisticCardLoading />
        <StatisticCardLoading />
      </div>
    );
  }
  const statCards = [
    {
      id: 'totalt',
      data: statistics.data.progress,
      name: i18n('employees.total'),
    }
  ];

  statistics.data.functions.forEach(item => {
    statCards.push(
      {
        id: item.role_id,
        data: item.progress,
        name: item.title,
      },
    );
  });

  statCards.push(
    {
      id: 'role',
      data: {},
      name: i18n('employees.report-roles'),
      icon: faUserTag
    },
    {
      id: 'competence',
      data: {},
      name: i18n('employees.report-competences'),
      icon: faRoute,
    },
  );

  const [open, setOpen] = useState(false);

  return(
    <>
      {!isMobile && (
        <div className="employees-page__statistics">
          {statCards.map((card, i) => (
            <>
              {i >= 5 && !open && (
                <div
                  className={`employees-page__statistics-card-wrapper items${statCards.length }`}
                >
                <HasMoreCard
                  onClick={() => setOpen(true)}
                >
                  <div>
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    style={{
                      fontSize: 64,
                      color: '#0B5CD7',

                    }}
                  />
                  </div>
                  <div>
                    {i18n('employees.report-view-all', {functionArgs:{x: statCards.length - 4}})}
                  </div>
                </HasMoreCard>
                </div>
              ) || (
                <Link
                  to={getRouteWithParams(routes.reportPreview, {
                    orgId,
                    reportId: card.id,
                  })}
                  className={`employees-page__statistics-card-wrapper items${statCards.length }`}
                >
                  <StatisticCard
                    name={card.name}
                    progress={card.data}
                    firstItem={i===0}
                    clickable
                    icon={card.icon}
                  />
                </Link>
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default StatisticCards;
