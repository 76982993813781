import themedLogo from '@components/themed-logo/themed-logo';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';

export const Wrapper = styled.div`
    background: #FAFBFC;
    border: 1px solid #E1E4E8;
    border-radius: 6px;
    margin-top: 15px;
    padding: 0.4em 1.1em 1.3em 1.1em;
`;

export const  Header = styled.h3`
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
`;
export const  PartipicantsWrapper = styled.div`
    background: #FFFFFF;
    border: 2px solid #2D7FF9;
    border-radius: 4px;
    padding: 1rem;
`;

export const ParticipantsLabel = styled.div`
    color: #757575;
    font-size: 0.9em;
    margin: 10px 0;
`;

export const  Partipicant = styled.div`
     display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
`;
export const StatusDot = styled.div``;

export const dotStyle = css`
    margin-right: 0.35rem;
`;

export const signedUpDotStyle = css`
    ${dotStyle};
    color: #008243;
`;

export const onWaitlistDotStyle = css`
    ${dotStyle};
    color: #EA780E;
`;

export const signOffDotStyle = css`
   ${dotStyle}
    color: #E00000;
`;

export const approveDotStyle = css`
   ${dotStyle}
   color: #008243;
`;

export const grayDotStyle = css`
   ${dotStyle}
   color: #616161;
`;

export const Name = styled.div`
    font-size: 1.05rem;
    em {
      color: #666;
      font-size: 0.8rem;
    }
`;

export const ActionsWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

export const ActionName = styled.div``;

export const Action = styled.div`
    display: flex;
    align-items: center;
    &:hover{
        cursor: pointer;
    }

    &:not(:last-child){
        margin-right: 0.6rem;
    }
`;
