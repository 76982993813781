import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import '@routes/employees/styles/events-list.scss';

import {getCoursePath, getEmployeePreviewPath} from '@utils/routes.utils';

import Moment from 'react-moment';
import moment from 'moment';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {i18nMoment} from '@src/i18n';

const createSubtitleContent = content => ({__html: content});

class EventsList extends Component {
  renderEvent = ({event, isFirst, isLast}) => {
    const {type} = this.props;

    return (
      event.competence_type === 28 && (
        <div className="events-list__item">
          <div
            className="events-list__card"
            style={{padding: 20}}
          >
            <div
              className={`events-list__text ${
                event.waitlist ? 'waiting' : ''
              }`}
            >
              {event.title
                && <div className="events-list__text__title">{event.title}</div>
               || (
                 <div className="events-list__text__title">
                   {event.competence_title}
                 </div>
               )}
              <div
                className="course-card__content--subtitle"
                dangerouslySetInnerHTML={createSubtitleContent(event.short_description)}
              />
              <div className="modal-form__buttons">
                <button
                  className="btn course-sign__button"
                  type="button"
                  onClick={() =>
                    window.location = '/load-config/' + event.competence_id}
                  style={{marginTop: 30}}
                >
                  Gå til kursløp
                </button>
              </div>
            </div>
          </div>
        </div>
      ) || (
        <div
          className={classNames([
            'events-list__card',
            {first: isFirst},
            {last: isLast},
          ])}
        >
          <Link to={getCoursePath(event.competence_id)}>
            <div
              className={`events-list__card__contents ${
                event.waitlist ? 'waiting' : ''
              }`}
            >
              <div className="events-list__date">
                <Moment
                  className="events-list__date__day"
                  interval={0}
                  format="DD"
                >
                  {event.startdate}
                </Moment>

                <div className="events-list__date__month">
                  {i18nMoment(event.startdate).format('MMM')}
                </div>
              </div>

              <div className="events-list__text__wrapper">
                {event.title
                  && <div className="events-list__text__title">{event.title}</div>
                 || (
                   <div className="events-list__text__title">
                     {event.competence_title}
                   </div>
                 )}
                {type !== 'employee' && (
                  <Link to={getEmployeePreviewPath(event.person.user_name)}>
                    <div className="events-list__person-name">
                      {event.person.fullname}
                    </div>
                  </Link>
                )}

                <div className="events-list__text__time_and_location">
                  <div className="events-list__text__description">
                    {moment(event.startdate).format('HH:mm')}
                    -
                    {moment(event.enddate).format('HH:mm')}
                    {event.location.title && <span>,&nbsp;</span>}
                  </div>

                  <div className="events-list__text__description">
                    {event.locationTitle}
                  </div>

                  {event.location && event.location.title && (
                    <div className="events-list__text__location">
                      {event.location.title}
                    </div>
                  )}
                </div>
              </div>

              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  marginLeft: 'auto',
                  fontSize: '0.8em',
                  color: '#424242',
                }}
              />
            </div>
          </Link>
        </div>
      )
    );
  };

  renderEvents = events => {
    let currentUser = null;
    let currentDate = null;
    let cache = [];

    const renderNode = children => children;

    return events.reduce((list, event, index, arr) => {
      const {person} = event;

      const date = moment(event.startdate).format('DD.MM.YYYY');

      let node = null;

      if (
        cache.length
        && (currentUser !== (person && person.user_name) || currentDate !== date)
      ) {
        node = renderNode(cache);
        cache = [];
      }
      cache.push(this.renderEvent({
        event,
        isFirst: index === 0,
        isLast: index + 1 === arr.length,
      }));

      currentUser = person && person.user_name;
      currentDate = date;
      const next = list;

      if (node) {
        next.push(node);
      }
      if (index === events.length - 1) {
        next.push(renderNode(cache));
      }

      return next;
    }, []);
  };

  render() {
    const {events, type} = this.props;

    if (type === 'tracks') {
      return (
        <div
          className="tracks-list"
          style={{width: '100%'}}
        >
          {events.map((event, idx, arr) => (
            <div>
              {this.renderEvent({
                event,
                isFirst: idx === 0,
                isLast: idx + 1 === arr.length,
              })}
            </div>
          ))}
        </div>
      );
    }

    return <div className="events-list">{this.renderEvents(events)}</div>;
  }
}

EventsList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string,
};

EventsList.defaultProps = {type: ''};

export default EventsList;
