import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/modal/modal';
import {coursesLmsRunning} from '@actions/courses.actions';
// import { getIsMobile } from '@selectors/global.selectors';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import {css, jsx} from '@emotion/react';
import {connect} from 'react-redux';
import NanoLearningPlayerContainer from '@routes/nano-learning/containers/nano-learning-player-container';

import './course-player.scss';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import { ActionButton } from '@components/buttons/actionButton';

const CoursePlayer = ({
  cid,
  type,
  onEnd,
  reloading,
  url,
  opened,
  // isMobile,
  coursesLmsRunning,
}) =>
  type === 24 && <NanoLearningPlayerContainer courseId={cid} /> || (
    <Modal
      onBackdropClick={() => {}}
      disableActionButtons 
      disableCloseButton
    >
      <div className="course-player">
        {type === 10 && (
          <div
            className={classNames('lms-dialog', {'lms-dialog--opened': opened})}
          >
            <div className="lms-dialog__content">
              {reloading && <div>{i18n('globals.updating', {ellipsis: true})}</div>
                  || opened && (
                    <>
                      <div>
                        Når du har gjennomført e-kurset
                        {' '}
                        {'—'}
                        {' '}
                        trykk på
                        {' '}
                        <i>gjennomført</i>
                        {' '}
                        for å gå tilbake.
                      </div>
                      <ActionButton
                        css={css`
                        margin: 1.2em auto 0 auto
                        `}
                        variant="round"
                        type="button"
                        onClick={onEnd}
                      >
                        Gjennomført
                      </ActionButton>
                    </>
                  ) || (
                    <>
                      <div>
                        Netteleseren din er satt opp med Pop-up blokkering.
                      </div>
                      <p>Trykk &laquo;Start e-kurs&raquo; for å åpne kurset.</p>
                      {/* <div>
                    For å kunne starte kurset automatisk må du deaktivere pop-up
                    blocker.
                  </div>
                  {(isMobile && (
                    <>
                      <p>
                        <b>iPhone/Safari:</b> Innstilinger &#10132; Safari
                        &#10132; Skru av &laquo; Blokker popupvinduer&raquo;
                      </p>
                      <p>
                        <b>Chrome:</b> Innstilinger &#10132;
                        Innholdsinnstillinger &#10132; Skru av &laquo;Blokker
                        forgrunnsvinduer&raquo;
                      </p>
                    </>
                  )) || (
                    <>
                      <p>
                        <b>Safari:</b> Innstilinger &#10132; Safari &#10132;
                        Skru av &laquo; Blokker popupvinduer&raquo;
                      </p>
                      <p>
                        <b>Chrome:</b> Innstilinger &#10132; Personvern og
                        sikkerhet &#10132; Nettsideinstillinger &#10132; Skru av
                        &laquo;Blokker forgrunnsvinduer&raquo;
                      </p>
                      <p>
                        <b>Edge:</b> Innstillinger &#10132; Nettstedstillatelser
                        &#10132; Skru av &laquo;Blokker forgrunnsvinduer&raquo;
                      </p>
                    </>
                  )} */}
                      <button
                        type="button"
                        onClick={() => {
                          const win = window.open(url, '_blank');

                          coursesLmsRunning({
                            url,
                            opened: !!win,
                          });
                        }}
                        className="btn"
                      >
                        {i18n('course.start')}
                      </button>
                    </>
              )}
            </div>
          </div>
        )}
        {type === 19 && (
          <div className="lms-dialog lms-dialog--opened">
            <div className="lms-dialog__content">
              <div>Klikk her for å signere</div>
              <button
                type="button"
                onClick={onEnd}
                className="btn"
              >
                klar!
              </button>
            </div>
          </div>
        )}
        {type === 12 && (
          <iframe
            title="Course Player"
            src={`${backendUrl}/player/play/${cid}?type=${type}`}
          >
            Nettleseren din støtter ikke iframe
          </iframe>
        )}
      </div>
    </Modal>
  )
  ;

CoursePlayer.propTypes = {
  cid: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  reloading: PropTypes.bool.isRequired,
  onEnd: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  // isMobile: PropTypes.bool.isRequired,
  coursesLmsRunning: PropTypes.func.isRequired,
};

// const mapStateToProps = state => ({
//   isMobile: getIsMobile(state),
// });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {coursesLmsRunning},
    dispatch,
  );

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps,
)(CoursePlayer);
