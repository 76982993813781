import styled from '@emotion/styled';

export const HasMoreCard = styled.div`
  cursor: pointer;
  align-items: center;
  border: 1px solid #E0E0E0;
  margin: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-grow: 1;
  font-size: 0.9em;
`;

