import {useState} from 'react';
import {DateTd, Header} from './style';
import {ArrowCircleLeft, ArrowCircleRight} from 'phosphor-react';
import { i18nMoment } from '@src/i18n';


const Calendar = ({dates, ...props}) => {
  const [date, setDate] = useState(new Date());
  const getDays = (month, year) => {
    const date = new Date(`${year}-${Number.parseInt(month, 0) + 1}-01`);
    const mydays = [];
    const mystart = [];

    const start_date = new Date(date);

    if (start_date.getDay() !== 1) {
      while (start_date.getDay() !== 1) {
        start_date.setDate(start_date.getDate() - 1);
        mystart.push({
          date: start_date.getDate(),
          day: start_date.getDay(),
          month: false,
        });
      }
    }
    let myweeks = mystart.reverse();

    while (date.getMonth() === Number.parseInt(month, 0)) {
      if (date.getDay() === 1) {
        mydays.push(myweeks);
        myweeks = [];
        myweeks.push({
          date: date.getDate(),
          day: date.getDay(),
          month: true,
        });
      }else {
        myweeks.push({
          date: date.getDate(),
          day: date.getDay(),
          month: true,
        });
      }
      date.setDate(date.getDate() + 1);
    }

    if (date.getDay() !== 0) {
      while (date.getDay() !== 1) {
        myweeks.push({
          date: date.getDate(),
          day: date.getDay(),
          month: false,
        });
        date.setDate(date.getDate() + 1);
      }
    }
    mydays.push(myweeks);

    return mydays;
  };

  const days = getDays(date.getMonth(), date.getFullYear());
  const today = new Date();

  return (
    <div {...props}>
      <Header>
        <div
          onClick={() => setDate(i18nMoment(date).subtract(1, 'month')
            .toDate())}
          style={{marginLeft: 6}}
        >
          <ArrowCircleLeft
            size={32}
            weight="fill"
          />
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            flexGrow: 3,
            paddingTop: 6,
            lineHeight: '120%',
            textAlign: 'center',
          }}
        >
          {i18nMoment(date).format('MMMM - YYYY')
            .toLocaleUpperCase()}
        </div>
        <div
          onClick={() => setDate(i18nMoment(date).add(1, 'month')
            .toDate())}
          style={{marginRight: 6}}
        >
          <ArrowCircleRight
            size={32}
            weight="fill"
          />
        </div>
      </Header>
      <table
        style={{
          width: '100%',
          borderSpacing: 8,
        }}
      >
        {days.map((week, i) => (
          <tr style={{paddingBottom: 20}} key={`week${i}`}>
            {week.map(day => (
              <DateTd
                key={`day${day.date}`}
                disabled={!day.month}
                today={day.month && day.date === today.getDate()}
              >
                {day.date}
              </DateTd>
            ))}
          </tr>
        ))}
        <tr />
      </table>
    </div>
  );
};

export default Calendar;
