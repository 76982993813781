import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {css, jsx} from '@emotion/react';
import {withRouter} from 'react-router-dom';

import {
  getProfile,
  getPassedCompetencesGroupedByType,
  getReport,
  getCVs,
} from '@selectors/profile.selectors';

import {routerMyEducationMainViewDidMount} from '@actions/router.actions';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import EmployeeReport from '@routes/employees/components/employee-report';

import '../styles/my-education-container.scss';

import PersonInfo from '../components/person-info';
import CompetencesList from '../components/competences-list';
import SimpleTable from '@routes/employees/components/simple-table/simple-table';
import moment from 'moment';
import {fetchReport} from '@actions/profile.actions';
import {CvsList} from './my-education-cv/list/cvs-list';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ContentLoader from 'react-content-loader';
const _ = require('lodash');
const RoleLoader = ({first, last, ...props}) => (
  <div className={`roles-list__role__loader ${last && ' last'} ${first && 'first'}`}>
    <ContentLoader
      title="Laster inn..."
      height="42"
      width="500"
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect
        x="7"
        y="11"
        rx="4"
        ry="4"
        width="450"
        height="20"
      />
    </ContentLoader>
  </div>
);

class MyEducationCompetenceContainer extends Component {
  constructor(props) {
    super(props);

    this.redirectToProfileView = this.redirectToProfileView.bind(this);
    this.handleEmployeeReportViewInit = this.handleEmployeeReportViewInit.bind(this);
  }

  componentDidMount() {
    const {routerMyEducationMainViewDidMount} = this.props;
    routerMyEducationMainViewDidMount();
  }

  redirectToProfileView() {
    const {history} = this.props;

    history.push(myEducationRoutes.profileView.path);
  }

  handleEmployeeReportViewInit() {
    const {fetchReport} = this.props;

    fetchReport();
  };

  render() {
    const {report, cvs, passedCompetencesGroupedByType, match} = this.props;
    const doneFetching = (report.data && !report.error) && ( (cvs.data && !cvs.isFetching) || cvs.error);

    return (
      <div className="my-education">
        <div className="my-education__section">
          {!doneFetching && (
            <>
              <div
                css={css`
                  background: #d8d8d8;
                  width: 71.3%;
                  height: 26px;
                  margin-bottom: 2.5em;
                `}
              />

              <RoleLoader first />
              <RoleLoader />
              <RoleLoader />
              <RoleLoader />
              <RoleLoader last />
            </>
          )}
          {passedCompetencesGroupedByType.data && (
            <div className="competences-list-wrapper">
              <Breadcrumbs
                style={{
                  marginBottom: '1.3rem',
                  display: doneFetching ? 'block' : 'none',
                }}
                breadcrumbs={[
                  {
                    breadcrumb: myEducationRoutes.cvView.breadcrumb,
                    match,
                  },
                ]}
              />

              <CvsList
                css={css`
                  margin-bottom: 6rem;
                  display: ${doneFetching ? 'block' : 'none'};
                `}
              />
              <EmployeeReport
                report={report}
                onInit={() => this.handleEmployeeReportViewInit()}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

MyEducationCompetenceContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  passedCompetencesGroupedByType: PropTypes.shape({}).isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  routerMyEducationMainViewDidMount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
  report: getReport(state),
  cvs: getCVs(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationMainViewDidMount,
      fetchReport,
    },
    dispatch,
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MyEducationCompetenceContainer);
