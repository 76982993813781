import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {rgb} from 'chalk';
import {darken, lighten} from 'polished';

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #E00000;
    border-radius: 5px;
`;

export const Header = styled.h3`
  background-color: #FFECEC;
  border-bottom: 1px solid #E00000;
  margin: 0;
  color: #E00000;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1em;

`;

export const Content = styled.div`
    width: 100%;

    padding: 1em;
`;
