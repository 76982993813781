import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import {emptyDate} from '@utils/misc.utils';
import {ActionButton} from '@components/buttons/actionButton';
import {css, jsx} from '@emotion/react';


import {Seperator} from '../../cv-styles';
import {ExperienceDescription} from '../../view/cv-view-styles';

import {Wrapper, ExperienceListWrapper, NoExperiencesFound, ExperienceHeader, ExperienceDetails, EditBtnExperience, EditBtnExperienceIcon, Header, FormHelp, LeftPart, RightPart, ActionButtonsWrapper, CancelButton, DeleteButton,  ManageExperienceWrapperHeader, Title, Description, AddNewIcon, AddNewBtn, AddNewBtnContent, StyledForm} from './edit-experience-type-styles';
import {reduxForm} from 'redux-form';
import {i18n} from '@src/i18n';
import {FormattedDate, PointSeparator} from '../../cv-components';
import moment from 'moment';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';

const [viewMode, editMode, addMode] = ['viewMode', 'editMode', 'addMode'];

/* Adding and changing of cv-entities of a specific type(eg education) */
const CVEditExperienceType = ({
  title,
  description,
  experienceListComponent,
  manageExperienceFormElementsComponent,
  addNewBtnTxt,
  formSchema,
  onAlteredData,
  experienceKind,
  initialExperienceData,
}) => {
  const [manageMode, setManageMode] = useState(viewMode);
  const [experienceData, setExperienceData] = useState(initialExperienceData);
  const [experienceUIDtoEdit, setExperienceUIDtoEdit] = useState(null);

  const enterAddMode = () => {
    setManageMode(addMode);
  };

  const enterEditMode = experienceUID => {
    setManageMode(editMode);
    setExperienceUIDtoEdit(experienceUID);
  };

  const getExperienceByUID = UID => {
    const experience = experienceData.find(elem => elem.UID === UID);

    return experience;
  };


  const getIndexOfExperienceByUID = UID =>
    experienceData.findIndex(elem => elem.UID === UID);

  const handleDate = date => {
    const isYearOnly = /^\d{4}$/.test(date);

    if (isYearOnly) {
      const d = moment(`${date}-01-01`).format('YYYY-MM-DD');

      return  d;
    }

    // is full date, of format YYY-MM-DD
    if(date instanceof Date){
      return moment(date).format("YYYY-MM-DD");
    }
    return date;
  };

  const onManageExperienceSubmit = formData => {
    let copyOfExperienceData;

    const addDatesToObj = (form, obj) => {
      const formData = {...form};

      if(obj.experience_type === 'education') {
        delete formData.startdate;
        delete formData.enddate;
      }
      const objectWithDate = {...obj};

      objectWithDate.startdate = formData.startdate ? handleDate(formData.startdate) : '';
      objectWithDate.enddate = formData.enddate ? handleDate(formData.enddate) : '';

      if (formData.startyear) {
        objectWithDate.startdate = handleDate(formData.startyear);
      } 
      if(formData.endyear) {
        objectWithDate.enddate = handleDate(formData.endyear);
      }

      return objectWithDate;
    };

    if (manageMode === editMode) {
      const experienceToEdit = getExperienceByUID(experienceUIDtoEdit);

      if (experienceToEdit) {
        copyOfExperienceData = experienceData;

        let editedExperience = {
          ...experienceToEdit,
          ...formData,
        };

        editedExperience = addDatesToObj(formData, editedExperience);
        copyOfExperienceData[
          getIndexOfExperienceByUID(experienceUIDtoEdit)
        ] = editedExperience;
      }
    } else if (manageMode === addMode) {
      const defaultData = {
        contact_person: '',
        experience_id: '',
        experience_type: experienceKind,
      };

      copyOfExperienceData = [...experienceData];
      let newEntity = {
        ...defaultData,
        ...formData,
      };

      newEntity = addDatesToObj(formData, newEntity);
      newEntity.UID = Date.now();

      copyOfExperienceData.push(newEntity);
    }
    setExperienceData(copyOfExperienceData);
    onAlteredData(experienceKind, copyOfExperienceData);
    setManageMode(viewMode);
    setExperienceUIDtoEdit(null);
  };

  const onManageExperienceDelete = () => {
    const indexToDelete = experienceData.findIndex(e => e.UID === experienceUIDtoEdit);

    if (indexToDelete >= 0) {
      const copyOfExperienceData = experienceData;

      copyOfExperienceData.splice(indexToDelete, 1);
      setExperienceData(copyOfExperienceData);
      onAlteredData(experienceKind, copyOfExperienceData);
      setManageMode(viewMode);
      setExperienceUIDtoEdit(null);
    }
  };

  const onManageExperienceCancel = () => {
    setManageMode(viewMode);
    setExperienceUIDtoEdit(null);
  };

  const experiences = experienceData.filter(e => e.UID !== experienceUIDtoEdit);

  const experience = getExperienceByUID(experienceUIDtoEdit);

  return (
    <Wrapper>
      <Header>{title}</Header>
      {description && (
        <>
          <Seperator />
          <Description>{description}</Description>
        </>
      )}
      <Seperator />
      {manageMode === viewMode && (
        <AddNewBtn onClick={enterAddMode}>
          <AddNewIcon />
          <AddNewBtnContent>{addNewBtnTxt}</AddNewBtnContent>
        </AddNewBtn>
      )}

      {manageMode === addMode && (
        <ManageExperience
          mode={addMode}
          title={addNewBtnTxt}
          formId={'add-cv-experience ' + experienceKind}
          onSubmit={onManageExperienceSubmit}
          formElements={manageExperienceFormElementsComponent}
          onCancel={onManageExperienceCancel}
        />
      )}
      {manageMode === editMode && (
        <ManageExperience
          mode={editMode}
          title={`${i18n('globals.edit')} ${title.toLowerCase()}`}
          onSubmit={onManageExperienceSubmit}
          formId={'edit-cv-experience ' + experienceKind}
          onDelete={onManageExperienceDelete}
          formElements={manageExperienceFormElementsComponent}
          initialValues={{
            ...experience,
            startdate: experience.startdate ? new Date(experience.startdate) : '',
            enddate: experience.enddate ? new Date(experience.enddate) : '',
          }}
          onCancel={onManageExperienceCancel}
        />
      )}


      {Array.isArray(experiences) 
  && experiences.map(e => (
    <ExperienceListWrapper key={`${e.UID}`}>
      <LeftPart css={css`flex-direction: column; width: 100%;`}>
        <Experience
          experience={e}
          experienceKind={experienceKind}
        />
      </LeftPart>

      <RightPart>
        <EditBtnExperience onClick={() => enterEditMode(e.UID)}>
          <EditBtnExperienceIcon>+</EditBtnExperienceIcon>
          {i18n('globals.change')}
        </EditBtnExperience>

      </RightPart>
    </ExperienceListWrapper>
  ))}

      {manageMode === 'view' && experiences.length === 0
    && (
      <NoExperiencesFound>
        {i18n(`cv.no-items-of-kind.${experienceKind}`)}
      </NoExperiencesFound>
    )}
      <Seperator />
    </Wrapper>
  );
};

export const Experience = ({experience, experienceKind}) => (
  <>
    {experienceKind === 'language' && (
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ExperienceHeader>{experience.name}</ExperienceHeader>
        <PointSeparator />
        <ExperienceDetails css={css`margin-left: 0;`}>{experience.designation}</ExperienceDetails>
      </div>
    )}
    {experienceKind !== 'language' && (
      <>
        <div
          css={css`
            display: flex;
          `}
        >
          <ExperienceHeader>{experience.designation}</ExperienceHeader>
          <ExperienceDetails>
            <FormattedDate
              fromDateStr={experience.startdate}
              toDateStr={experience.enddate}
              yearOnly={['education'].includes(experienceKind)}
            />
          </ExperienceDetails>
        </div>
        <ExperienceDescription>{experience.name}</ExperienceDescription>
      </>
    )}
  </>
);

// insert the formId prop passed to ManageExperience to the redux-form
const mapStateToProps = (state, ownProps) => ({form: ownProps.formId});

const ManageExperience =  compose(connect(mapStateToProps),reduxForm({
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
}))
(({
  title,
  mode,
  formElements,
  onCancel,
  handleSubmit,
  experienceKind,
  onDelete,
}) => (
  <form
    autofill="off"
    form={'cv-edit-' + experienceKind}
    autoComplete="off"
    onSubmit={handleSubmit}
  >
    <ManageExperienceWrapperHeader>{title}</ManageExperienceWrapperHeader>
    {React.createElement(formElements, {})}
    <ActionButtonsWrapper>
        
      <LeftPart css={css`width:100%;`}>

        <CancelButton
          type="button"
          onClick={onCancel}
          css={css`margin-right: 1em;`}
        >
          {i18n('globals.cancel')}
        </CancelButton>

        <ActionButton
          type="submit"
          css={css`margin-top: 0;`}
        >
          {mode === editMode ? 'Endre' : 'Legg til'}
        </ActionButton>
      </LeftPart>
      {mode === editMode && (
        <RightPart>
          <DeleteButton
            type="button"
            action
            small
            onClick={onDelete}
          >
            {i18n('cv.remove-experience')}
          </DeleteButton>
        </RightPart>
      )}
    </ActionButtonsWrapper>
  </form>
));

ManageExperience.propTypes = {
  title: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([editMode, addMode]).isRequired,
  formElements: PropTypes.element.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  initialFormData: PropTypes.shape({}),
};

ManageExperience.defaultProps = {
  formSchema: null,
  initialFormData: {},
};

export default CVEditExperienceType;

CVEditExperienceType.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  addNewBtnTxt: PropTypes.string.isRequired,
  experienceListComponent: PropTypes.element.isRequired,
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manageExperienceFormElementsComponent: PropTypes.element.isRequired,
  onAlteredData: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  experienceKind: PropTypes.string.isRequired,
};

CVEditExperienceType.defaultProps = {formSchema: null};
