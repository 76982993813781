import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import '../styles/profile-base-information.scss';
import Progress from '@components/progress/progress';
// import ProfileInformation from '@routes/my-education/components/profile-base-information-loading';
import EventsList from '@routes/employees/components/events-list';

import {
  getProfile,
  getRoles,
  getExpiring,
  getEvents,
  getSection,
  getTab,
  getPassedCompetencesGroupedByType,

  getMissingCompetences,
  getMessageUnreadCount,
} from '@selectors/profile.selectors';

import {
  switchTab,
  switchSection,
  profileFetchPersonRequirements,
} from '@actions/profile.actions';


import {
  routerMyEducationMainViewDidMount,
  routerMyEducationMessagesMainViewDidMount,
} from '@actions/router.actions';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import PersonRoleInfo from './person-role-info';


import ExpiringList from '@components/expiring-competences';
import Calendar from '@components/calendar/calendar';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import {i18n} from '@src/i18n';
import {getIsMobile} from '@selectors/global.selectors';

class ProfileBaseInformation extends Component {
  state = {isRolesContainerExpanded: false};

  handleTabSelect = tab => {
    // check keys if you want
    const {
      switchTab,
      requiredCompetences,
      profileFetchPersonRequirements,
    } = this.props;

    switchTab(tab);
    if (tab === 'activites' && requiredCompetences.keys.length === 0) {
      profileFetchPersonRequirements();
    }
  };

  setSection = section => {
    // check keys if you want
    const {
      switchSection,
      messages,
      routerMyEducationMainViewDidMount,
      routerMyEducationMessagesMainViewDidMount,
    } = this.props;

    switchSection(section);
    if (section === 'messages' && messages.data.length === 0) {
      routerMyEducationMessagesMainViewDidMount('messages');
    }else if (section === 'cv' && messages.data.length === 0) {
      routerMyEducationMainViewDidMount();
    }
  };

  createRoleSections(rolesData) {
    return rolesData
      ? rolesData.map(position => (
        <div
          className="section cell small-12 medium-6"
          key={position.id}
        >
          <div className="profile-base-info small">
            <Link
              key={position.id}
              to={getRouteWithParams(myEducationRoutes.roleView.path, {roleId: position.id})}
            >
              <div
                className="profile-base-info__position"
                key={position.id}
              >
                <div className="content">
                  <div className="profile-base-info__position-title">
                    {position.title}
                  </div>
                </div>

                <button
                  className="oversize-start"
                >
                  {position.passed_count === 0 ? i18n('role.start')  : i18n('role.continue')}
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <div className="bottom-task-bar">
                {position.missing_count > 0 && (
                  <div className="progress">
                    <div className="profile-base-info__competencelevel">
                      {position.passed_count !== 0 && (
                        <Progress
                          progress={Math.round(position.passed_count
                            / (position.passed_count
                              + position.missing_count)
                            * 100)}
                        />
                      ) || <Progress progress="0" />}
                    </div>
                  </div>
                ) || (
                  <div>
                    {i18n('globals.done')}
&nbsp;
                    <FontAwesomeIcon icon="thumbs-up" />
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
      ))
      : null;
  }

  render() {
    const {
      profile: {data: profileData},
      isFetching,
      summary,
      events,
      isMobile,
      expiring,
    } = this.props;
    const {isRolesContainerExpanded} = this.state;

    let hiddenSubRoles = [];
    let visibleSubRoles = [];
    const eventDates = [];
    const hasEvents = Boolean(expiring && expiring.data && expiring.data.length !== 0) || Boolean(expiring && expiring.data && expiring.data.length !== 0);

    if (summary.data && summary.data.requirement.length !== 0) {
      const sortedSubRoles = summary.data.requirement.slice(1).sort((a, b) => {
        if (a.missing_count === b.missing_count) {
          return a.title > b.title ? 1 : -1;
        }

        return b.missing_count - a.missing_count;
      });

      visibleSubRoles = sortedSubRoles.slice(0, 2);
      hiddenSubRoles = sortedSubRoles.slice(2);
    }

    // if (!isFetching && profileData) {
    return (
      <div>
        <div className="my-education__section__competences">
          {hasEvents && (
            <div className="profile-base-info__positions">
              <div className="grid-x heading">
                {!isMobile && (
                  <div
                    className="cell small-12 medium-4"
                    style={{paddingRight: 40}}
                  >
                    <Calendar dates={eventDates}/>
                  </div>
                )}
                <div className="cell small-12 medium-8">
                  {expiring && expiring.data && expiring.data.length !== 0 && (
                    <>
                      <h2 style={{marginTop:0}}>{i18n('mypage.expires')}</h2>
                      <ExpiringList
                        expiring={expiring.data}
                        isPerson
                      />
                    </>
                  )}
                  {events.data && events.data.length !== 0 && (
                    <>
                      <h2>
                        {i18n('mypage.my-course-enrollments')}
                      </h2>
                      <EventsList
                        type="employee"
                        events={events.data}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {events.tracks && events.tracks.length !== 0 && (
            <div className="profile-base-info__positions">
              <div className="grid-x heading">
                <div className="cell small-12">
                  <h2>Mine kursløp</h2>
                </div>
                <EventsList
                  type="tracks"
                  events={events.tracks}
                />
              </div>
            </div>
          )}


          <div className="profile-base-info__positions">
            <div style={{marginTop: 20}}>
              <div className="grid-x">
                {summary.isFetching && (
                  <div className="profile-base-info__loaders profile-base-info__loaders--competences">
                    <div className="profile-base-info">
                      <div className="profile-base-info__loaders__title" />
                      <div className="profile-base-info__loaders__txt" />
                      <div
                        style={{width:'70%'}}
                        className="profile-base-info__loaders__txt"
                      />
                      <div
                        style={{width:'75%'}}
                        className="profile-base-info__loaders__txt"
                      />
                    </div>
                    <div className="profile-base-info profile-base-info--small">
                      <div className="profile-base-info__loaders__title" />
                      <div
                        style={{width:'60%'}}
                        className="profile-base-info__loaders__txt"
                      />
                      <div
                        style={{width:'70%'}}
                        className="profile-base-info__loaders__txt"
                      />
                      <div
                        style={{width:'20%'}}
                        className="profile-base-info__loaders__txt"
                      />
                    </div>
                    <div className="profile-base-info profile-base-info--small">
                      <div className="profile-base-info__loaders__title" />
                      <div className="profile-base-info__loaders__txt" />
                      <div className="profile-base-info__loaders__txt" />
                    </div>
                  </div>
                ) || (
                  <>
                    <div
                      style={{
                        padding: 30,
                        marginBottom: 40,
                      }}
                    >
                      <h2>{i18n('role.learning-progression')}</h2>
                      <p style={{fontSize: '0.9em'}}>
                        Læringsløpet du gjennomfører består av flere aktiviteter. Progresjonsindikatoren til høyre viser hvor langt du har kommet i gjennomføringen for hele kursløpet. Progresjon vist på kursnivå viser gjennomføringsgrad på spesifikt kurs.
                      </p>
                      <Progress
                        progress={55}
                        fullWidth
                      />
                    </div>
                    {summary.data
                            && summary.data.requirement.length !== 0 && (
                              <PersonRoleInfo
                                role={summary.data.requirement[0]}
                                files={summary.data.requirement[0].files}
                                description={
                                  summary.data.requirement[0].description
                                }
                              />
                    )}

                  </>
                )}
              </div>

              <div className="grid-x">
                {this.createRoleSections(visibleSubRoles)}
                {isRolesContainerExpanded
                      && this.createRoleSections(hiddenSubRoles)}
              </div>

              {hiddenSubRoles.length !== 0 && (
                <div className="info__chip">
                  <button
                    type="button"
                    className={classnames('chip', 'show-more', {'chip--active': isRolesContainerExpanded})}
                    onClick={() =>
                      this.setState(({isRolesContainerExpanded}) => ({isRolesContainerExpanded: !isRolesContainerExpanded}))}
                  >
                    ...
                  </button>
                </div>
              )}
            </div>
          </div>

        </div>

      </div>
    );
    // }
    // return <div>{isFetching ? <ProfileInformation /> : 'Feil!'}</div>;
  }
}

ProfileBaseInformation.propTypes = {
  profile: PropTypes.shape({
    data: PropTypes.shape({}),
    error: PropTypes.shape({}),
  }).isRequired,
  summary: PropTypes.shape({
    data: PropTypes.shape({}),
    isFetching: PropTypes.shape({}),
    error: PropTypes.shape({}),
  }).isRequired,
  switchTab: PropTypes.func.isRequired,
  switchSection: PropTypes.func.isRequired,
  roles: PropTypes.shape({}).isRequired,
  events: PropTypes.shape({}).isRequired,
  expiring: PropTypes.shape({}).isRequired,
  tabSelected: PropTypes.string.isRequired,
  selectionSelected: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  messages: PropTypes.shape({}).isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  profileFetchPersonRequirements: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  summary: state.profile.summary,
  roles: getRoles(state),
  messages: state.messages.all,
  isMobile: getIsMobile(state),
  events: getEvents(state),
  expiring: getExpiring(state),
  passedFetching: state.profile.passed_full.isFetching,
  tabSelected: getTab(state),
  selectionSelected: getSection(state),
  isFetching: state.profile.isFetching,
  isSubFetching: state.profile.competences.isSubFetching,
  requiredCompetences: getMissingCompetences(state),
  unreadCount: getMessageUnreadCount(state),
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTab,
      switchSection,
      profileFetchPersonRequirements,
      routerMyEducationMessagesMainViewDidMount,
      routerMyEducationMainViewDidMount,
    },
    dispatch,
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProfileBaseInformation);
