import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {routerMyEducationRoleViewDidMount} from '@actions/router.actions';

import '../styles/my-education-role-container.scss';
import Progress from '@components/progress/progress';
import {withRouter} from 'react-router';
import classnames from 'classnames';

import CompetenceCourse from '@components/competences/competence-course';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceNano from '@components/competences/competence-nano';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';
import CompetenceVerification from '@components/competences/competence-verification';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import {i18n} from '@src/i18n';
import {ProgressCircle} from '@snapper/dashboard';
import {getConfigObject} from '@selectors/config.selectors';
import {getImageUrl} from '@utils/misc.utils';
/*
const createSubtitleContent = content => ({
  __html: content,
});
*/

class MyEducationRoleContainer extends Component {
  state = {
    isPassedContainerExpanded: false,
    tab: 'mandatory',
    isRequiredContainerExpanded: false,
  };

  componentDidMount() {
    const {
      routerMyEducationRoleViewDidMount,
      match: {params: {roleId}},
    } = this.props;

    routerMyEducationRoleViewDidMount(roleId);
  }

  getCompetenceComponent(type) {
    switch (type) {
    case 'digital_signature':
      return CompetenceSignature;
    case 'nano':
      return CompetenceNano;
    case 'ecourse':
      return CompetenceEcourse;
    case 'complex':
      return CompetenceComplex;
    case 'equivalents':
      return CompetenceEquivalents;
    case 'verification':
      return CompetenceVerification;
    case 'checklist':
      return CompetenceChecklist;
    case 'checklist_item':
      return CompetenceChecklistItem
    default:
      return CompetenceCourse;
    }
  }

  render() {
    const {isSubFetching, role, configObject, breadcrumbs = []} = this.props;
    const {tab} = this.state;
    const defaultImg = getImageUrl(configObject.getProperty('params.default-img'));
    const {
      isPassedContainerExpanded,
      isRequiredContainerExpanded,
    } = this.state;

    let myPassedCompetences = [];

    let showPassed = [];
    const myRole = role.data;
    let showComps = [];
    let showOblComps = [];
    let required_length = 0;
    let required_show_length = 0;
    let required_passed_length = 0;
    let progress = 0;

    if (role.data) {
      myPassedCompetences = role.data.requirements.filter(c => c.passed === 100);

      required_length = role.data.requirements.filter(c => c.requirement_type === 'Mandatory').length;
      required_passed_length = role.data.requirements.filter(c => c.requirement_type === 'Mandatory' && c.passed === 100).length;

      if (required_passed_length !== 0) {
        progress = Math.round(required_passed_length / required_length * 100);
      }

      if (myRole.id === -2) {
        showComps = role.data.requirements.filter(c => c.passed !== 100);
      } else {
        showComps = role.data.requirements.filter(c => c.requirement_type === 'Mandatory');
        showOblComps = role.data.requirements.filter(c => c.requirement_type === 'Optional' && c.passed !== 100);
      }

      showPassed = myPassedCompetences;
      if (!isPassedContainerExpanded) {
        showPassed = [...myPassedCompetences].splice(0, 3);
      }

      required_show_length = showComps.length;
      if (!isRequiredContainerExpanded && showComps.length > 5) {
        showComps = [...showComps].splice(0, 5);
      }

      showPassed.map(function(course) {
        return true;
      });
    }

    if (role.isFetching || !role.data) {
      return (
        <div className="my-education">
          <div className="loading">
            <div className="my-education__section">
              <div className="my-education__section__role-wrapperrole-wrapper">
                <div className="my-education__section__role-header">
                  <div className="loading__title" />
                  <div className="loading__text" />
                </div>
              </div>
            </div>
            <div className="loading__content">
              <h3>...</h3>
              <div className="grid-x">
                <div className="cell small-5 loading__image" />
                <div className="cell small-4 loading__checklist">
                  <div className="loading__title" />
                  <div className="loading__text" />
                  <div className="loading__text loading__text--short" />
                  <div className="loading__text loading__text--short loading__text--small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="my-education">
          <div className="my-education__section">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div className="my-education__section__role-wrapper">
              <div className="my-education__section__role-header">
                <div className="grid-x">
                  <div className="cell small-12 medium-8">
                    <h1>{i18n('role.learning-progression')}</h1>
                    <p>
                      Læringsløpet du gjennomfører består av flere aktiviteter. Progresjonsindikatoren til høyre viser hvor langt du har kommet i gjennomføringen for hele kursløpet. Progresjon vist på kursnivå viser gjennomføringsgrad på spesifikt kurs.
                    </p>
                  </div>
                  <div className="cell small-12 medium-4">
                    <ProgressCircle
                      delay={1}
                      size={150}
                      value={(progress / 100)}
                      className="circle"
                      strokeWidth={12}
                      primaryColor="black"
                    >
                      {progress}
                      %
                    </ProgressCircle>
                  </div>
                </div>
              </div>

              <ul className="my-education__section__ul">
                <li className={(tab === 'mandatory' ? 'active' : '')}
                  onClick={() => this.setState({tab: 'mandatory'})}
                >
                  {i18n('role.mandatory')}
                </li>
                <li className={(tab === 'free' ? 'active' : '')}
                    onClick={() => this.setState({tab: 'free'})}
                >
                  {i18n('role.free')}
                </li>
              </ul>

              {tab === 'mandatory' && showComps && showComps.length !== 0 && (
                <div className="my-education__section__role-required">
                  <div className="grid-x header">
                    <div className="medium-8">
                      <h2>
                        {i18n('role.tasks-related-to')}
                        {' '}
                        {myRole.title}
                      </h2>
                    </div>
                    <div className="medium-4">
                      {myRole.id !== -2 && (
                        <>
                          <div
                            style={{
                              marginBottom:6,
                              marginTop:10,
                            }}
                          >
                            {required_passed_length}
                            /
                            {required_length}
                            {' '}
                            {i18n('role.tasks', {lowerCase: true})}
                          </div>
                          {myRole.id !== -2 && (
                            <Progress
                              barOnly
                              progress={progress}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="role-content">
                    {showComps.map(course => {
                      const CourseComponent = this.getCompetenceComponent(course.competence.competence_type
                        .competence_type);

                      return (
                        <div key={course.id}>
                          <div
                            className="my-education__section__role-wrapper__card"
                            key={course.id}
                          >
                            <div
                              key={`competence-list-sub-${course.competence_id}`}
                              className="grid-x"
                            >
                              <CourseComponent
                                defaultImg={defaultImg}
                                type="ecourse"
                                size="big"
                                isFetching={isSubFetching}
                                course={course.competence}
                                isPassed={course.passed === 100}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {required_show_length > 5 && (
                    <div className="info__chip">
                      {!isRequiredContainerExpanded
                        && (
                          <div>
                            {i18n('role.showing-of', {
                              functionArgs: {
                                x: 5,
                                y: required_show_length,
                              },
                            })}
                          </div>
                        )}
                      <button
                        type="button"
                        className={classnames('chip', 'show-more', {'chip--active': isRequiredContainerExpanded})}
                        onClick={() =>
                          this.setState(({isRequiredContainerExpanded}) => ({isRequiredContainerExpanded: !isRequiredContainerExpanded}))}
                      >
                        ...
                      </button>
                    </div>
                  )}
                </div>
              )}

              {tab === 'free' && (
                <div className="my-education__section__role-required">
                  <div className="grid-x header">
                    <div className="medium-8">
                      <h2>
                        {i18n('role.tasks-optional-to')}
                        {' '}
                        {myRole.title}
                      </h2>
                    </div>
                    <div className="medium-4">
                    </div>
                  </div>
                  <div className="role-content">
                    {showOblComps.map(course => {
                      const CourseComponent = this.getCompetenceComponent(course.competence.competence_type
                        .competence_type);

                      return (
                        <div key={course.id}>
                          <div
                            className="my-education__section__role-wrapper__card"
                            key={course.id}
                          >
                            <div
                              key={`competence-list-sub-${course.competence_id}`}
                              className="grid-x"
                            >
                              <CourseComponent
                                defaultImg={defaultImg}
                                type="ecourse"
                                size="big"
                                isFetching={isSubFetching}
                                course={course.competence}
                                isPassed={course.passed === 100}
                                roleId={myRole.id}
                                root="show-role"
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MyEducationRoleContainer.propTypes = {
  role: PropTypes.shape({}).isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  routerMyEducationRoleViewDidMount: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({params: PropTypes.shape({cid: PropTypes.shape.isRequired}).isRequired}).isRequired,
};

const mapStateToProps = state => ({
  isSubFetching: state.profile.competences.isSubFetching,
  configObject: getConfigObject(state),
  role: state.profile.show_role,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {routerMyEducationRoleViewDidMount},
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyEducationRoleContainer));
