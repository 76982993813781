import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import '../styles/employee-report.scss';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';

import ContentLoader from 'react-content-loader';
const RoleLoader = props => (
  <div className="roles-list__role__loader">
    <ContentLoader
      title="Laster inn..."
      height="42"
      width="500"
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect
        x="7"
        y="11"
        rx="4"
        ry="4"
        width="450"
        height="20"
      />
    </ContentLoader>
  </div>
);

class EmployeeReport extends Component {
  componentDidMount() {
    const {onInit} = this.props;

    if (onInit) {
      onInit();
    }
  }

  render() {
    const {report} = this.props;

    return (
      <div className="employee-report">
        <div className="employee-report__card">
          {report && report.data && (
            <>
              <h2 className="employee-report__title">{report.data.title}</h2>
              <table
                className="employee-report__table"
                width="100%"
              >
                <tr>
                  {report.data.headers.map(h =>
                    <th>{h[0]}</th>)}
                </tr>
                {report.data.competences.map(c => (
                  <tr>
                    <td>{c.date}</td>
                    <td>{c.competence_title}</td>
                    <td>{c.competence.competence_type}</td>
                    <td>{c.coursevenue}</td>
                    <td width={100}>{c.duration_string}</td>
                    <td width={100}>{c.valid_until}</td>
                    <td>
                      {c.certificate_url && (
                        <>
                          <a
                            href={`${backendUrl}${c.certificate_url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Kurs bevis
                          </a>
                          <br />
                        </>
                      )}

                      {c.competence.files && (
                        <div>
                          {c.competence.files.map(f => (
                            <div>
                              <a
                                href={`${backendUrl}/files/image/${
                                  f.file_id
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {f.title}
                              </a>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )}
        </div>
      </div>
    );
  }
}

EmployeeReport.propTypes = {
  onInit: PropTypes.func,
  history: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({}).isRequired,
};

EmployeeReport.defaultProps = {onInit: null};

export default compose(withRouter)(EmployeeReport);
