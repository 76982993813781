import themedLogo from '@components/themed-logo/themed-logo';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';

export const Wrapper = styled.div`
    position: relative;
`;

export const DropdownItemsWrapper = styled.div`
    position: absolute;
    background: #FFFFFF;
    width: auto;

    border: 1px solid #E0E0E0;
    z-index: 2;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    display: ${({isOpen}) => isOpen ? 'block' : 'none'};

`;
export const DropdownItem  = styled.div`
    color: #424242;
    padding: 0.5em 2em;
    font-size: 1em;

    &:hover, &.focus-visible, :focus:not(:hover){
        background:  #ECF2FC;
        cursor: pointer;
        color: #0B5CD7;

        ${({disabled}) => disabled
            && css`
                background: initial;
                color: #9E9E9E;
                cursor: default;                
            ` 
        }
    }

    ${({disabled}) => disabled
        && css`
            color: #9E9E9E;
        ` 
    }
`;
export const DropdownItemsLabel  = styled.div`
    color: #9E9E9E;
    font-size: 0.8em;
    padding-top: 0.95em;
    padding-bottom: 0.45em;
    padding-left: 2em;
    &:hover{
        cursor: default;
    }
`;

export const ToggleDropdownButton = styled.button`
  ${({theme}) =>
  ({
    black: css`
        color: #ffffff;
        background: #0a0a0a;
      `,
    grayTransparent: css`
        color: #757575;
        background: transparent;
      `,
  }[theme])}

  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.7em;
  padding: 0.75em;

  &:focus {
    outline: none;
  }

  &:hover {
    ${({theme}) =>
    ({
      black: css`
          background: ${lighten(0.05, '#0A0A0A')};
        `,
      grayTransparent: css``,
    }[theme])}

    cursor: pointer;
  }

  &.focus-visible {
    box-shadow: 0 0 0 3px #537fc3;
  }

  ${({hasText}) =>
      hasText
    && css`
      padding: 1em;
    `}

  ${({isNextToActionButton}) =>
      isNextToActionButton
    && css`
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    `}
`;

export const ActionButton = styled.button`
    padding: 0.7em 2em;
    background: #424242;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    color: #FFFFFF;
    text-align: left;
    font-weight: 500;
    font-size: 1em;

    flex-grow: 1;

    &:focus{
        outline: none;
    }

    &:hover{
        background: ${lighten(0.05, '#424242')};
        cursor: pointer;
    }

    &.focus-visible{
        box-shadow: 0 0 0 3px #537fc3;
    }

`;

export const ButtonsWrapper = styled.div`
    display: flex;
`;