import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

import {PersonShape} from '@types/person';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import Progress from '@components/progress/progress';

import PersonAvatar from '@components/person-avatar/person-avatar';

import {getIsMobile} from '@selectors/global.selectors';

import '@routes/employees/styles/emplyee-card.scss';
import {getMainOrganisations} from '../../../../store/selectors/profile.selectors';

import {
  Wrapper,
  Heading,
  SubHeading,
  LeftPart,
  RightPart,
  PersonInfoWrapper,
  Personalia,
  Text,
  ContentWrapper,
} from './style';
import {ActionButton} from '@components/buttons/actionButton';
import {IconButton} from '@components/buttons/iconButton';

import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {i18n} from '@src/i18n/index';

class EmployeeCard extends Component {
  state = {open: false};

  handleAvatarClick = () => {
    const {employee, onAvatarClick} = this.props;

    onAvatarClick(employee);
  };

  handleEditClick = () => {
    const {employee, onEditClick} = this.props;

    onEditClick(employee);
  };

  handleMessageClick = () => {
    const {employee, onMessageClick} = this.props;

    onMessageClick(employee);
  };

  render() {
    const {
      onProfileClick,
      employee: {fullname, positions, mobile, phone, email, progress},
      employee,
      isExpanded,
      mainOrganisation,
      page,
      taskList: TaskList,
      onEditEmploymentClick,
      listView,
      wrapperStyle,
      onCloseButtonClick,
      isFetching,
    } = this.props;

    const {isMobile} = this.props;
    const {open} = this.state;

    const cardProps
      = !isExpanded && {
        role: 'button',
        onClick: this.handleAvatarClick,
        onKeyPress: this.handleAvatarClick,
        tabIndex: 0,
      }
      || {};

    const position = mainOrganisation && positions?.find(p => p.organisation_id === mainOrganisation.id)?.title;

    if (isExpanded && isMobile) {
      return (
        <div
          className="employee-card employee-card--mobile"
          {...cardProps}
        >
          <div className="employee-card__top-section">
            <div className="employee-card__avatar-wrapper">
              <PersonAvatar
                person={employee}
                notificationCount={employee.pending_checklists}
                className="employee-card__avatar"
              />
            </div>
            <div className="employee-card__name-wrapper">
              <div className="employee-card__name">{fullname}</div>
              <div className="employee-card__progress-wrapper">
                {progress > 0 && (
                <Progress
                  progress={progress}
                  fullWidth
                  barOnly
                />
                ) || (
                  <>0%</>
                )}
              </div>
            </div>
          </div>
          <div className="employee-card__actions">
            {['message', 'edit'].includes(page) && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={onProfileClick}
              >
                <i className="fas fa-user" />
              </button>
            )}
            {page !== 'message' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleMessageClick}
              >
                <i className="fas fa-envelope" />
              </button>
            )}
            {page !== 'edit' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleEditClick}
              >
                <i className="fas fa-pencil-alt" />
              </button>
            )}
            {TaskList && (
              <>
                <button
                  className={classNames('btn btn--icon', {'btn--expanded': open})}
                  type="button"
                  onClick={() => this.setState({open: !open})}
                  onKeyPress={() => this.setState({open: !open})}
                  onBlur={() => this.setState({open: false})}
                >
                  <i className="fas fa-ellipsis-h" />
                </button>
                {open && (
                  <div className="btn__dropdown">
                    <TaskList onClick={() => this.setState({open: false})} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        {listView
      && (
        <tr
          className={classNames([
            'employee-card',
            {
              'employee-card--expanded': isExpanded,
              'employee-card--clickable': !isExpanded,
            },
          ])}
          {...cardProps}
        >
          <td className="employee-card__avatar-wrapper">
            <PersonAvatar
              person={employee}
              notificationCount={employee.pending_checklists}
              className={classNames([
                'employee-card__avatar',
                {'employee-card__avatar--centered': isExpanded},
              ])}
            />
          </td>
          <td
            className={classNames([
              'employee-card__name-wrapper',
              {'employee-card__name-wrapper--centered': isExpanded},
            ])}
          >
            <div className="employee-card__name">{fullname}</div>
          </td>
          <td>
            <div
              style={{
                width:28,
                height: 28,
                backgroundColor: (employee.progress === 0 ? '#fafafa': 'white'),
                padding: 4,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                fontSize: 9,
                borderRadius: 24,
                boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              {competencelevel > 0 && (
                <CircularProgressbar
                  value={employee.progress}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    backgroundColor: 'red',
                    pathColor: '#0B5CD7',
                    trailColor: employee.progress === 0 ? '#FFD7D7' : '#E0E0E0',
                  })}
                />
              ) || (
                <>0%</>
              )}

            </div>
          </td>
          <td className="employee-card__position">
            <div>
              {position}
&nbsp;
            </div>
          </td>

          <td className="employee-card__email">
            {isExpanded ? <a href={`mailto:${email}`}>{email}</a> : email}
          </td>

          <td className="employee-card__phone">
            {isExpanded ? <a href={`tel:${phone}`}>{phone}</a> : phone}
          </td>
          <td className="employee-card__phone">
            {isExpanded ? <a href={`tel:${mobile}`}>{mobile}</a> : mobile}
          </td>
          {isExpanded && (
            <>
              <div className="employee-card__progress-wrapper">
                <Progress
                  progress={competencelevel}
                  fullWidth
                />
              </div>
              <div className="employee-card__actions">
                <button
                  className="btn btn--icon"
                  type="button"
                  onClick={this.handleMessageClick}
                >
                  <i className="fas fa-envelope" />
                </button>
                <button
                  className="btn btn--icon"
                  type="button"
                  onClick={this.handleEditClick}
                >
                  <i className="fas fa-pencil-alt" />
                </button>
              </div>
            </>
          )}
        </tr>
      )
      ||  employee
        && (
          <Wrapper
            class="employee-top-part"
            style={wrapperStyle}
          >
            {onCloseButtonClick && (
              <IconButton
                className="close-btn"
                theme="gray"
                variant="round"
                ariaLabel={i18n('globals.close')}
                title={i18n('globals.close')}
                onClick={onCloseButtonClick}
                icon={faTimes}
                style={{
                  position: 'absolute',
                  right: '1.75em',
                  top: '1.75em',
                }}
              />
            )}
            <ContentWrapper>
              <LeftPart>
                <PersonInfoWrapper>
                  <PersonAvatar
                    isFetching={isFetching}
                    person={employee}
                    notificationCount={employee.pending_checklists}
                    className={classNames([
                      'employee-card__avatar',
                      'rounded-corners',
                      {'employee-card__avatar--centered': isExpanded || isFetching},
                    ])}
                  />
                  <Personalia>
                    <Heading>{fullname}</Heading>
                    {position && (
                      <SubHeading>
                        <div>
                          {position}
                        </div>
                      </SubHeading>
                    )}
                    <Text>{email}</Text>
                    <Text>{mobile || phone}</Text>
                  </Personalia>
                </PersonInfoWrapper>

                <ActionButton
                  variant="primary"
                  theme="gray"
                  onClick={this.handleMessageClick}
                  withRightChrevron
                  css={{
                    marginLeft: 0,
                    marginBottom: 0,
                  }}
                  isFetching={isFetching}
                >
                  {i18n('person.send-message-to-x', {functionArgs: {x: fullname}})}
                </ActionButton>
              </LeftPart>
              <RightPart>
                <ActionButton
                  variant="round"
                  theme="gray"
                  onClick={this.handleEditClick}
                  withRightChrevron
                  css={{marginBottom: '1.1em'}}
                  isFetching={isFetching}
                >
                  {i18n('person.change-personalia')}
                </ActionButton>
                <ActionButton
                  theme="gray"
                  variant="round"
                  onClick={onEditEmploymentClick}
                  withRightChrevron
                  isFetching={isFetching}
                >
                  {i18n('person.change-employment')}
                </ActionButton>
              </RightPart>
            </ContentWrapper>
          </Wrapper>
        )}

        <div className="employee" />
      </>
    );
  }
}

EmployeeCard.propTypes = {
  employee: PersonShape().isRequired,
  onProfileClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onMessageClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  taskList: PropTypes.node,
  page: PropTypes.string,
};

EmployeeCard.defaultProps = {
  isExpanded: false,
  onProfileClick: () => {},
  onAvatarClick: () => {},
  onEditClick: () => {},
  onMessageClick: () => {},
  taskList: null,
  page: '',
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  mainOrganisation: getMainOrganisations(state),
});

export default connect(mapStateToProps)(EmployeeCard);

export const EmployeeCardLoadingNew = () =>  (
  <EmployeeCard
    isFetching
    employee={{}}
  />
);

export const EmployeeCardLoading = ({isExpanded, isCentered, isMobile}) =>
  isExpanded
    ?     isMobile && (
      <div className="employee-card employee-card__loader--mobile">
        <div className="employee-card__top-section">
          <ContentLoader
            title="Laster inn..."
            height={52}
            width={409}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <circle
              cx="26"
              cy="26"
              r="25"
            />
            <rect
              x="70"
              y="10"
              rx="4"
              ry="4"
              width="120"
              height="20"
            />
            <rect
              x="10"
              y="85"
              rx="3"
              ry="3"
              width="70"
              height="5"
            />
          </ContentLoader>
        </div>
        <div className="employee-card__actions">
          <ContentLoader
            title="Laster inn..."
            height={40}
            width={150}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <circle
              cx="30"
              cy="20"
              r="20"
            />
            <circle
              cx="80"
              cy="20"
              r="20"
            />
            <circle
              cx="130"
              cy="20"
              r="20"
            />
          </ContentLoader>
        </div>
      </div>
    ) || (
      <div
        className={classNames([
          'employee-card__loader--expanded',
          {'employee-card__loader--centered': isCentered},
        ])}
      >
        <ContentLoader
          title="Laster inn..."
          height={122}
          width={90}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="0"
            y="70"
            rx="4"
            ry="4"
            width="90"
            height="5"
          />
          <rect
            x="10"
            y="85"
            rx="3"
            ry="3"
            width="70"
            height="5"
          />
          <circle
            cx="45"
            cy="30"
            r="23"
          />
        </ContentLoader>
      </div>
    )
    : (
      <div
        className={classNames([
          'employee-card__loader',
          {'employee-card__loader--centered': isCentered},
        ])}
      >
        <ContentLoader
          title="Laster inn..."
          height={40}
          width={180}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="58"
            y="8"
            rx="4"
            ry="4"
            width="117"
            height="6"
          />
          <rect
            x="58"
            y="24"
            rx="3"
            ry="3"
            width="85"
            height="6"
          />
          <circle
            cx="20"
            cy="20"
            r="20"
          />
        </ContentLoader>
      </div>
    );

EmployeeCardLoading.propTypes = {
  isCentered: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isMobile: PropTypes.bool,
};

EmployeeCardLoading.defaultProps = {
  isCentered: false,
  isExpanded: false,
  isMobile: false,
};
