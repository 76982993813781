import React, {useEffect, useState} from 'react';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n/index';
import {AnimatePresence, motion} from 'framer-motion';

const AnimateSlideUpOnLoad = ({key, exitAnimating, children, ...props}) => (
  <AnimatePresence>
    {!exitAnimating 
    && (
      <motion.div
        key={key}
        css={css`
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10000;
          background: #fff;
          margin: 0;
          overflow: auto;
        `}
        initial={{top: '100%'}}
        animate={{top: '0%'}}
        exit={{top: '100%'}}
        transition={{
          ease: 'easeOut',
          duration: 0.6,
        }}
        {...props}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export default AnimateSlideUpOnLoad;
