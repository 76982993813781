import PropTypes from 'prop-types';
import React from 'react';
import './course-duration.scss';
import classNames from 'classnames';
import {i18n} from '@src/i18n';

const clockFAClass = 'fas fa-clock';
const stopwatchFAClass = 'fas fa-stopwatch';

export const getDurationName = (metric, plural) => plural
  ? i18n('date-and-time' + metric, {lowerCase: true}, metric)
  : i18n('date-and-time.' + metric.slice(0, - 1), {lowerCase: true}, metric);

const CourseDuration = ({
  duration,
  metric,
  iconType,
  className,
  ...props
}) => (
  <div
    className={classNames('course-duration', className)}
    {...props}
  >
    <i
      className={classNames(
        'course-duration__clock',
        iconType === 'stopwatch' ? stopwatchFAClass : clockFAClass,
      )}
      title="varighet"
    />
    {`${duration} ${getDurationName(metric, duration !== 1)}`}
  </div>
);

CourseDuration.defaultProps = {
  className: null,
  iconType: 'clock',
};

CourseDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number.isRequired,
  metric: PropTypes.oneOf(['hours', 'days', 'minutes']).isRequired,
  iconType: PropTypes.oneOf(['stopwatch', 'clock']),
};

export default CourseDuration;
