import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`

`;

export const Cv = styled.div`

`;

export const Title = styled.h2`
    font-weight: 500;
`;

export const Table = styled.table`
    width: 100%;

    thead{
        th{
            font-weight: normal;
            color: #424242;
            font-size: 0.85rem;
            padding-bottom: 0.6rem;
        }
        th.name{
            width: 80%;
            text-align: left;
        }
        th.last-updated{
            width: 20%;
            text-align: right;
        }
    }

	tbody{
        tr{
            &:hover{
                cursor: pointer;
            }

            td{
                border-bottom: 1px solid #C4C4C4;
                padding: 0.5rem 0;
            }

            td.name{
                text-align: left;
                padding-left: 6px;
            }
            td.last-updated{
                text-align:right;
                color: #616161;
                padding-right: 6px;
            }
        }
	}
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: 1.4rem;
`;
